<div class="dark:tw-border dark:tw-border-solid dark:tw-border-gray-700 tw-rounded tw-py-2">
  <h2
    mat-dialog-title
    class="tw-text-xl tw-font-bold tw-mb-4 tw-text-dark-light-dynamic tw-px-4 lg:tw-px-6">
    {{config.title}}
  </h2>

  <mat-dialog-content class="tw-px-4 lg:tw-px-6">
    <div *ngIf="loading" class="tw-flex tw-justify-center tw-items-center tw-h-32">
      <mat-spinner diameter="32"></mat-spinner>
    </div>

    <div *ngIf="!loading">
      <!-- Current Company Info -->
      <div class="tw-bg-gray-100 dark:tw-bg-gray-700 tw-p-4 tw-rounded">
        <h3 class="tw-text-lg tw-font-semibold tw-mt-0 tw-mb-2 tw-text-gray-700 dark:tw-text-white">
          Current Company
        </h3>

        <!-- company info -->
        <div>
          <div>
            <span class="tw-font-semibold tw-text-sm dark:tw-text-gray-300">Name:</span>
            <span class="tw-text-dark-light-dynamic tw-text-base tw-ml-2">{{company.name}}</span>
          </div>
          <div>
            <span class="tw-font-semibold tw-text-sm dark:tw-text-gray-300">
              Current {{config.fieldName}}:
            </span>
            <span class="tw-text-dark-light-dynamic tw-text-base tw-ml-2">
              {{company[config.fieldKey] || 'None'}}
            </span>
          </div>
        </div>
      </div>

      <!-- Input Form -->
      <form [formGroup]="inputForm" class="tw-mt-6 tw-mb-4">
        <mat-form-field class="tw-w-full" appearance="outline">
          <mat-label>New {{config.fieldName}}</mat-label>
          <input
            matInput
            formControlName="newValue"
            [placeholder]="'Enter new ' + config.fieldName.toLowerCase()" />
        </mat-form-field>
      </form>

      <!-- Companies with Current Value -->
      <div
        *ngIf="currentValueCompanies.length > 0"
        class="tw-bg-yellow-100 dark:tw-bg-yellow-900/30 tw-border tw-border-solid tw-border-yellow-300 dark:tw-border-yellow-700 tw-p-4 tw-rounded">
        <h3 class="tw-text-lg tw-font-semibold tw-mb-2 tw-mt-0 dark:tw-text-white">
          Other Companies with Current {{config.fieldName}}
        </h3>
        <div class="tw-space-y-2 dark:tw-text-gray-200">
          <div *ngFor="let company of currentValueCompanies" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2">business</mat-icon>
            <a
              [href]="'/datawarehouse/companies?id=' + company.id"
              target="_blank"
              class="hover:tw-underline">
              {{company.name}}
            </a>
          </div>
        </div>
      </div>

      <!-- Companies with New Value -->
      <div
        *ngIf="newValueCompanies.length > 0"
        class="tw-bg-red-100 dark:tw-bg-red-900 tw-p-4 tw-rounded tw-mt-4">
        <h3 class="tw-text-lg tw-font-semibold tw-mb-2 tw-mt-0 dark:tw-text-white">
          Companies Already Using New {{config.fieldName}}
        </h3>
        <div class="tw-space-y-2 dark:tw-text-gray-200">
          <div *ngFor="let company of newValueCompanies" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2">business</mat-icon>
            <a
              [href]="'/datawarehouse/companies?id=' + company.id"
              target="_blank"
              class="hover:tw-underline">
              {{company.name}}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="newValueCompanies.length === 0">
      <p class="tw-text-gray-500">
        No companies found with the new {{config.fieldName}} {{inputForm.get('newValue')?.value}}.
      </p>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="tw-gap-y-2 tw-px-4 lg:tw-px-6">
    <button mat-button (click)="close()" [disabled]="saving">Cancel</button>

    <!-- Show these buttons when dealing with current value -->
    <ng-container *ngIf="company[config.fieldKey]">
      <button mat-raised-button color="warn" (click)="removeValue()" [disabled]="saving">
        Remove {{config.fieldName}}
      </button>
      <button
        mat-raised-button
        color="warn"
        (click)="removeFromOthers()"
        [disabled]="saving || currentValueCompanies.length === 0">
        Remove from Others
      </button>
    </ng-container>

    <!-- Show these buttons when setting new value -->
    <ng-container
      *ngIf="inputForm.valid && inputForm.get('newValue')?.value !== company[config.fieldKey]">
      <button
        *ngIf="newValueCompanies.length > 0"
        mat-raised-button
        color="warn"
        (click)="updateValueAndClearOthers()"
        [disabled]="saving">
        Set Here & Clear Others
      </button>
      <button mat-raised-button color="primary" (click)="updateValueOnly()" [disabled]="saving">
        Update {{config.fieldName}}
      </button>
    </ng-container>
  </mat-dialog-actions>
</div>
