<div id="chat-container" class="tw-flex tw-flex-col tw-h-full tw-pt-5 chart-container">
  <mat-drawer-container>
    <!-- Left-side drawer for chat history -->
    <mat-drawer
      mode="over"
      position="start"
      [opened]="historyDrawerOpen"
      class="tw-w-[300px] xs:tw-w-[380px] sm:tw-w-[540px] md:tw-w-[600px] lg:tw-w-[700px] tw-p-4">
      <app-chat-history-drawer
        [orgId]="orgId"
        [isOpen]="historyDrawerOpen"
        (closeDrawer)="toggleHistoryDrawer()">
      </app-chat-history-drawer>
    </mat-drawer>

    <mat-drawer-content class="tw-h-full tw-min-h-[90vh]">
      <div class="tw-flex tw-gap-2 tw-mb-2">
        <button
          *ngIf="messages.length > 0"
          mat-raised-button
          color="accent"
          (click)="startNewChat()">
          <mat-icon class="tw-mr-1">edit_square</mat-icon>
          Start New Chat
        </button>

        <!-- history button -->
        <button mat-stroked-button color="primary" (click)="toggleHistoryDrawer()">
          <mat-icon class="dark:tw-text-gray-500 tw-mr-1">history</mat-icon>
          Chat History
        </button>
      </div>

      <div class="tw-flex-1 tw-overflow-y-auto tw-mb-5">
        <!-- chat messages -->
        <div
          *ngFor="let message of messages; let i = index"
          class="tw-flex tw-gap-1 tw-my-2.5"
          [ngClass]="{'tw-justify-end': message.type === 'user', 
                    'tw-justify-start': message.type === 'assistant'}">
          <!-- robot face -->
          <img
            *ngIf="message.type === 'assistant'"
            src="../../../assets/img/ldt-robot.svg"
            alt="robot face"
            class="tw-w-7 tw-self-start tw-shrink-0 ldt-logo-white-gray tw-mt-2" />

          <div class="tw-w-full">
            <div
              [ngClass]="{
                'tw-ml-[10%] xxl:tw-ml-[60%]': message.type === 'user', 

                'tw-mr-[10%] xl_1800:tw-mr-0 tw-ml-2': message.type === 'assistant'
              }"
              class="tw-bg-white dark:tw-bg-gray-700 tw-border tw-border-solid tw-border-gray-200 dark:tw-border-gray-600 tw-p-5 tw-rounded-xl tw-max-w-[90%]">
              <!-- Message content -->
              <div
                class="tw-whitespace-pre-wrap tw-break-words tw-text-gray-900 dark:tw-text-gray-200"
                [ngClass]="{'tw-text-base': message.type === 'user'}">
                <!-- TODO: this is not working/showing -->
                <div
                  *ngIf="message.isLoading"
                  class="tw-text-gray-600 dark:tw-text-gray-400 tw-italic">
                  AI is thinking...
                </div>

                <!-- User messages -->
                <div *ngIf="message.type === 'user'">
                  <span>{{message.content}}</span>
                </div>

                <!-- NEW DATA FORMAT: Sections and charts -->
                <div
                  *ngIf="message.type === 'assistant' && message.sections && message.sections.length > 0">
                  <div *ngFor="let section of message.sections" class="tw-mb-4">
                    <!-- Render section content -->
                    <markdown [data]="section.content" class="markdown-content"></markdown>

                    <!-- Chart placeholders for sections with reports using quickBuildId and reportId -->
                    <div
                      *ngIf="section.reports && section.reports.length > 0"
                      class="viz-container tw-mt-3 tw-flex tw-flex-wrap tw-gap-4">
                      <div *ngFor="let report of section.reports; let i = index" class="tw-w-full ">
                        <app-viz-from-quickbuild-factory
                          [quickBuild]="getQuickBuildById(report.quickBuildId, quickBuildsInThread)"
                          [reportId]="report.reportId"></app-viz-from-quickbuild-factory>
                        <div class="tw-mt-2 tw-flex tw-justify-end">
                          <a
                            href="javascript:void(0)"
                            (click)="report.quickBuildId && openDrawer(getQuickBuildById(report.quickBuildId, quickBuildsInThread))"
                            class="tw-text-sm hover:tw-underline"
                            [class.tw-opacity-50]="!report.quickBuildId">
                            View data
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Filters and Feedback below the columns -->
              <div *ngIf="message.type === 'assistant'">
                <!-- TODO: better responsive layout for this section -->
                <!-- QuickBuilds list -->
                <div *ngIf="quickBuildsInThread?.length">
                  <h3
                    class="tw-text-gray-700 dark:tw-text-gray-200 tw-font-semibold tw-mt-0 tw-mb-2">
                    Data used for this analysis
                  </h3>

                  <div
                    class="tw-rounded tw-bg-gray-100 dark:tw-bg-gray-800 tw-p-2 lg:tw-p-4 tw-my-3 tw-space-y-2">
                    <!-- list items -->
                    <div
                      class="tw-flex tw-flex-row tw-flex-wrap tw-gap-2 tw-items-center tw-justify-between"
                      *ngFor="let quickBuild of quickBuildsInThread; let i = index">
                      <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-gap-2">
                        <div class="tw-flex tw-flex-row tw-items-center tw-gap-2">
                          <h4
                            class="tw-text-gray-700 dark:tw-text-gray-400 tw-font-semibold tw-text-base tw-my-0">
                            {{quickBuild.name || 'AI-generated QuickBuild'}}
                          </h4>
                        </div>
                      </div>

                      <button
                        (click)="openDrawer(quickBuild)"
                        type="button"
                        mat-stroked-button
                        color="primary"
                        class="tw-flex tw-items-center tw-gap-1 tw-h-8">
                        <mat-icon class="tw-text-base">table_view</mat-icon>
                        <span>Get the Data</span>
                      </button>
                    </div>
                  </div>
                </div>

                <!-- Companies list (Battle) -->
                <div *ngIf="companyLookups" class="tw-mt-8">
                  <h3
                    class="tw-text-gray-700 dark:tw-text-gray-200 tw-font-semibold tw-mt-0 tw-mb-2">
                    Companies used for this analysis
                  </h3>

                  <div
                    class="tw-rounded tw-bg-gray-100 dark:tw-bg-gray-800 tw-p-2 lg:tw-p-4 tw-my-4 tw-space-y-2">
                    <!-- list items -->
                    <div
                      class="tw-flex tw-flex-row tw-flex-wrap tw-gap-2 tw-items-center tw-justify-between"
                      *ngFor="let company of companyLookups | keyvalue">
                      <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-gap-2">
                        <div class="tw-flex tw-flex-row tw-items-center tw-gap-2">
                          <h4
                            class="tw-text-gray-700 dark:tw-text-gray-400 tw-font-semibold tw-text-base tw-my-0">
                            {{company.key}}
                          </h4>
                        </div>
                      </div>

                      <button
                        (click)="openCompanyDrawer(company.key, company.value)"
                        type="button"
                        mat-stroked-button
                        color="primary"
                        class="tw-flex tw-items-center tw-gap-1 tw-h-8">
                        <mat-icon class="tw-text-base">table_view</mat-icon>
                        <span>Moneyball</span>
                      </button>
                    </div>
                  </div>

                  <div *ngIf="companyBattleQBs" class="companies-battle-viz tw-mb-4 tw-mt-8">
                    <app-reports-headcount-comparison
                      [quickBuilds]="companyBattleQBs"
                      [reportTarget]="reportTarget"
                      class=""></app-reports-headcount-comparison>
                  </div>
                </div>
                <!-- Feedback input -->
                <div
                  *ngIf="message.type === 'assistant' && !message.isLoading"
                  class="tw-relative tw-h-1">
                  <div
                    class="tw-absolute tw-right-[-8px] tw-flex tw-items-center tw-gap-1.5 tw-bg-white dark:tw-bg-gray-800 tw-shadow tw-rounded-lg tw-px-1.5 tw-py-1">
                    <button
                      mat-icon-button
                      class="tw-w-8 tw-h-8 tw-min-w-0 tw-p-1"
                      [class]="message.isPositiveFeedback ? 'tw-text-primary_purple-500' : 'tw-text-gray-500 dark:tw-text-gray-300 hover:tw-text-gray-600 dark:hover:tw-text-gray-100'"
                      (click)="showFeedbackInput(message, true)"
                      matTooltip="This was helpful">
                      <div class="tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center">
                        <mat-icon class="tw-text-[20px]">thumb_up</mat-icon>
                      </div>
                    </button>
                    <button
                      mat-icon-button
                      class="tw-w-8 tw-h-8 tw-min-w-0 tw-p-1"
                      [class]="message.isPositiveFeedback === false ? 'tw-text-orange-500' : 'tw-text-gray-500 dark:tw-text-gray-300 hover:tw-text-gray-600 dark:hover:tw-text-gray-100'"
                      (click)="showFeedbackInput(message, false)"
                      matTooltip="This needs improvement">
                      <div class="tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center">
                        <mat-icon class="tw-text-[20px]">thumb_down</mat-icon>
                      </div>
                    </button>
                  </div>

                  <!-- Inline feedback input -->
                  <div
                    *ngIf="message.showFeedbackInput"
                    class="tw-absolute tw-right-[-8px] tw-bottom-[24px] tw-bg-white dark:tw-bg-gray-800 tw-rounded-lg tw-border-1 tw-border-gray-200 tw-border-solid dark:tw-border-gray-600 tw-shadow-lg dark:tw-shadow-gray-600 tw-w-80 tw-pb-1"
                    clickOutside
                    (clickOutside)="message.showFeedbackInput = false">
                    <!-- Status banner -->
                    <div
                      class="dark:tw-bg-gray-800 tw-rounded-t-lg tw-px-4 tw-py-2 tw-pt-3 tw-border-b tw-border-gray-200 dark:tw-border-gray-600">
                      <h3 class="tw-text-base tw-font-semibold tw-text-primary_purple-500 tw-my-1">
                        Your rating has been submitted. Feedback is appreciated.
                      </h3>
                    </div>
                    <!-- Main content -->
                    <div class="tw-py-2 tw-pb-1 tw-px-4">
                      <div class="tw-box-border">
                        <div class="tw-mb-6">
                          <div class="tw-text-sm tw-text-gray-600 dark:tw-text-gray-200 tw-mb-1">
                            Data accuracy/relevancy:
                          </div>
                          <div class="tw-relative">
                            <mat-slider
                              [min]="0"
                              [max]="4"
                              [step]="1"
                              [discrete]="true"
                              class="tw-w-full custom-slider">
                              <input matSliderThumb [(ngModel)]="accuracyRating" />
                            </mat-slider>
                          </div>
                          <div class="tw-flex tw-text-sm tw-text-gray-600 dark:tw-text-gray-200">
                            <div class="tw-w-[40px] tw-pr-2">No answer</div>
                            <div class="tw-flex-1 tw-flex tw-justify-between tw-pl-2">
                              <span class="tw-w-[40px] tw-pl-3">Least accurate</span>
                              <span class="tw-w-[40px] tw-pr-6">Very accurate</span>
                            </div>
                          </div>
                        </div>
                        <textarea
                          [(ngModel)]="message.feedbackText"
                          [placeholder]="feedbackPlaceholder"
                          class="tw-box-border tw-w-full tw-text-sm tw-p-2 tw-border tw-border-gray-200 dark:tw-border-gray-600 tw-rounded tw-bg-gray-50 dark:tw-bg-gray-700 tw-text-gray-900 dark:tw-text-white dark:tw-placeholder-gray-300"
                          rows="3">
                        </textarea>
                      </div>
                      <div class="tw-flex tw-justify-end tw-gap-2 tw-mt-1">
                        <button
                          mat-button
                          class="tw-text-sm tw-h-10"
                          (click)="message.showFeedbackInput = false">
                          Cancel
                        </button>
                        <button
                          mat-button
                          color="primary"
                          class="tw-text-sm tw-font-semibold tw-h-10"
                          (click)="submitFeedback(message)">
                          Submit
                        </button>
                      </div>
                    </div>
                    <!-- Arrow pointing down -->
                    <div
                      class="tw-absolute tw-bottom-[-6px] tw-right-5 tw-w-3 tw-h-3 tw-rotate-45 tw-bg-white dark:tw-bg-gray-800 tw-border-b tw-border-r tw-border-gray-200 dark:tw-border-gray-600"></div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Follow-up questions section -->
            <!--- only show this if this is the last message -->
            <div
              class="follow-up-questions tw-max-w-[600px] tw-rounded-md tw-p-4 tw-my-4"
              *ngIf="message.followUpQuestions?.length && i === messages.length - 1">
              <h4 class="tw-m-0 dark:tw-text-gray-300">Follow-Up Questions:</h4>
              <ul>
                <li
                  *ngFor="let question of message.followUpQuestions"
                  class="tw-flex tw-items-center tw-bg-gray-100 dark:tw-bg-gray-800 tw-rounded-lg tw-px-4 tw-my-3 tw-mx-0">
                  <a
                    href="javascript:void(0)"
                    (click)="sendFollowUpOrSampleQuestion(question)"
                    class="tw-text-sm dark:tw-text-gray-400 hover:tw-text-link-color-dynamic-darker tw-py-1">
                    {{ question }}
                    <mat-icon
                      class="tw-text-[16px] tw-leading-5 tw-h-4 tw-w-4 tw-rotate-[-30deg] tw-text-link-color-dynamic tw-pl-1">
                      send
                    </mat-icon>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="tw-flex tw-gap-2.5 tw-items-center">
        <mat-form-field appearance="outline" floatLabel="auto" class="tw-flex-1">
          <mat-label class="dark:tw-text-white">
            Ask a <span *ngIf="messages.length > 1">follow-up</span> question...
          </mat-label>
          <input
            matInput
            [(ngModel)]="currentPrompt"
            placeholder="E.g., describe the educational background of people at Google"
            (keyup.enter)="sendMessage()"
            [disabled]="isLoading"
            class="dark:tw-text-gray-200" />
        </mat-form-field>
        <button
          mat-raised-button
          color="primary"
          (click)="sendMessage()"
          [disabled]="isLoading || !currentPrompt?.trim()">
          Send
        </button>
      </div>

      <!-- Sample questions to ask to first-time users -->
      <!-- TODO: create a component to reuse this -->
      <div
        *ngIf="messages.length === 0 && sampleQuestions.length"
        class="follow-up-questions tw-max-w-[900px] tw-rounded-md">
        <ul>
          <li
            *ngFor="let question of sampleQuestions"
            class="tw-flex tw-items-center tw-bg-gray-100 dark:tw-bg-gray-800 tw-rounded-lg tw-px-4 tw-py-1 tw-my-3 tw-mx-0">
            <a
              href="javascript:void(0)"
              (click)="sendFollowUpOrSampleQuestion(question, true)"
              class="tw-text-sm dark:tw-text-gray-400 tw-py-1 hover:tw-text-link-color-dynamic-darker">
              {{ question }}
              <mat-icon
                class="tw-text-[16px] tw-leading-4 tw-h-4 tw-w-4 tw-rotate-[-30deg] tw-text-link-color-dynamic tw-pl-1">
                send
              </mat-icon>
            </a>
          </li>
        </ul>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>

<app-streams-subscribe-drawer
  [quickBuild]="quickBuildForSubscription"
  [filtersSelectedTab]="drawerService.filtersSelectedTab$ | async"
  [isOpen]="drawerService.isDrawerOpen$ | async"></app-streams-subscribe-drawer>
