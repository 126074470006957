<h2 mat-dialog-title class="tw-font-semibold tw-mb-4">Create API Key</h2>

<mat-dialog-content [formGroup]="form" class="tw-flex tw-flex-col">
  <mat-form-field appearance="outline">
    <mat-label>Name</mat-label>
    <input matInput placeholder="Name" formControlName="name" />
    <mat-error *ngIf="form.get('name')?.hasError('required')"> Name is required </mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Description</mat-label>
    <input matInput placeholder="Description" formControlName="description" />
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions class="tw-flex tw-justify-between tw-px-6 tw-pb-5 tw-pt-0">
  <button mat-stroked-button (click)="close()">Cancel</button>
  <button
    mat-raised-button
    [disabled]="!form.valid"
    color="primary"
    (click)="save()"
    class="disabled:tw-border-gray-400 disabled:tw-text-gray-400 disabled:tw-cursor-not-allowed">
    Create
  </button>
</mat-dialog-actions>
