<div id="workforce-chat-container" class="tw-flex tw-flex-col tw-h-full chart-container">
  <mat-drawer-container class="tw-h-screen">
    <!-- Left-side drawer for chat history -->
    <mat-drawer
      mode="over"
      position="start"
      [opened]="historyDrawerOpen"
      class="tw-w-[300px] xs:tw-w-[380px] sm:tw-w-[540px] md:tw-w-[600px] lg:tw-w-[700px] xl:tw-w-[900px] tw-p-4 tw-bg-gray-700">
      <app-chat-history-drawer
        [orgId]="orgId"
        [isOpen]="historyDrawerOpen"
        (closeDrawer)="toggleHistoryDrawer()">
      </app-chat-history-drawer>
    </mat-drawer>

    <mat-drawer-content
      #drawerContent
      (scroll)="checkScroll()"
      class="tw-h-full tw-flex tw-flex-col tw-justify-between">
      <!-- Header with logo -->
      <header
        class="tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-px-3 md:tw-px-6 tw-py-2 md:tw-py-3 tw-bg-gray-900 tw-z-50 tw-flex tw-justify-between tw-items-center"
        [ngClass]="{'tw-shadow-lg tw-shadow-gray-800': hasScrolled}">
        <img
          src="assets/img/workforce_logo_white.svg"
          alt="workforce.ai"
          class="tw-h-6"
          (dblclick)="toggleShowCharts()" />

        <div>
          <!-- buttons -->
          <div class="tw-flex tw-gap-2">
            <button
              *ngIf="messages.length > 0"
              mat-raised-button
              color="accent"
              (click)="startNewChat()">
              <mat-icon class="tw-mr-1 tw">edit_square</mat-icon>
              <span class="tw-hidden md:tw-inline-block">Start New Chat</span>
            </button>

            <!-- history button -->
            <button mat-stroked-button color="primary" (click)="toggleHistoryDrawer()">
              <mat-icon class="dark:tw-text-gray-500 tw-mr-1">history</mat-icon>
              <span class="tw-hidden md:tw-inline-block">Chat History</span>
            </button>
          </div>
        </div>
      </header>

      <div
        class="tw-container tw-mx-auto tw-px-2 md:tw-px-4 tw-pb-6 tw-mb-5 tw-mt-[52px] md:tw-mt-[61px]">
        <!-- heading -->
        <h1 class="tw-font-semibold tw-mb-2 tw-text-center tw-text-gray-200">
          <span
            class="tw-text-gray-200 tw-text-2xl md:tw-text-[1.8rem] tw-inline-block tw-max-w-[400px] md:tw-max-w-[500px]">
            Answer Your Workforce Questions
            <span
              class="tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-to-emerald-600 tw-from-sky-400">
              in Seconds,
            </span>
            Not Days
          </span>
        </h1>

        <div class="tw-flex-1 tw-overflow-y-auto tw-mb-5">
          <!-- chat messages -->
          <div
            *ngFor="let message of messages; let i = index"
            class="tw-flex tw-gap-1 tw-my-6"
            [ngClass]="{'tw-justify-end': message.type === 'user', 
                      'tw-justify-start': message.type === 'assistant'}">
            <!-- robot face -->
            <img
              *ngIf="message.type === 'assistant'"
              src="../../../assets/img/ldt-robot.svg"
              alt="robot face"
              class="tw-w-5 md:tw-w-7 tw-self-start tw-shrink-0 ldt-logo-white-gray tw-mt-2" />

            <div class="tw-w-full">
              <div
                [ngClass]="{
                  'tw-ml-[10%] xxl:tw-ml-[60%]': message.type === 'user', 
                  'tw-mr-[10%] xl_1800:tw-mr-0 tw-ml-1 md:tw-ml-2': message.type === 'assistant'
                }"
                class="tw-bg-white dark:tw-bg-gray-700 tw-border tw-border-solid tw-border-gray-200 dark:tw-border-gray-600 tw-px-3 md:tw-px-5 tw-py-5 tw-rounded-xl tw-max-w-[90%]">
                <!-- Message content -->
                <div
                  class="tw-whitespace-pre-wrap tw-break-words tw-text-gray-900 dark:tw-text-gray-200"
                  [ngClass]="{'tw-text-base': message.type === 'user'}">
                  <div
                    *ngIf="message.isLoading"
                    class="tw-text-gray-600 dark:tw-text-gray-400 tw-italic">
                    AI is thinking...
                  </div>

                  <!-- User messages -->
                  <div *ngIf="message.type === 'user'">
                    <span>{{message.content}}</span>
                  </div>

                  <!-- Sections and charts -->
                  <div
                    *ngIf="message.type === 'assistant' && message.sections && message.sections.length > 0">
                    <div *ngFor="let section of message.sections" class="tw-mb-4">
                      <ng-container
                        *ngIf="showCharts || section.content.startsWith('### Executive Summary')">
                        <!-- section content -->
                        <markdown [data]="section.content" class="markdown-content"></markdown>

                        <!-- Charts/Viz if section has reports -->
                        <div
                          *ngIf="section.reports && section.reports.length > 0"
                          class="viz-container tw-mt-3 tw-flex tw-flex-wrap tw-gap-4">
                          <div
                            *ngFor="let report of section.reports; let i = index"
                            class="tw-w-full">
                            <app-viz-from-quickbuild-factory
                              [quickBuild]="getQuickBuildById(report.quickBuildId, quickBuildsInThread)"
                              [reportId]="report.reportId"></app-viz-from-quickbuild-factory>
                            <div class="tw-mt-2 tw-flex tw-justify-end">
                              <a
                                href="javascript:void(0)"
                                (click)="report.quickBuildId && openDrawer(getQuickBuildById(report.quickBuildId, quickBuildsInThread))"
                                class="tw-text-sm tw-font-semibold hover:tw-underline"
                                [class.tw-opacity-50]="!report.quickBuildId">
                                View data
                              </a>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <!-- Filters and Feedback -->
                <div *ngIf="message.type === 'assistant'">
                  <!-- QuickBuilds list -->
                  <div *ngIf="quickBuildsInThread?.length && !isLoading">
                    <h3
                      class="tw-text-gray-700 dark:tw-text-gray-200 tw-font-semibold tw-mt-0 tw-mb-2">
                      Data used for this analysis
                    </h3>

                    <div
                      class="tw-rounded tw-bg-gray-100 dark:tw-bg-gray-800 tw-p-2 lg:tw-p-4 tw-my-3 tw-space-y-2">
                      <!-- QB list items -->
                      <div
                        class="tw-flex tw-flex-row tw-flex-wrap tw-gap-2 tw-items-center tw-justify-center md:tw-justify-between"
                        *ngFor="let quickBuild of quickBuildsInThread; let i = index">
                        <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-gap-2">
                          <div class="tw-flex tw-flex-row tw-items-center tw-gap-2">
                            <h4
                              class="tw-text-gray-700 dark:tw-text-gray-400 tw-font-semibold tw-text-sm md:tw-text-base tw-my-0">
                              {{quickBuild.name || 'AI-generated QuickBuild'}}
                            </h4>
                          </div>
                        </div>

                        <button
                          (click)="openDrawer(quickBuild)"
                          type="button"
                          mat-stroked-button
                          color="primary"
                          class="tw-flex tw-items-center tw-gap-1 tw-h-8">
                          <mat-icon class="tw-text-base">table_view</mat-icon>
                          <span>Get the Data</span>
                        </button>
                      </div>
                    </div>
                  </div>

                  <!-- Companies list -->
                  <div *ngIf="companyLookups" class="tw-mt-8">
                    <h3
                      class="tw-text-gray-700 dark:tw-text-gray-200 tw-font-semibold tw-mt-0 tw-mb-2">
                      Companies used for this analysis
                    </h3>

                    <div
                      class="tw-rounded tw-bg-gray-100 dark:tw-bg-gray-800 tw-p-2 lg:tw-p-4 tw-my-4 tw-space-y-2">
                      <!-- list items -->
                      <div
                        class="tw-flex tw-flex-row tw-flex-wrap tw-gap-2 tw-items-center tw-justify-between"
                        *ngFor="let company of companyLookups | keyvalue">
                        <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-gap-2">
                          <div class="tw-flex tw-flex-row tw-items-center tw-gap-2">
                            <h4
                              class="tw-text-gray-700 dark:tw-text-gray-400 tw-font-semibold tw-text-base tw-my-0">
                              {{company.key}}
                            </h4>
                          </div>
                        </div>

                        <button
                          (click)="openCompanyDrawer(company.key, company.value)"
                          type="button"
                          mat-stroked-button
                          color="primary"
                          class="tw-flex tw-items-center tw-gap-1 tw-h-8">
                          <mat-icon class="tw-text-base">table_view</mat-icon>
                          <span>Moneyball</span>
                        </button>
                      </div>
                    </div>
                    <div
                      *ngIf="companyBattleQBs.length > 1 && showCharts"
                      class="companies-battle-viz tw-mb-4 tw-mt-8">
                      <app-reports-headcount-comparison
                        [quickBuilds]="companyBattleQBs"
                        [reportTarget]="reportTarget"
                        class=""></app-reports-headcount-comparison>
                    </div>
                  </div>
                  <!-- Feedback input -->
                  <div
                    *ngIf="message.type === 'assistant' && !message.isLoading"
                    class="tw-relative tw-h-1">
                    <div
                      class="tw-absolute tw-right-[-8px] tw-flex tw-items-center tw-gap-1.5 tw-bg-white dark:tw-bg-gray-800 tw-shadow tw-rounded-lg tw-px-1.5 tw-py-1">
                      <button
                        mat-icon-button
                        class="tw-w-8 tw-h-8 tw-min-w-0 tw-p-1"
                        [class]="message.isPositiveFeedback ? 'tw-text-primary_purple-500' : 'tw-text-gray-500 dark:tw-text-gray-300 hover:tw-text-gray-600 dark:hover:tw-text-gray-100'"
                        (click)="showFeedbackInput(message, true)"
                        matTooltip="This was helpful">
                        <div class="tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center">
                          <mat-icon class="tw-text-[20px]">thumb_up</mat-icon>
                        </div>
                      </button>
                      <button
                        mat-icon-button
                        class="tw-w-8 tw-h-8 tw-min-w-0 tw-p-1"
                        [class]="message.isPositiveFeedback === false ? 'tw-text-orange-500' : 'tw-text-gray-500 dark:tw-text-gray-300 hover:tw-text-gray-600 dark:hover:tw-text-gray-100'"
                        (click)="showFeedbackInput(message, false)"
                        matTooltip="This needs improvement">
                        <div class="tw-h-full tw-w-full tw-flex tw-items-center tw-justify-center">
                          <mat-icon class="tw-text-[20px]">thumb_down</mat-icon>
                        </div>
                      </button>
                    </div>

                    <!-- Inline feedback input -->
                    <div
                      *ngIf="message.showFeedbackInput"
                      class="tw-absolute tw-right-[-8px] tw-bottom-[24px] tw-bg-white dark:tw-bg-gray-800 tw-rounded-lg tw-border-1 tw-border-gray-200 tw-border-solid dark:tw-border-gray-600 tw-shadow-lg dark:tw-shadow-gray-600 tw-w-80 tw-pb-1"
                      clickOutside
                      (clickOutside)="message.showFeedbackInput = false">
                      <!-- Status banner -->
                      <div
                        class="dark:tw-bg-gray-800 tw-rounded-t-lg tw-px-4 tw-py-2 tw-pt-3 tw-border-b tw-border-gray-200 dark:tw-border-gray-600">
                        <h3
                          class="tw-text-base tw-font-semibold tw-text-primary_purple-500 tw-my-1">
                          Your rating has been submitted. Feedback is appreciated.
                        </h3>
                      </div>
                      <!-- Main content -->
                      <div class="tw-py-2 tw-pb-1 tw-px-4">
                        <div class="tw-box-border">
                          <div class="tw-mb-6">
                            <div class="tw-text-sm tw-text-gray-600 dark:tw-text-gray-200 tw-mb-1">
                              Data accuracy/relevancy:
                            </div>
                            <div class="tw-relative">
                              <mat-slider
                                [min]="0"
                                [max]="4"
                                [step]="1"
                                [discrete]="true"
                                class="tw-w-full custom-slider">
                                <input matSliderThumb [(ngModel)]="accuracyRating" />
                              </mat-slider>
                            </div>
                            <div class="tw-flex tw-text-sm tw-text-gray-600 dark:tw-text-gray-200">
                              <div class="tw-w-[40px] tw-pr-2">No answer</div>
                              <div class="tw-flex-1 tw-flex tw-justify-between tw-pl-2">
                                <span class="tw-w-[40px] tw-pl-3">Least accurate</span>
                                <span class="tw-w-[40px] tw-pr-6">Very accurate</span>
                              </div>
                            </div>
                          </div>
                          <textarea
                            [(ngModel)]="message.feedbackText"
                            [placeholder]="feedbackPlaceholder"
                            class="tw-box-border tw-w-full tw-text-sm tw-p-2 tw-border tw-border-gray-200 dark:tw-border-gray-600 tw-rounded tw-bg-gray-50 dark:tw-bg-gray-700 tw-text-gray-900 dark:tw-text-white dark:tw-placeholder-gray-300"
                            rows="3">
                          </textarea>
                        </div>
                        <div class="tw-flex tw-justify-end tw-gap-2 tw-mt-1">
                          <button
                            mat-button
                            class="tw-text-sm tw-h-10"
                            (click)="message.showFeedbackInput = false">
                            Cancel
                          </button>
                          <button
                            mat-button
                            color="primary"
                            class="tw-text-sm tw-font-semibold tw-h-10"
                            (click)="submitFeedback(message)">
                            Submit
                          </button>
                        </div>
                      </div>
                      <!-- Arrow pointing down -->
                      <div
                        class="tw-absolute tw-bottom-[-6px] tw-right-5 tw-w-3 tw-h-3 tw-rotate-45 tw-bg-white dark:tw-bg-gray-800 tw-border-b tw-border-r tw-border-gray-200 dark:tw-border-gray-600"></div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Follow-up questions section -->
              <div
                class="follow-up-questions tw-max-w-[600px] tw-rounded-md tw-p-4 tw-my-4"
                *ngIf="message.followUpQuestions?.length  && i === messages.length - 1">
                <h4 class="tw-m-0 dark:tw-text-gray-300">Follow-Up Questions:</h4>
                <ul>
                  <li
                    *ngFor="let question of message.followUpQuestions"
                    class="tw-flex tw-items-center tw-bg-gray-100 dark:tw-bg-gray-800 tw-rounded-lg tw-px-4 tw-my-3 tw-mx-0">
                    <a
                      href="javascript:void(0)"
                      (click)="sendFollowUpOrSampleQuestion(question)"
                      class="tw-text-sm dark:tw-text-gray-400 hover:tw-text-link-color-dynamic-darker tw-py-1">
                      {{ question }}
                      <mat-icon
                        class="tw-text-[16px] tw-leading-5 tw-h-4 tw-w-4 tw-rotate-[-30deg] tw-text-link-color-dynamic tw-pl-1">
                        send
                      </mat-icon>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="!isLoading" class="tw-flex tw-gap-2.5 tw-items-center">
          <mat-form-field appearance="outline" floatLabel="auto" class="tw-flex-1">
            <mat-label class="dark:tw-text-white">
              Ask a
              <span *ngIf="messages.length > 1">follow-up</span> question...
            </mat-label>
            <input
              matInput
              [(ngModel)]="currentPrompt"
              placeholder="E.g., describe the educational background of people at Google"
              (keyup.enter)="sendMessage()"
              [disabled]="isLoading"
              class="dark:tw-text-gray-200" />
            <button
              mat-icon-button
              matSuffix
              color="primary"
              (click)="sendMessage()"
              [disabled]="isLoading || !currentPrompt?.trim()"
              class="tw--mr-2">
              <mat-icon>send</mat-icon>
            </button>
          </mat-form-field>
        </div>

        <!-- Sample questions to ask to first-time users -->
        <!-- TODO: create a component to reuse this -->
        <div
          *ngIf="messages.length === 0 && sampleQuestions.length"
          class="follow-up-questions tw-max-w-[900px] tw-rounded-md">
          <ul>
            <li
              *ngFor="let question of sampleQuestions"
              class="tw-flex tw-items-center tw-bg-gray-100 dark:tw-bg-gray-800 tw-rounded-lg tw-px-4 tw-py-1 tw-my-3 tw-mx-0">
              <a
                href="javascript:void(0)"
                (click)="sendFollowUpOrSampleQuestion(question, true)"
                class="tw-text-sm dark:tw-text-gray-400 tw-py-1 hover:tw-text-link-color-dynamic-darker">
                {{ question }}
                <mat-icon
                  class="tw-text-[16px] tw-leading-4 tw-h-4 tw-w-4 tw-rotate-[-30deg] tw-text-link-color-dynamic tw-pl-1">
                  send
                </mat-icon>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <footer
        class="tw-flex tw-justify-end md:tw-justify-between tw-items-end tw-px-4 tw-py-1 md:tw-py-2 tw-text-center lg:tw-px-6 tw-mt-auto"
        [ngClass]="{'tw-fixed tw-bottom-0 tw-left-0 tw-right-0': messages.length === 0 || isLoading }">
        <p class="tw-hidden md:tw-block tw-text-sm tw-text-gray-400 tw-mb-1 tw-mt-0">
          &copy; 2025 Workforce.ai. All rights reserved.
        </p>
        <p
          class="tw-flex tw-flex-row md:tw-flex-col tw-items-center md:tw-items-start tw-gap-2 md:tw-gap-1 tw-text-left tw-mb-1 tw-mt-0">
          <span class="tw-text-xs">Powered by:</span>
          <span>
            <img
              src="../../assets/img/logo-white.svg"
              alt="live data tech"
              class="tw-h-4 md:tw-h-6" />
          </span>
        </p>
      </footer>
    </mat-drawer-content>
  </mat-drawer-container>
</div>

<app-streams-subscribe-drawer
  [quickBuild]="quickBuildForSubscription"
  [filtersSelectedTab]="drawerService.filtersSelectedTab$ | async"
  [isOpen]="drawerService.isDrawerOpen$ | async"></app-streams-subscribe-drawer>
