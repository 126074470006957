<div class="chat-dialog">
  <h2 mat-dialog-title class="tw-flex tw-items-center tw-leading-[64px]">
    <span>AI Analysis</span>
    <mat-icon class="tw-ml-2 tw-text-gray-500">auto_awesome</mat-icon>
  </h2>

  <button
    mat-icon-button
    (click)="close()"
    class="tw-absolute tw-top-2 tw-right-2 tw-text-gray-800 dark:tw-text-gray-200">
    <mat-icon>close</mat-icon>
  </button>

  <mat-dialog-content class="tw-pt-0">
    <!-- loading state -->
    <div *ngIf="data.isLoading" class="tw-flex tw-flex-col tw-items-center tw-pb-8 tw-pt-4">
      <mat-icon>hourglass_empty</mat-icon>
      <div class="tw-flex tw-items-center">
        <p class="tw-text-lg">Analyzing company data</p>
        <div class="loading-dots">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>

    <div *ngIf="!data.isLoading">
      <div *ngFor="let item of data.content | keyvalue">
        <markdown [data]="item.value"></markdown>
      </div>
    </div>
  </mat-dialog-content>
</div>
