<div class="tw-flex tw-flex-wrap tw-flex-col tw-mt-4">
  <div class="tw-bg-white dark:tw-bg-gray-800 tw-rounded-sm">
    <!-- 1st row -->
    <div class="tw-flex">
      <div class="tw-flex tw-items-center tw-px-5 tw-py-6">
        <div class="tw-mx-5">
          <div class="tw-text-4xl tw-font-semibold tw-text-gray-700 dark:tw-text-gray-200">
            Streams Trials
          </div>
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-px-5 tw-py-6">
        <div class="tw-p-3 tw-rounded-full tw-bg-orange-500 tw-bg-opacity-75"></div>

        <div class="tw-mx-5">
          <div class="tw-text-4xl tw-font-semibold tw-text-gray-700 dark:tw-text-gray-200">
            {{getActiveSubscriptionCount()}}
          </div>
          <div class="tw-text-gray-500 dark:tw-text-gray-400">Active Streams Trials</div>
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-px-5 tw-py-6">
        <div class="tw-p-3 tw-rounded-full tw-bg-orange-500 tw-bg-opacity-75"></div>

        <div class="tw-mx-5">
          <div class="tw-text-4xl tw-font-semibold tw-text-gray-700 dark:tw-text-gray-200">
            {{getRecentSubscriptCount()}}
          </div>
          <div class="tw-text-gray-500 dark:tw-text-gray-400">New Last 7 Days</div>
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-px-5 tw-py-6">
        <div class="tw-p-3 tw-rounded-full tw-bg-orange-500 tw-bg-opacity-75"></div>

        <div class="tw-mx-5">
          <div class="tw-text-4xl tw-font-semibold tw-text-gray-700 dark:tw-text-gray-200">
            {{getRecentUnsubCount()}}
          </div>
          <div class="tw-text-gray-500 dark:tw-text-gray-400">Unsub Last 7 Days</div>
        </div>
      </div>
    </div>

    <!-- 2nd row -->
    <div class="tw-flex">
      <div class="tw-flex tw-items-center tw-px-5 tw-py-6">
        <div class="tw-mx-5">
          <div class="tw-text-4xl tw-font-semibold tw-text-gray-700 dark:tw-text-gray-200">
            Moneyball Trials
          </div>
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-px-5 tw-py-6">
        <div class="tw-p-3 tw-rounded-full tw-bg-orange-500 tw-bg-opacity-75"></div>

        <div class="tw-mx-5">
          <div class="tw-text-4xl tw-font-semibold tw-text-gray-700 dark:tw-text-gray-200">
            {{activeMBTrialCount}}
          </div>
          <div class="tw-text-gray-500 dark:tw-text-gray-400">Active MB Trials</div>
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-px-5 tw-py-6">
        <div class="tw-p-3 tw-rounded-full tw-bg-orange-500 tw-bg-opacity-75"></div>

        <div class="tw-mx-5">
          <div class="tw-text-4xl tw-font-semibold tw-text-gray-700 dark:tw-text-gray-200">
            {{expiredMBTrialCount}}
          </div>
          <div class="tw-text-gray-500 dark:tw-text-gray-400">Expired MB Trials</div>
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-px-5 tw-py-6">
        <div class="tw-p-3 tw-rounded-full tw-bg-orange-500 tw-bg-opacity-75"></div>

        <div class="tw-mx-5">
          <div class="tw-text-4xl tw-font-semibold tw-text-gray-700 dark:tw-text-gray-200">
            {{recentlyCreatedMBTrialCount}}
          </div>
          <div class="tw-text-gray-500 dark:tw-text-gray-400">New Last 7 Days</div>
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-px-5 tw-py-6">
        <div class="tw-p-3 tw-rounded-full tw-bg-orange-500 tw-bg-opacity-75"></div>

        <div class="tw-mx-5">
          <div class="tw-text-4xl tw-font-semibold tw-text-gray-700 dark:tw-text-gray-200">
            {{recentlyExpiredMBTrialCount}}
          </div>
          <div class="tw-text-gray-500 dark:tw-text-gray-400">Expired Last 7 Days</div>
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-px-5 tw-py-6">
        <div class="tw-p-3 tw-rounded-full tw-bg-orange-500 tw-bg-opacity-75"></div>

        <div class="tw-mx-5">
          <div class="tw-text-4xl tw-font-semibold tw-text-gray-700 dark:tw-text-gray-200">
            {{expiringSoonMBTrialCount}}
          </div>
          <div class="tw-text-gray-500 dark:tw-text-gray-400">Expiring Next 7 Days</div>
        </div>
      </div>
    </div>
  </div>

  <!-- charts -->
  <div class="tw-mt-4">
    <div class="tw-flex tw-aspect-auto tw-w-full" id="dashboard-signupChartContainer"></div>
    <div class="tw-flex tw-flex-wrap tw-gap-4 tw-mt-4">
      <div class="tw-aspect-square tw-max-w-96" id="dashboard-cumSignupChartContainer"></div>
      <div class="tw-aspect-square tw-max-w-96" id="dashboard-weeklyGrowthChartContainer"></div>
      <div class="tw-aspect-square tw-max-w-96" id="dashboard-activeChartContainer"></div>
      <div class="tw-aspect-square tw-max-w-96" id="dashboard-topCompaniesChartContainer"></div>
    </div>
  </div>
</div>
