import { Injectable } from '@angular/core';
import { SearchPersonsDataRequest, SearchService } from 'ldt-people-api';
import { QuickBuild, QuickBuildService, UIFilterGroup } from './quick-build.service';
import { map, Observable, of, tap } from 'rxjs';
import { Log } from '../logger.service';
import { AuthService } from '../auth/service/auth.service';
export interface ReportResponse {
  count: number;
  data: any;
}

@Injectable({
  providedIn: 'root',
})
export class PeopleReportService {
  private apiCache = new Map<string, any>(); // Map to store API responses
  private cacheSizeInBytes = 0;
  private maxCacheSize = 100; // Maximum number of cached items allowed

  constructor(
    private reportService: SearchService,
    private quickBuildService: QuickBuildService,
    private auth: AuthService
  ) {}

  // Add the API response to the cache FIFO eviction
  private addToCache(key: string, response: any): void {
    if (this.apiCache.size >= this.maxCacheSize) {
      const firstKey = this.apiCache.keys().next().value!;
      this.apiCache.delete(firstKey);
    }
    this.apiCache.set(key, response);

    const newItemSize = PeopleReportService.getObjectSize(response);
    this.cacheSizeInBytes += newItemSize;
    const cacheStats = this.getCacheStats();
    Log.d('Cache stats:', cacheStats);
  }

  // Add method to calculate size of object in bytes
  private static getObjectSize(obj: any): number {
    const str = JSON.stringify(obj);
    return str.length * 2;
  }

  // Add method to get current cache size
  private getCacheStats(): { sizeInBytes: number; sizeInMB: number; itemCount: number } {
    return {
      sizeInBytes: this.cacheSizeInBytes,
      sizeInMB: this.cacheSizeInBytes / 1024 / 1024,
      itemCount: this.apiCache.size,
    };
  }

  // Get report data by report ID and quick build
  getReportDataById(reportId: string, quickBuild: QuickBuild): Observable<ReportResponse> {
    Log.d('getReportDataById-quickBuild', quickBuild, reportId);
    const apiFilters = QuickBuildService.rootFilterGroupToAPIFilters(
      quickBuild.rootFilterGroup,
      reportId
    );

    Log.d('getReportDataById-apiFilters', apiFilters);

    let req: SearchPersonsDataRequest = {
      filters: [apiFilters[0]],
      size: 0,
    };

    const hash = JSON.stringify(req);
    if (this.apiCache.has(hash)) {
      return of(this.apiCache.get(hash));
    }

    return this.reportService.searchPersonsData(this.auth.getSelectedOrgIdValue, req).pipe(
      map((res: any) => {
        return {
          count: res.count,
          data: res.report_results,
        };
      }),
      tap((res: any) => {
        this.addToCache(hash, res);
      })
    );
  }

  // Get report data with report details
  getReportDataWithReportDetails(
    reportId: string,
    reportType: string,
    reportParams: any,
    reportTarget: string,
    quickBuild: QuickBuild
  ): Observable<ReportResponse> {
    Log.d('getReportDataWithReportDetails-quickBuild', quickBuild, reportId, reportTarget);

    // If the report target is not provided, use the root filter group id
    if (!reportTarget && quickBuild.rootFilterGroup.id) {
      reportTarget = quickBuild.rootFilterGroup.id;
    }

    // We get a copy of the quick build, remove all the reports since we can only do one at a time,
    // and we add the one we want back into it
    const qbCopy = QuickBuildService.copyQuickBuildWithoutReports(quickBuild);
    const report = {
      id: reportId,
      name: reportType,
      params: reportParams,
    };

    Log.d('getReportDataWithReportDetails-report', report);
    PeopleReportService.addReportToQuickBuild(qbCopy, reportTarget, report);
    Log.d('getReportDataWithReportDetails-qbCopy', qbCopy);

    return this.getReportDataById(report.id, qbCopy);
  }

  // Add a report to the quick build at the given location
  private static addReportToQuickBuild(qb: QuickBuild, reportTarget: string, report: any) {
    let groupToModify = PeopleReportService.findGroupToModify(qb.rootFilterGroup, reportTarget);

    if (!groupToModify) {
      groupToModify = PeopleReportService.findFirstJobsGroup(qb.rootFilterGroup);

      if (!groupToModify) {
        throw new Error('Group to modify not found');
      }
    }

    groupToModify.reports = [report];
  }

  // Find the group to modify in the quick build
  private static findGroupToModify(
    group: UIFilterGroup,
    reportTarget: string
  ): UIFilterGroup | undefined {
    if (group.id === reportTarget) {
      return group;
    }

    const filterGroupFilters = group.filters.filter((f) => 'filters' in f) as UIFilterGroup[];

    for (const filter of filterGroupFilters) {
      const result = PeopleReportService.findGroupToModify(filter, reportTarget);
      if (result) {
        return result;
      }
    }

    return undefined;
  }

  // Find the first jobs group in the quick build
  private static findFirstJobsGroup(group: UIFilterGroup): UIFilterGroup | undefined {
    if (group.isJobsGroup) {
      return group;
    }

    const filterGroupFilters = group.filters.filter((f) => 'filters' in f) as UIFilterGroup[];

    for (const filter of filterGroupFilters) {
      const result = PeopleReportService.findFirstJobsGroup(filter);
      if (result) {
        return result;
      }
    }

    return undefined;
  }
}
