import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import { reportTreemapOptions } from '../highcharts-options';
import { QuickBuildService } from 'src/app/people/quick-build.service';
import { PeopleReportService } from 'src/app/people/report-service.service';
import { departments, ReportVizChartColors } from '../constants';
import { getPeopleColumnByName } from 'src/app/people/people-columns';
import { BaseReportComponent } from '../base-report.component';
import { Log } from 'src/app/logger.service';

const FIELDS_TO_GROUP_BY = ['jobs.function', 'jobs.level'];
const REPORT_NAME = 'demographics';

@Component({
  selector: 'app-reports-functional-distribution',
  templateUrl: './functional-distribution.component.html',
  styleUrls: ['./functional-distribution.component.scss'],
})
export class FunctionalDistributionComponent extends BaseReportComponent {
  breakdownByOptions = FIELDS_TO_GROUP_BY;

  protected readonly CHART_ID = 'functional-distribution';

  constructor(reportService: PeopleReportService, quickBuildService: QuickBuildService) {
    super(reportService, quickBuildService);
  }

  protected initializeChart(): void {
    this.chart = Highcharts.chart(`highchartsContainer-${this.chartId}`, reportTreemapOptions);
    this.chart.update({ title: { text: 'Functional Distribution' } }, true, true);
  }

  protected getChartData(): void {
    if (!this.quickBuild) return;

    const reportParams = {
      group_by: ['jobs.function', 'jobs.level'],
    };
    const sub = this.reportService
      .getReportDataWithReportDetails(
        this.reportId,
        REPORT_NAME,
        reportParams,
        this.reportTarget,
        this.quickBuild
      )
      .subscribe((data) => {
        Log.d('FunctionalDistributionComponent data', data);
        const series = this.generateChartSeries(data.count, data.data.demographics);
        this.updateChart(series);
      });
    this.subscriptions.add(sub);
  }

  protected generateChartSeries(total: number, data: any): any[] {
    Log.d('FunctionalDistributionComponent generateChartSeries', total, data);

    let points: any[] = [];
    let deptCounts = data.reduce(
      (r: any, i: any) => (
        (r[i.group_values[0].value] = (r[i.group_values[0].value] || 0) + i.count_employees), r
      ),
      {}
    );
    Object.keys(deptCounts).forEach((d: any) => {
      const keyIndex = this.departmentIndexer(d);
      const color = ReportVizChartColors[keyIndex];
      points.push({ id: 'id-' + keyIndex, name: d, value: deptCounts[d], color: color });
    });
    data.forEach((d: any, i: any) => {
      const dIndex = this.departmentIndexer(d.group_values[0].value);
      points.push({
        id: 'id-' + dIndex + '_' + i,
        name: d.group_values[1].value,
        value: d.count_employees,
        parent: 'id-' + dIndex,
      });
    });

    return [
      {
        name: 'Employees',
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        allowDrillToNode: true,
        animationLimit: 1000,
        dataLabels: {
          enabled: false,
        },
        levels: [
          {
            level: 1,
            dataLabels: {
              enabled: true,
            },
            borderWidth: 3,
            levelIsConstant: false,
          },
          {
            level: 1,
            dataLabels: {
              style: {
                fontSize: '14px',
              },
            },
          },
        ],
        accessibility: {
          exposeAsGroupOnly: true,
        },
        data: points,
      },
    ];
  }

  departmentIndexer(dept: any) {
    return departments.indexOf(dept);
  }

  getFieldDisplayName(field: string): string {
    return getPeopleColumnByName(field).displayName;
  }
}
