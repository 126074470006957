<div class="charts-actions tw-flex tw-flex-row tw-justify-between tw-mb-2">
  <div class="tw-flex tw-items-center tw-space-x-1">
    <span class="tw-text-sm tw-font-medium">Show:</span>
    <mat-button-toggle-group
      #group="matButtonToggleGroup"
      name="values"
      aria-label="Values"
      (change)="toggleHeadcountChartType($event)"
      class="moneyBattleToggle tw-h-6 tw-border tw-border-solid tw-border-gray-300">
      <mat-button-toggle
        value="histogram"
        class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
        [checked]="selectedHeadcountChartType === 'histogram'">
        <ng-container *ngIf="selectedHeadcountChartType === 'histogram'">
          <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
        </ng-container>
        By Month
      </mat-button-toggle>
      <mat-button-toggle
        value="column"
        class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
        [checked]="selectedHeadcountChartType === 'column'">
        <ng-container *ngIf="selectedHeadcountChartType === 'column'">
          <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
        </ng-container>
        Total
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="tw-flex tw-items-center tw-space-x-1">
    <span class="tw-text-sm tw-font-medium">Values:</span>
    <mat-button-toggle-group
      #group="matButtonToggleGroup"
      name="values"
      aria-label="Values"
      (change)="toggleHeadcountValueType($event)"
      class="moneyBattleToggle tw-h-6 tw-border tw-border-solid tw-border-gray-300 dark:tw-border-none">
      <mat-button-toggle
        value="percentage"
        class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
        [checked]="selectedHeadcountChartValueType === 'percentage'">
        <ng-container *ngIf="selectedHeadcountChartValueType === 'percentage'">
          <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
        </ng-container>
        %
      </mat-button-toggle>
      <mat-button-toggle
        value="number"
        class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
        [checked]="selectedHeadcountChartValueType === 'number'">
        <ng-container *ngIf="selectedHeadcountChartValueType === 'number'">
          <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
        </ng-container>
        #
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</div>
<div class="tw-h-[400px] mat-card-shadow tw-rounded">
  <div
    id="highchartsContainer-{{ chartId }}"
    style="width: 100%; height: 100%"
    class="tw-rounded"></div>
</div>
