import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { StreamsSubscribeDrawerService } from './streams-subscribe-drawer.service';
import { QuickBuild } from 'src/app/people/quick-build.service';
import { NotificationService } from '../notification-service/notification.service';
import { Router } from '@angular/router';
import { AlertType } from '../alert/alert.component';
import { Subscription } from 'rxjs';
import { Log } from 'src/app/logger.service';

@Component({
  selector: 'app-streams-subscribe-drawer',
  templateUrl: './streams-subscribe-drawer.component.html',
  animations: [
    trigger('drawerAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('300ms ease-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [animate('300ms ease-in', style({ transform: 'translateX(100%)' }))]),
    ]),
    trigger('backdropAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('300ms ease-in', style({ opacity: 0 }))]),
    ]),
    trigger('overlayAnimation', [
      transition(':leave', [animate('300ms ease-in', style({ opacity: 0 }))]),
    ]),
  ],
  providers: [],
})
export class StreamsSubscribeDrawerComponent implements OnChanges, OnInit, OnDestroy {
  @Input() isOpen = true;
  @Input() title = 'Subscribe to Live Data Streams';
  @Input() filtersSelectedTab: 'moneyball' | 'reports' | 'people' = 'reports';
  @Output() closed = new EventEmitter<void>();
  @Input() quickBuild: QuickBuild;

  mode: 'filters' | 'data-selection' = 'filters';
  selectedOptions = {
    download: true,
    subscribe: false,
  };
  matchingRecords: number = 0;
  alertType = AlertType;
  private serviceSubscription: Subscription;

  constructor(
    private drawerService: StreamsSubscribeDrawerService,
    // private downloadsService: DownloadsService,
    private notify: NotificationService,
    private router: Router
    // private downloadStatusService: DownloadStatusService
  ) {}

  @HostListener('document:keydown.escape')
  onEscapePressed() {
    this.dismiss();
  }

  ngOnChanges(changes: any) {
    if ('quickBuild' in changes) {
      this.quickBuild = changes.quickBuild.currentValue;
    }

    // Update service when isOpen changes from parent
    if ('isOpen' in changes && changes.isOpen.currentValue !== changes.isOpen.previousValue) {
      // if (changes.isOpen.currentValue) {
      //   this.drawerService.openDrawer();
      // } else {
      //   this.drawerService.closeDrawer();
      // }
    }

    Log.d('streams-subscribe-drawer Changes detected', changes);
  }

  ngOnInit() {
    // Subscribe to the service to keep local isOpen state in sync
    this.serviceSubscription = this.drawerService.isDrawerOpen$.subscribe((isOpen) => {
      this.isOpen = isOpen;
    });

    // service knows the initial state
    if (this.isOpen) {
      this.drawerService.openDrawer();
    }
  }

  ngOnDestroy() {
    if (this.serviceSubscription) {
      this.serviceSubscription.unsubscribe();
    }

    // drawer is closed when component is destroyed
    this.drawerService.closeDrawer();
  }

  dismiss() {
    this.drawerService.closeDrawer();
    // this.closed.emit();
  }

  // finalize() {
  //   if (this.mode === 'filters') {
  //     this.mode = 'data-selection';
  //   } else {
  //     this.completePurchase();
  //   }
  // }

  goBack() {
    this.mode = 'filters';
  }

  onOptionSelect(option: 'download' | 'subscribe', checked: boolean) {
    this.selectedOptions[option] = checked;
  }

  hasSelectedOption(): boolean {
    return this.selectedOptions.download || this.selectedOptions.subscribe;
  }

  getOrgId(): string {
    return this.router.url.split('/')[1];
  }

  // Get the downloads page URL with the organization ID
  getDownloadsUrl(): string {
    return `/${this.getOrgId()}/downloads`;
  }

  // // Test complete purchase function that shows the download status component
  // private completePurchase() {
  //   // Create a name for the download based on the filter criteria
  //   const filterSummary = this.getFilterSummary();
  //   const downloadName =
  //     filterSummary.length > 0
  //       ? `${filterSummary} - ${new Date().toLocaleDateString()}`
  //       : `Data Export - ${new Date().toLocaleDateString()}`;

  //   // Check if no options are selected
  //   if (!this.hasSelectedOption()) {
  //     this.notify.error('Please select at least one option: Download or Subscribe');
  //     return;
  //   }

  //   // Track if both options are selected
  //   const bothSelected = this.selectedOptions.download && this.selectedOptions.subscribe;
  //   let downloadData;
  //   let subscriptionData;

  //   // Process download option if selected
  //   if (this.selectedOptions.download) {
  //     downloadData = this.downloadsService.addDownload({
  //       name: downloadName,
  //       description: `${this.matchingRecords} professionals matching your criteria`,
  //       type: 'download',
  //       recordCount: this.matchingRecords,
  //       filterCriteria: this.quickBuild ? this.quickBuild : {},
  //     });

  //     // Only show individual notification if not both selected
  //     if (!bothSelected) {
  //       this.notify.success('Your download is being prepared and will be available shortly.');
  //     }
  //   }

  //   // Process subscription option if selected
  //   if (this.selectedOptions.subscribe) {
  //     subscriptionData = this.downloadsService.addDownload({
  //       name: downloadName,
  //       description: `${this.matchingRecords} professionals matching your criteria`,
  //       type: 'subscription',
  //       recordCount: this.matchingRecords,
  //       filterCriteria: this.quickBuild ? this.quickBuild : {},
  //     });

  //     // Only show individual notification if not both selected
  //     if (!bothSelected) {
  //       this.notify.success('Your subscription has been set up successfully.');
  //     }
  //   }

  //   // Show combined success notification if both options selected
  //   if (bothSelected) {
  //     this.notify.success(
  //       'Your download is being prepared and your subscription has been set up successfully.'
  //     );
  //   }

  //   // Show the download status component for the appropriate data
  //   if (downloadData) {
  //     this.downloadStatusService.showDownloadStatus(downloadData);
  //   } else if (subscriptionData) {
  //     this.downloadStatusService.showDownloadStatus(subscriptionData);
  //   }

  //   // Close the drawer
  //   this.dismiss();
  // }

  // private getFilterSummary(): string {
  //   if (!this.quickBuild) {
  //     return '';
  //   }

  //   // Since we don't know the exact structure of QuickBuild, we'll use a more generic approach
  //   const filters = this.quickBuild as any;
  //   const parts = [];

  //   // Add job titles if present
  //   if (filters.jobTitles && Array.isArray(filters.jobTitles) && filters.jobTitles.length > 0) {
  //     parts.push(filters.jobTitles.slice(0, 2).join(', '));
  //   }

  //   // Add location if present
  //   if (filters.location) {
  //     parts.push(filters.location);
  //   }

  //   // Add industry if present
  //   if (filters.industry) {
  //     parts.push(filters.industry);
  //   }

  //   return parts.join(' in ');
  // }
}
