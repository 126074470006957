import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';

import * as FileSaver from 'file-saver';

import { GrowService } from './grow.service';
import { NotificationService } from '../shared/notification-service/notification.service';

export interface ResultData {
  checked: boolean;
  name: string;
  company: string;
  title: string;
  lipid: string;
  tenure_avg: number;
  tenure_current: number;
}

@Component({
  selector: 'app-grow',
  templateUrl: './grow.component.html',
  styleUrls: ['./grow.component.scss'],
})
export class GrowComponent implements OnInit {
  loading: Boolean = false;
  resultsColumns: string[] = [
    'checked',
    'name',
    'title',
    'company',
    'linkedIn',
    'tenure_avg',
    'tenure_current',
  ];
  dataSource: MatTableDataSource<ResultData> = new MatTableDataSource<ResultData>();
  sort: MatSort;
  role: string = '';
  company: string = '';
  industry: string = '';
  tags: string = '';

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }

  constructor(
    private growService: GrowService,
    private notify: NotificationService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {}

  totalTenure: any = {
    current_total: 0,
    current_count: 0,
    past_total: 0,
    past_count: 0,
  };

  getResults() {
    this.loading = true;
    this.dataSource.data = [];

    this.growService.grow(this.role, this.company, this.industry, this.tags).subscribe({
      next: (r: any) => {
        // Make unique
        // Reverse so that earlier results take precedence and then reverse again
        this.dataSource.data = <Array<ResultData>>(
          [
            ...new Map(r.results.reverse().map((item: ResultData) => [item.lipid, item])).values(),
          ].reverse()
        );
        this.loading = false;

        if (r.results.length == 0) {
          this.notify.error('No results found');
          return;
        }
      },
      error: (e: any) => {
        console.log(e);
        this.notify.error('Error getting data from Grow service');
        this.loading = false;
      },
    });
  }

  downloadResults(): void {
    const toDownload = this.dataSource.data.filter((e) => e.checked);
    const filename = `${this.role}@${this.company}.csv`.replace(' ', '-');

    const csvString = [
      ['Name', 'Title', 'Company', 'LinkedIn'],
      ...toDownload.map((item) => [
        `"${item.name}"`,
        `"${item.title}"`,
        `"${item.company}"`,
        `https://linkedin.com/in/${item.lipid}`,
      ]),
    ]
      .map((e) => e.join(','))
      .join('\n');

    const data: Blob = new Blob([csvString], {
      type: 'text/csv;charset=utf-8',
    });

    FileSaver.saveAs(data, filename);
  }

  toggleCheckAll(event: any) {
    this.dataSource.data.forEach((e) => {
      e.checked = event.checked;
    });
  }

  mbQuery = {
    query: {
      bool: {
        should: [],
      },
    },
    _source: ['id', 'tenure_months', 'started_at'],
    size: 10000,
  };
}
