<h1
  mat-dialog-title
  class="delete-dialog-title tw-text-gray-800 dark:tw-text-gray-200 tw-text-2xl tw-px-0 tw-mb-4">
  {{data.title}}
</h1>

<div mat-dialog-content class="tw-mb-3">
  <app-alert [alertType]="AlertType.Warning" [style]="'compact'">
    <p class="tw-leading-6 tw-my-0">{{data.message}}</p>
  </app-alert>
</div>

<div mat-dialog-actions class="tw-flex tw-justify-between">
  <button mat-stroked-button [mat-dialog-close]="false">No</button>
  <button mat-raised-button color="warn" [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
</div>
