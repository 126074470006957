<div id="org-setting-editor" class="tw-mb-4">
  <h2 class="section-header tw-mb-2 tw-mt-8">Settings</h2>
  <form
    [formGroup]="form"
    class="tw-grid tw-gap-4 tw-grid-cols-1 md:tw-grid-cols-2 xl:tw-grid-cols-4">
    <mat-card>
      <mat-card-header class="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <mat-card-title class="tw-mb-0">Ledgers</mat-card-title>
        <div class="tw-flex tw-items-center">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeLedgerCapability" />
          <span class="tw-mr-1">Off</span>
          <mat-slide-toggle
            formControlName="ledgerCapability"
            id="ledgerCapability"></mat-slide-toggle>
          <span class="tw-ml-1">On</span>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="tw-mt-2">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeContactLimit" />
          <mat-form-field appearance="outline">
            <mat-label class="tw-mr-1">Ledger Contact Limit</mat-label>
            <input matInput formControlName="contactLimit" id="contactLimit" placeholder="None" />
          </mat-form-field>
        </div>

        <div class="tw-mt-2 tw-font-bold">Features:</div>

        <div>
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeLedgerReinit" />
          <mat-label class="tw-mr-1">Reinit Ledger</mat-label>
          <mat-slide-toggle formControlName="ledgerReinit" id="ledgerReinit"></mat-slide-toggle>
        </div>
        <div>
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeLedgerUiApiVersion" />
          <mat-label class="tw-mr-1">API Version</mat-label>
          V1
          <mat-slide-toggle
            formControlName="ledgerUiApiVersion"
            [disabled]="form.get('ledgerUiApiVersion')?.value"
            id="ledgerUiApiVersion"></mat-slide-toggle>
          V2
        </div>
        <div>
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeLedgerUiApiVersionSelector" />
          <mat-label class="tw-mr-1">Version Picker</mat-label>
          No
          <mat-slide-toggle
            formControlName="ledgerUiApiVersionSelector"
            [disabled]="!form.get('ledgerUiApiVersionSelector')?.value"
            id="ledgerUiApiVersionSelector"></mat-slide-toggle>
          Yes
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header class="tw-flex tw-justify-between tw-items-center tw-mb-6">
        <mat-card-title class="tw-mb-0">Moneyball</mat-card-title>
        <div class="tw-flex tw-items-center">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeMoneyballCapability" />
          <span class="tw-mr-1">Off</span>
          <mat-slide-toggle
            formControlName="moneyballCapability"
            id="moneyballCapability"></mat-slide-toggle>
          <span class="tw-ml-1">On</span>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="tw-flex tw-gap-2 tw-items-center tw-flex-wrap">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeMoneyballExpiration">
            <span class="tw-text-xs">Include Moneyball Expiration</span>
          </mat-checkbox>
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="useStripeForMoneyballExpiration">
            <span class="tw-text-xs">Use Stripe for Moneyball Expiration</span>
          </mat-checkbox>
          <mat-form-field class="tw-mr-1">
            <input
              class="tw-w-2/3"
              formControlName="moneyballExpiration"
              matInput
              [matDatepicker]="picker"
              [min]="minExpirationDate"
              placeholder="Expiration Date" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <button
            matSuffix
            mat-stroked-button
            aria-label="Clear"
            (click)="clearExpiration()"
            class="tw-p-0 tw-max-h-9">
            Clear
          </button>
        </div>
        <!-- Subscription Type -->
        <div class="tw-flex tw-items-start tw-gap-4 tw-mt-6">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeMoneyballSubscriptionType" />
          <mat-label class="tw-mr-1">Subscription Type</mat-label>
          <mat-radio-group
            formControlName="moneyballSubscriptionType"
            class="tw-flex tw-flex-col tw-gap-2">
            <mat-radio-button *ngFor="let type of subscriptionTypes" [value]="type.value">
              {{ type.label }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="tw-mt-6">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeMoneyballEntitledSeats" />
          <mat-form-field appearance="outline">
            <mat-label class="tw-mr-1">Moneyball Seats</mat-label>
            <input
              matInput
              formControlName="moneyballEntitledSeats"
              id="moneyballEntitledSeats"
              placeholder="None" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header class="tw-flex tw-justify-between tw-items-center">
        <mat-card-title class="tw-mb-0">Jobs</mat-card-title>
        <div class="tw-flex tw-items-center">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludeJobsCapability" />
          <span class="tw-mr-1">Off</span>
          <mat-slide-toggle formControlName="jobsCapability" id="jobsCapability"></mat-slide-toggle>
          <span class="tw-ml-1">On</span>
        </div>
      </mat-card-header>
      <mat-card-content> </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-header class="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <mat-card-title class="tw-mb-0">People</mat-card-title>
        <div class="tw-flex tw-items-center">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludePeopleCapability" />
          <span class="tw-mr-1">Off</span>
          <mat-slide-toggle
            formControlName="peopleCapability"
            id="peopleCapability"></mat-slide-toggle>
          <span class="tw-ml-1">On</span>
        </div>
      </mat-card-header>
      <mat-card-content>
        <div class="tw-mt-2 tw-font-bold">Subscription:</div>

        <!-- Subscription Type -->
        <div class="tw-flex tw-items-start tw-gap-4 tw-mt-2">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludePeopleSubscriptionType" />
          <mat-label class="tw-mr-1">Type</mat-label>
          <mat-radio-group
            formControlName="peopleSubscriptionType"
            class="tw-flex tw-flex-col tw-gap-2">
            <mat-radio-button value="free">Free</mat-radio-button>
            <mat-radio-button value="unlimited">Unlimited</mat-radio-button>
            <mat-radio-button value="metered">Metered</mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- Monthly Credits -->
        <div class="tw-mt-6">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludePeopleMonthlyCredits" />
          <mat-form-field appearance="outline">
            <mat-label>Monthly Credits</mat-label>
            <input
              matInput
              formControlName="peopleMonthlyCredits"
              type="number"
              [disabled]="form.get('peopleSubscriptionType')?.value === 'paid'"
              placeholder="Enter credits" />
          </mat-form-field>
        </div>
        <div class="tw-mt-2 tw-font-bold">Features:</div>

        <!-- feature explore -->
        <div class="tw-mt-2">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludePeopleFeatureExplore" />
          <mat-label class="tw-mr-1" for="peopleFeatureExplore">
            <span class="badge-selected-items tw-bg-gray-600">Explore</span>
          </mat-label>
          No
          <mat-slide-toggle
            formControlName="peopleFeatureExplore"
            id="peopleFeatureExplore"></mat-slide-toggle>
          Yes
        </div>

        <!-- feature chat -->
        <div class="tw-mt-2 ">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludePeopleFeatureChatToQuery" />
          <mat-label class="tw-mr-1" for="peopleFeatureChatToQuery">
            -> <span class="badge-selected-items tw-bg-gray-600">Chat-Based Filters</span>
          </mat-label>
          No
          <mat-slide-toggle
            formControlName="peopleFeatureChatToQuery"
            id="peopleFeatureChatToQuery"></mat-slide-toggle>
          Yes
        </div>

        <div class="tw-mt-2 ">
          <mat-checkbox
            *ngIf="usePartialUpdate"
            class="tw-mr-2"
            formControlName="shouldIncludepeopleFeatureChat" />
          <mat-label class="tw-mr-1" for="peopleFeatureChat">
            <span class="badge-selected-items tw-bg-gray-600">Chat</span>
          </mat-label>
          No
          <mat-slide-toggle
            formControlName="peopleFeatureChat"
            id="peopleFeatureChat"></mat-slide-toggle>
          Yes
        </div>
      </mat-card-content>
    </mat-card>
  </form>
</div>
