<div class="tw-flex tw-row tw-justify-between tw-items-center tw-flex-wrap tw-mb-4">
  <div>
    <h1 class="content-title tw-items-center tw-mb-0 tw-mt-0">
      Moneyball Battle
      <span
        class="tw-inline-flex tw-items-center tw-rounded-md tw-leading-4 tw-bg-blue-50 tw-text-sm tw-font-semibold tw-text-blue-700 dark:tw-bg-transparent dark:tw-text-teal-400 dark:tw-ring-teal-400/50 tw-ring-1 tw-ring-inset tw-ring-blue-700/50 tw-px-2 tw-py-1 tw-ml-1">
        beta
      </span>
    </h1>
    <p class="tw-text-sm dark:tw-text-gray-300 tw-mt-2">
      Select up to ten companies to see their data.
    </p>
  </div>
</div>

<!-- filters -->
<div
  class="tw-text-sm tw-text-gray-800 tw-rounded tw-border tw-border-solid tw-bg-white tw-border-gray-300 tw-relative dark:tw-bg-gray-800 dark:tw-text-white dark:tw-border-none tw-p-4">
  <!-- companies & search -->
  <div
    class="moneyballBattleContainer tw-max-w-[1800px] tw-flex tw-gap-x-2 tw-gap-y-4 tw-items-center tw-flex-wrap tw-mb-5">
    <div
      *ngFor="let company of companies | keyvalue "
      class="company-tag-selected tw-flex tw-justify-between tw-items-center tw-rounded tw-border tw-border-solid tw-border-gray-400 tw-text-sm tw-font-medium tw-h-10 tw-max-w-80 dark:tw-border-gray-500 dark:hover:tw-bg-gray-600 tw-pl-3 tw-pr-2">
      <a
        class="tw-flex tw-items-center tw-flex-1 tw-max-w-[17rem] tw-mr-1"
        (click)="goToMoneyball(company.key)"
        href="javascript:void(0)">
        <mat-icon
          class="tw-inline-block tw-text-base tw-flex-shrink-0"
          matTooltip="Go to company info page"
          matTooltipPosition="above">
          open_in_new
        </mat-icon>

        <span
          class="hover:tw-underline tw-whitespace-nowrap tw-overflow-hidden tw-text-ellipsis tw-font-semibold">
          {{company.value.company.company.name}}
        </span>
        <img
          *ngIf="company.value.company.type === 'group'"
          src="../../assets/img/office-building.png"
          alt="office building conglomerate"
          class="tw-w-6 tw-align-middle tw-ml-1" />
      </a>
      <button
        class="icon-btn tw-text-dark-light-dynamic tw-bg-transparent tw-border tw-border-solid tw-border-transparent tw-rounded-sm hover:tw-bg-gray-200 tw-cursor-pointer focus:tw-rounded focus:tw-bg-gray-200 dark:hover:tw-bg-gray-800 dark:focus:tw-bg-gray-800 tw-transition-all"
        (click)="removeCompanyFromList(company.key)">
        <mat-icon
          class="close-icon tw-inline-block tw-text-base tw-leading-[26px] tw-font-semibold">
          clear
        </mat-icon>
      </button>
    </div>

    <app-company-search
      *ngIf="companiesAsArray().length < maxCompanies"
      resetAfterSelection="true"
      [labelText]="'Add a company'"
      [floatLabel]="'always'"
      (companySelected)="companySelected($event)"
      class="moneyball-battle-search" />

    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      aria-label="open more actions"
      class="tw-w-auto tw-text-gray-800 tw-rounded-md tw-border tw-border-solid tw-border-gray-300 tw-leading-8 focus:tw-bg-gray-100 hover:tw-bg-gray-100 dark:focus:tw-bg-gray-900 dark:hover:tw-bg-gray-900 tw-transition-all dark:tw-text-white dark:tw-border-gray-500 tw-px-4">
      Actions
      <mat-icon>more_horiz</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="loadList()">
        <mat-icon>collections_bookmark</mat-icon>
        <span>Load Company List</span>
      </button>
      <button mat-menu-item [disabled]="companiesAsArray().length === 0" (click)="clearCompanies()">
        <mat-icon>delete_outline</mat-icon>
        <span>Clear Companies</span>
      </button>
    </mat-menu>
  </div>

  <div>
    <div
      class="tw-flex tw-flex-col mdx:tw-flex-row tw-justify-between tw-gap-x-2 tw-gap-y-4 tw-flex-wrap tw-pt-5">
      <app-date-range-picker
        [startRange]="dateRange"
        (selectedDateRange)="onDateRangeChanged($event)"
        class="tw-max-w-96"></app-date-range-picker>
      <app-job-function-dropdown
        [multiSelect]="true"
        [preSelectedJobFunctions]="appliedJobFunctions"
        (jobFunctionSelectionChanged)="onJobFunctionSelectionChanged($event)"
        class="tw-flex-1 tw-max-w-96 mdx:tw-ml-auto"></app-job-function-dropdown>
      <app-job-level-dropdown
        [multiSelect]="true"
        [preSelectedJobLevels]="appliedJobLevels"
        (jobLevelSelectionChanged)="onJobLevelSelectionChanged($event)"
        class="tw-flex-1 tw-max-w-96"></app-job-level-dropdown>
    </div>
  </div>
</div>

<div class="tw-grid tw-gap-4 tw-grid-cols-2 tw-w-full tw-mt-10">
  <!-- Chart Headcount -->
  <div
    id="chart-container-headcount"
    class="tw-flex tw-flex-col tw-col-span-2 xl:tw-col-span-1 tw-h-full tw-max-h-[500px]">
    <div class="charts-actions tw-flex tw-flex-row tw-justify-between tw-mb-2">
      <div class="tw-flex tw-items-center tw-space-x-1">
        <span class="tw-text-sm tw-font-medium">Show:</span>
        <mat-button-toggle-group
          #group="matButtonToggleGroup"
          name="values"
          aria-label="Values"
          (change)="toggleHeadcountChartType($event)"
          class="moneyBattleToggle tw-h-6 tw-border tw-border-solid tw-border-gray-300">
          <mat-button-toggle
            value="histogram"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedHeadcountChartType === 'histogram'">
            <ng-container *ngIf="selectedHeadcountChartType === 'histogram'">
              <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
            </ng-container>
            By Month
          </mat-button-toggle>
          <mat-button-toggle
            value="column"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedHeadcountChartType === 'column'">
            <ng-container *ngIf="selectedHeadcountChartType === 'column'">
              <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
            </ng-container>
            Total
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div class="tw-flex tw-items-center tw-space-x-1">
        <span class="tw-text-sm tw-font-medium">Values:</span>
        <mat-button-toggle-group
          #group="matButtonToggleGroup"
          name="values"
          aria-label="Values"
          (change)="toggleHeadcountValueType($event)"
          class="moneyBattleToggle tw-h-6 tw-border tw-border-solid tw-border-gray-300 dark:tw-border-none">
          <mat-button-toggle
            value="percentage"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedHeadcountChartValueType === 'percentage'">
            <ng-container *ngIf="selectedHeadcountChartValueType === 'percentage'">
              <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
            </ng-container>
            %
          </mat-button-toggle>
          <mat-button-toggle
            value="number"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedHeadcountChartValueType === 'number'">
            <ng-container *ngIf="selectedHeadcountChartValueType === 'number'">
              <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
            </ng-container>
            #
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="tw-h-[400px] mat-card-shadow tw-rounded">
      <div id="arrDepChartContainer" style="width: 100%; height: 100%" class="tw-rounded"></div>
    </div>
  </div>

  <!-- Chart Tenure -->
  <div
    id="chart-container-tenure"
    class="tw-flex tw-flex-col tw-col-span-2 xl:tw-col-span-1 tw-h-full tw-max-h-[500px]">
    <div class="charts-actions tw-flex tw-flex-row tw-justify-end tw-mt-4 xl:tw-mt-0 tw-mb-2">
      <div class="tw-flex tw-items-center tw-space-x-1">
        <span class="tw-text-sm tw-font-medium">Employee Status:</span>
        <mat-button-toggle-group
          #group="matButtonToggleGroup"
          name="values"
          aria-label="Values"
          (change)="toggleTenureStatus($event)"
          class="moneyBattleToggle tw-h-6 tw-border tw-border-solid tw-border-gray-300 dark:tw-border-none">
          <mat-button-toggle
            value="current"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedTenureChartStatus === 'current'">
            <ng-container *ngIf="selectedTenureChartStatus === 'current'">
              <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
            </ng-container>
            Active
          </mat-button-toggle>
          <mat-button-toggle
            value="departed"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedTenureChartStatus === 'departed'">
            <ng-container *ngIf="selectedTenureChartStatus === 'departed'">
              <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
            </ng-container>
            Departed
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="tw-h-[400px] mat-card-shadow tw-rounded">
      <div id="tenureChartContainer" style="width: 100%; height: 100%" class="tw-rounded"></div>
    </div>
  </div>
</div>

<div class="tw-grid tw-gap-4 tw-grid-cols-2 tw-w-full tw-mt-10">
  <!-- Chart Tenure Cohort -->
  <div
    id="chart-container-tenure-cohort"
    class="tw-flex tw-flex-col tw-col-span-2 xl:tw-col-span-1 tw-h-full tw-max-h-[500px]">
    <div class="charts-actions tw-flex tw-flex-row tw-justify-end tw-mb-2">
      <div class="tw-flex tw-items-center tw-space-x-1">
        <span class="tw-text-sm tw-font-medium">Cohort Size (yrs):</span>
        <mat-button-toggle-group
          #group="matButtonToggleGroup"
          name="values"
          aria-label="Values"
          (change)="toggleTenureCohortSize($event)"
          class="moneyBattleToggle tw-h-6 tw-border tw-border-solid tw-border-gray-300 dark:tw-border-none">
          <mat-button-toggle
            value="1"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedTenureCohortSize === '1'">
            <ng-container *ngIf="selectedTenureCohortSize === '1'">
              <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
            </ng-container>
            1
          </mat-button-toggle>
          <mat-button-toggle
            value="2"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedTenureCohortSize === '2'">
            <ng-container *ngIf="selectedTenureCohortSize === '2'">
              <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
            </ng-container>
            2
          </mat-button-toggle>
          <mat-button-toggle
            value="3"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedTenureCohortSize === '3'">
            <ng-container *ngIf="selectedTenureCohortSize === '3'">
              <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
            </ng-container>
            3
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="tw-h-[400px] mat-card-shadow tw-rounded">
      <div
        id="tenureCohortChartContainer"
        style="width: 100%; height: 100%"
        class="tw-rounded"></div>
    </div>
  </div>

  <!-- Chart Churn -->
  <div
    id="chart-container-churn"
    class="tw-flex tw-flex-col tw-col-span-2 xl:tw-col-span-1 tw-h-full tw-max-h-[500px]">
    <div class="charts-actions tw-flex tw-flex-row tw-justify-end tw-mb-2">
      <div class="tw-flex tw-items-center">
        <button
          [matTooltip]="tooltipContent"
          [matTooltipClass]="'multiline-tooltip'"
          matTooltipPosition="above"
          class="tw-flex tw-text-gray-600 dark:tw-text-gray-200">
          <mat-icon class="tw-cursor-pointer"> info_outline </mat-icon>
        </button>
        <span class="tw-text-sm tw-font-medium tw-ml-0 tw-mr-1">Movement:</span>
        <mat-button-toggle-group
          #group="matButtonToggleGroup"
          name="values"
          aria-label="Values"
          (change)="toggleTurnoverRateValueType($event)"
          class="moneyBattleToggle tw-h-6 tw-border tw-border-solid tw-border-gray-300 dark:tw-border-none">
          <mat-button-toggle
            value="departures"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedTurnoverByMonthChartValueType === 'departures'">
            <ng-container *ngIf="selectedTurnoverByMonthChartValueType === 'departures'">
              <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
            </ng-container>
            Churn
          </mat-button-toggle>
          <mat-button-toggle
            value="arrivals"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedTurnoverByMonthChartValueType === 'arrivals'">
            <ng-container *ngIf="selectedTurnoverByMonthChartValueType === 'arrivals'">
              <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
            </ng-container>
            Hiring
          </mat-button-toggle>
          <mat-button-toggle
            value="turnover"
            class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
            [checked]="selectedTurnoverByMonthChartValueType === 'turnover'">
            <ng-container *ngIf="selectedTurnoverByMonthChartValueType === 'turnover'">
              <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
            </ng-container>
            Turnover
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
    <div class="tw-h-[400px] mat-card-shadow tw-rounded">
      <div id="churnRateChartContainer" style="width: 100%; height: 100%" class="tw-rounded"></div>
    </div>
  </div>
</div>

<!-- Chart All Employee Movement -->
<div
  id="chart-container-movement"
  class="tw-flex tw-justify-center tw-w-full  mat-card-shadow tw-rounded tw-mt-10 tw-h-full tw-max-h-[500px]">
  <div id="fromToChartContainer" style="width: 100%; height: 100%" class="tw-rounded"></div>
</div>

<!-- Chart Inter-company Movement -->
<div
  id="chart-container-inter"
  class="tw-flex tw-justify-center tw-w-full tw-h-2/3 mat-card-shadow tw-rounded tw-mt-10">
  <div id="poachingChartContainer" style="width: 100%; height: 100%" class="tw-rounded"></div>
</div>
