import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
export interface ChangeLog {
  resource_type: 'company' | 'alias';
  resource_id: string;
  action: 'create' | 'update' | 'delete';
  fields?: string[];
  oldValues?: Record<string, any>;
  newValues?: Record<string, any>;
  timestamp: Date;
  userId: string;
  userEmail: string;
}

const webhookUrl: string = 'https://eogcz8fydmb8vup.m.pipedream.net';

@Injectable({
  providedIn: 'root',
})
export class ChangeLoggerService {
  constructor(private http: HttpClient) {}

  logChange(log: ChangeLog) {
    this.http.post(webhookUrl, log).subscribe(() => {
      // Do nothing
    });
  }
}
