import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog } from '@angular/material/legacy-dialog';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import {
  InvitationsService,
  Org,
  OrganizationsService,
  UpdateOrgSettingsRequest,
  UserRole,
  UserRolesService,
  UsersService,
} from 'ldt-identity-service-api';
import { GridApi } from 'ag-grid-community';
import { DeleteConfirmationComponent } from 'src/app/delete-confirmation/delete-confirmation.component';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { AuthService } from 'src/app/auth/service/auth.service';
import { InvitationsCreateDialogComponent } from 'src/app/team/invitations-create-dialog/invitations-create-dialog.component';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';
import { OrgSettingsEditorComponent } from '../../shared/org-settings-editor/org-settings-editor.component';
import { UsageService } from 'ldt-billing-service-api';

@Component({
  selector: 'app-identity-org-details',
  templateUrl: './identity-org-details.component.html',
  styleUrls: ['./identity-org-details.component.scss'],
})
export class IdentityOrgDetailsComponent implements OnInit {
  @ViewChild('settingsForm') settingsForm!: OrgSettingsEditorComponent;

  /// AG -GRID --------------
  private simpleFilterParams: any = {
    filterOptions: ['contains'],
    suppressAndOrCondition: true,
  };
  defaultColDef = {
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    filterParams: this.simpleFilterParams,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  columnDefs = [
    { field: 'user.id', checkboxSelection: true, cellRenderer: 'loadingRenderer', maxWidth: 150 },
    { field: 'role', maxWidth: 150, filter: true },
    { field: 'user.name', sort: 'asc' },
    { field: 'user.email' },
    { field: 'user.createdAt' },
    { field: 'user.lastLoginAt' },
  ];
  rowData: UserRole[];
  emails: string = '';
  rowSelection = 'multiple';
  loading: boolean = true;
  org: Org;
  peopleUsageApi: number = 0;
  peopleUsageDelivery: number = 0;
  peopleUsageTotal: number = 0;
  private gridApi: GridApi;
  private orgId: string = '';
  roles: string[] = ['viewer', 'editor', 'admin'];
  role: string;

  components = {
    loadingRenderer: function (params: any) {
      if (params.value !== undefined) {
        return params.value;
      } else {
        return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
      }
    },
  };
  onGridReady(params: any) {
    this.gridApi = params.api;

    // params.api.setDatasource(this);
    this.gridApi.sizeColumnsToFit();
  }

  constructor(
    private userRolesService: UserRolesService,
    private route: ActivatedRoute,
    private orgsService: OrganizationsService,
    private usageService: UsageService,
    private usersService: UsersService,
    private notify: NotificationService,
    public dialog: MatDialog,
    private legacyDialog: MatLegacyDialog,
    private router: Router,
    private auth: AuthService,
    private invService: InvitationsService,
    public darkModeService: DarkModeService
  ) {
    this.orgId = route.snapshot.paramMap.get('orgId') || '';
  }

  ngOnInit() {
    this.orgsService.getOrg(this.orgId).subscribe({
      next: (r: any) => {
        this.org = r;
      },
      error: (err) => {
        if (err.status === 404) {
          this.notify.error('Org ID not found');
        } else {
          this.notify.error(
            'Oops. There was an error during your request. Please try again later.'
          );
        }
        this.loading = false;
      },
    });

    // Get org api usage for scope = this org and w/o historical usage data
    this.usageService.getOrgApiUsage(this.orgId, 'org', false).subscribe({
      next: (r) => {
        this.peopleUsageApi =
          r.usage?.find(
            (u) => u.product_name === 'people' && ['api', 'usage'].includes(u.usage_type)
          )?.total_usage || 0;

        this.peopleUsageDelivery =
          r.usage?.find((u) => u.product_name === 'people' && u.usage_type === 'delivery')
            ?.total_usage || 0;

        this.peopleUsageTotal = this.peopleUsageApi + this.peopleUsageDelivery;
      },
    });
    this.refreshOrgUsers();
  }

  refreshOrgUsers() {
    this.userRolesService.getOrgUsers(this.orgId).subscribe({
      next: (r) => {
        this.rowData = r;
      },
      error: () => {
        this.notify.error('Oops. There was an error during your request. Please try again later.');
      },
    });
  }

  addUsers() {
    const emailList = this.emails.split(',');
    emailList.forEach((e) => {
      this.usersService.getUsers(e.trim()).subscribe({
        next: (u: any) => {
          const matching = u.filter((i: any) => i.email === e.trim());
          if (matching.length === 1) {
            let userId: string = matching[0].id || '';
            let body = [{ userId: userId, role: this.role }];
            this.userRolesService.addOrgUser(this.orgId, body).subscribe({
              next: (r) => {
                this.notify.success('Successfully added: ' + e);
                this.refreshOrgUsers();
              },
              error: () => {
                this.notify.error(
                  'Oops. There was an error during your request. Please try again later.'
                );
              },
            });
          } else {
            this.notify.error('Failed to add: ' + e);
          }
        },
        error: () => {
          this.notify.error(
            'Oops. There was an error during your request. Please try again later.'
          );
        },
      });
    });
  }

  rowsSelected: boolean = false;
  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
    this.rowsSelected = selectedRows.length !== 0;
  }

  removeSelectedUsers() {
    var numToDelete = this.gridApi.getSelectedRows().length;
    var ids: any = this.gridApi.getSelectedRows().map((d: any) => d.user.id);

    const confirmDialog = this.legacyDialog.open(DeleteConfirmationComponent, {
      data: {
        title: 'Confirm User Removal',
        message: 'Are you sure you want to remove ' + numToDelete + ' users from this Org?',
      },
    });
    confirmDialog.afterClosed().subscribe((result: boolean) => {
      if (result === true) {
        this.userRolesService.deleteOrgUsers(this.orgId, { userIds: ids }).subscribe({
          next: (response: any) => {
            this.gridApi.deselectAll();
            this.refreshOrgUsers();
            this.notify.success(numToDelete + ' users successfully removed.');
          },
          error: () => {
            this.notify.error(
              'Oops. There was an error during your request. Please try again later.'
            );
          },
        });
      }
    });
  }

  toggleOrgActive() {
    const statusToSet =
      this.org.status === UpdateOrgSettingsRequest.StatusEnum.Active
        ? UpdateOrgSettingsRequest.StatusEnum.Inactive
        : UpdateOrgSettingsRequest.StatusEnum.Active;
    let body: UpdateOrgSettingsRequest = {
      status: statusToSet,
    };
    this.orgsService.updateOrgSettings(this.org.id, body).subscribe({
      next: (r) => {
        this.org = r;
        this.notify.success('Org Settings Changed');
      },
      error: () => {
        this.notify.error('Problem updating org settings');
      },
    });
  }

  updateOrgSettings() {
    const settings = this.settingsForm.getCurrentSettings();
    let req: UpdateOrgSettingsRequest = {
      settings: settings,
    };
    this.orgsService.updateOrgSettings(this.org.id, req).subscribe({
      next: () => {
        this.notify.success('Org Settings Changed');
      },
      error: () => {
        this.notify.error('Problem updating org settings');
      },
    });
  }

  observeOrg() {
    this.auth.setAdministrativeOrg(this.org);
    this.auth.setSelectedOrgId(this.org.id); // Org ID
    this.router.navigate([this.org.id + '/ledgers']);
  }

  // handle the invite dialog form submission
  showInviteDialog(evt?: MouseEvent) {
    evt?.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    const dialogRef = this.dialog.open(InvitationsCreateDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((data) => {
      if (data && data.emails && Array.isArray(data.emails)) {
        // Confirm it's an array
        data.emails.forEach((email: string) => {
          const body = {
            inviteeEmail: email.trim(),
            inviteeRole: data.role,
          };

          this.invService.createInvitation(this.org.id, body).subscribe({
            next: (r) => {
              this.notify.success(email + ' successfully invited.');
            },
            error: (err) => {
              if (err.status === 409) {
                this.notify.error('User already has access to this org');
              } else {
                this.notify.error('Failed to invite ' + email + '. Please try again later.');
              }
            },
          });
        });
      }
    });
  }
}
