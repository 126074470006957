import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { EventSource } from 'eventsource';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ApiUsage, UsageService } from 'ldt-billing-service-api';
import { environment } from 'src/environments/environment';

// TODO: this will come from the API at some point for FREE, but not yet
const PEOPLE_QUOTA_FREE = 500;

const PEOPLE_QUOTA_WARNING_THRESHOLD = 90;

// The object that observers will receive
export interface CreditUsage {
  orgId: string;
  peopleQuota: number | null;
  peopleUsage?: number;
  peopleUsagePercentage?: number;
  peopleQuotaWarning?: 'warn' | 'error' | null;
  resetDate: Date;
}

// In-memory storage of usage by usage_type, as provided by the API. The initial getOrgApiUsage call will
// provide values for all usage_types, but the SSE stream will only provide values for one usage_type at a time.
// This holds the elements that make up our running total of usage.
interface CreditUsageDetails {
  usage_key: string;
  total_usage: number;
}

@Injectable({
  providedIn: 'root',
})
export class CreditUsageService {
  private orgApiUsageStream: Subscription;

  private creditUsage: CreditUsage | null = null;
  creditUsage$ = new BehaviorSubject<CreditUsage | null>(null);

  private creditUsageDetails: CreditUsageDetails[] = [];

  constructor(
    private authService: AuthService,
    private usageService: UsageService
  ) {
    this.authService.$getSelectedOrg.subscribe((org) => {
      if (!org) {
        this.creditUsage$.next(null);
        return;
      }

      // Bail early if there are no people settings
      if (!org.settings.people) {
        this.creditUsage$.next(null);
        return;
      }

      // For now (upcoming API changes will change this) we use the subscriptionType to determine the quota, or null if no quota
      this.creditUsage = {
        orgId: org.id,
        peopleQuota:
          org.settings.people.subType === 'free'
            ? PEOPLE_QUOTA_FREE
            : org?.settings.people.subType === 'metered'
              ? org?.settings.people.recordLimit
              : null,
        peopleQuotaWarning: null,
        resetDate: new Date(org.settings.people.nextBillingCycleStartDate + 'T00:00:00Z'),
      };
      this.creditUsage$.next(this.creditUsage);

      // Consumers of this service should check if this is null to know if the org has a quota
      if (this.creditUsage.peopleQuota === null) {
        return;
      }

      // Get the initial usage details from the billing API
      this.usageService.getOrgApiUsage(org.id, 'principal', false).subscribe({
        next: (usage) => {
          // Map usage to our interface
          this.creditUsageDetails = usage.usage.map((entry) => ({
            usage_key: this.getApiUsageKey(entry),
            total_usage: entry.total_usage,
          }));

          // Calculate the total usage and stats
          this.calculateTotalUsage();

          // Update observers
          this.creditUsage$.next(this.creditUsage);

          // Start the SSE stream
          this.getOrgApiUsageStream(org.id);
        },
        error: (error) => {
          console.error('error', error);
        },
      });
    });
  }

  // Calculate the total usage and stats to send to consumers so they have very little work to do
  private calculateTotalUsage() {
    this.creditUsage!.peopleUsage = this.creditUsageDetails.reduce(
      (acc, curr) => acc + curr.total_usage,
      0
    );
    this.creditUsage!.peopleUsagePercentage =
      (this.creditUsage!.peopleUsage! / this.creditUsage!.peopleQuota!) * 100;
    this.creditUsage!.peopleQuotaWarning =
      this.creditUsage!.peopleUsagePercentage && this.creditUsage!.peopleUsagePercentage >= 100
        ? 'error'
        : this.creditUsage!.peopleUsagePercentage &&
            this.creditUsage!.peopleUsagePercentage >= PEOPLE_QUOTA_WARNING_THRESHOLD
          ? 'warn'
          : null;
  }

  // Starts streaming from the SSE endpoint to get updates on usage. The endpoint provides the current total for
  // the specified usage_type. Every time a new value is received, it replaces the existing value in the in-memory
  // storage of usage. Then we recalculate the total usage and stats to send to consumers.
  private getOrgApiUsageStream(orgId: string) {
    // If there is already a stream, unsubscribe from it
    if (this.orgApiUsageStream) {
      this.orgApiUsageStream.unsubscribe();
    }

    // Create a new stream
    this.orgApiUsageStream = new Observable<any>((observer) => {
      const url = environment.billingApiBasePath + '/' + orgId + '/usage/stream';

      // We use the eventsource package instead of angular native so we can set auth headers
      const eventSource = new EventSource(url, {
        fetch: (input: any, init: any) =>
          fetch(input, {
            ...init,
            headers: {
              ...init!.headers,
              Authorization: `Bearer ${this.authService.getAccessTokenValue}`,
            },
          }),
      });

      // When a message is received, push it into the observable stream
      eventSource.onmessage = (event: any) => {
        // Parse the message as JSON and push it into the observable stream, raising an error if it's not valid
        try {
          observer.next(JSON.parse(event.data));
        } catch (error) {
          observer.error(error);
        }
      };

      // If there is an error with the SSE stream, notify the observer
      eventSource.onerror = (error: any) => {
        observer.error(error);
        eventSource.close();
      };

      // On unsubscribe, close the EventSource connection
      return () => {
        eventSource.close();
      };
    }).subscribe({
      next: (data: ApiUsage) => {
        // find the matching credit usage details by type and update the total usage or add the new type
        const matchingUsage = this.creditUsageDetails.find(
          (detail) => detail.usage_key === this.getApiUsageKey(data)
        );
        if (matchingUsage) {
          matchingUsage.total_usage = data.total_usage;
        } else {
          // If the usage_type is not in the list, add it
          this.creditUsageDetails.push({
            usage_key: this.getApiUsageKey(data),
            total_usage: data.total_usage,
          });
        }
        this.calculateTotalUsage();
        this.creditUsage$.next(this.creditUsage);
      },
      error: (error) => {
        console.error('error', error);
      },
    });
  }

  private getApiUsageKey(usage: ApiUsage): string {
    return `${usage.org_id}-${usage.principal_id}-${usage.product_name}-${usage.usage_type}`;
  }
}
