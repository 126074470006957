import { Component, Input, SimpleChanges } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import * as Highcharts from 'highcharts';
import { Subscription } from 'rxjs';
import { columnChartXAaxis, reportChartLineOptions } from '../highcharts-options';
import {
  QuickBuild,
  QuickBuildService,
  UniqueIdGenerator,
} from 'src/app/people/quick-build.service';
import { PeopleReportService } from 'src/app/people/report-service.service';
import * as moment from 'moment';
import { getPeopleColumnByName } from 'src/app/people/people-columns';
import { BaseReportComponent } from '../base-report.component';
import { Log } from 'src/app/logger.service';

const REPORT_NAME = 'demographics';

@Component({
  selector: 'app-reports-headcount-comparison',
  templateUrl: './headcount-comparison.component.html',
  styleUrls: ['./headcount-comparison.component.scss'],
})
export class HeadcountComparisonComponent extends BaseReportComponent {
  @Input() quickBuilds: QuickBuild[];
  @Input() reportTarget: string;
  @Input() dateFrom: moment.Moment;
  @Input() dateTo: moment.Moment;

  protected readonly CHART_ID = 'headcount-comparison';

  chart: Highcharts.Chart;
  chartId: string;
  subscriptions: Subscription = new Subscription();

  selectedHeadcountChartValueType: 'percentage' | 'number' = 'percentage';
  selectedHeadcountChartType: 'column' | 'histogram' = 'histogram';

  constructor(reportService: PeopleReportService, quickBuildService: QuickBuildService) {
    super(reportService, quickBuildService);
    this.chartId = UniqueIdGenerator.generate();
    if (!this.dateFrom || !this.dateTo) {
      this.dateFrom = moment().subtract(2, 'year');
      this.dateTo = moment();
    }
  }

  protected initializeChart(): void {
    this.chart = Highcharts.chart(`highchartsContainer-${this.chartId}`, reportChartLineOptions);
    this.chart.update(demographicsChartNoSeries, true, true);
  }

  protected shouldRefreshOnChanges(changes: SimpleChanges): boolean {
    Log.d('headcount-comparison shouldRefreshOnChanges', changes);
    const currentQBs = changes['quickBuilds']?.currentValue?.filter((qb: QuickBuild) => qb);
    const previousQBs = changes['quickBuilds']?.previousValue?.filter((qb: QuickBuild) => qb);

    if (!currentQBs || currentQBs.length === 0) {
      this.resetChartData();
      return false;
    }

    if (!previousQBs || previousQBs.length === 0) {
      // Add a new series to the chart
      const newQuickBuilds = changes['quickBuilds'].currentValue;
      newQuickBuilds.forEach((qb: QuickBuild) => {
        if (!qb) return;
        this.addQuickbuildToChart(qb);
      });
      return false;
    }

    // Remove series no longer present
    let shouldRefresh = false;
    const noLongerPresent = previousQBs?.filter(
      (qb: QuickBuild) => !currentQBs.map((q: QuickBuild) => q.id).includes(qb.id)
    );
    noLongerPresent?.forEach((qb: QuickBuild) => {
      this.removeQuickbuildFromChart(qb.id, qb.name);
      shouldRefresh = true;
    });

    // Add new series
    const newQuickBuilds = currentQBs.filter(
      (qb: QuickBuild) => qb && !previousQBs.map((q: QuickBuild) => q.id).includes(qb.id)
    );
    newQuickBuilds.forEach((qb: QuickBuild) => {
      this.addQuickbuildToChart(qb);
      shouldRefresh = true;
    });

    return shouldRefresh;
  }

  protected getChartData(): void {
    Log.d('headcount-comparison getChartData', this.quickBuilds);
    if (!this.quickBuilds) return;
    this.quickBuilds.forEach((quickBuild) => {
      if (!quickBuild) return;
      this.addQuickbuildToChart(quickBuild);
    });
  }

  addQuickbuildToChart(quickBuild: QuickBuild) {
    Log.d('headcount-comparison addQuickbuildToChart', quickBuild);
    this.removeQuickbuildFromChart(quickBuild.id, quickBuild.name);
    const reportParams = {
      date_from: this.dateFrom.toISOString(),
      date_to: this.dateTo.toISOString(),
    };
    const sub = this.reportService
      .getReportDataWithReportDetails(
        this.reportId,
        REPORT_NAME,
        reportParams,
        this.reportTarget,
        quickBuild
      )
      .subscribe((data) => {
        this.generateChartSeries(quickBuild.id, quickBuild.name, data.data.demographics);
      });
    this.subscriptions.add(sub);
  }

  onBreakdownByChanged(value: MatButtonToggleChange) {
    if (!value) return;

    Log.d('onBreakdownByChanged', value);
    this.resetChartData();
    this.getChartData();
  }

  resetChartData() {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    this.removeAllSeriesFromChart();
    switch (this.selectedHeadcountChartValueType) {
      case 'percentage':
        this.chart?.update(
          {
            yAxis: {
              title: { text: 'Pct Change' },
            },
          },
          true,
          true
        );
        break;
      case 'number':
        this.chart?.update(
          {
            yAxis: {
              title: { text: 'Number of Employees' },
            },
          },
          true,
          true
        );
        break;
    }
  }

  removeAllSeriesFromChart() {
    if (!this.chart) return;

    while (this.chart.series.length > 0) {
      this.chart.series[0].remove(false);
    }
  }

  generateChartSeries(quickBuildId: string, quickBuildName: string, inputData: any): void {
    let data = inputData.map((d: any) => [new Date(d.date).valueOf(), d.count_employees]);

    if (this.selectedHeadcountChartValueType === 'percentage') {
      const startValue = data[0][1];
      data.forEach((d: any) => {
        d[1] = ((d[1] - startValue) / startValue) * 100;
      });
    }

    if (this.selectedHeadcountChartType === 'column') {
      const lastPoint = data[data.length - 1] || [];

      // We only have 1 series - get or init it
      const seriesName = 'Growth';
      let seriesToChange = this.chart.series.find((s) => s.name === seriesName);
      if (!seriesToChange) {
        seriesToChange = this.chart.addSeries({
          name: seriesName,
          type: 'column',
          data: [],
          custom: { id: seriesName, showGroupIcon: false },
          colorIndex: 0,
          showInLegend: false,
        });
        // this.chart.update({
        //   xAxis: columnChartXAaxis,
        // });
      }

      // If they want a number, we have to subtract the first point from the last to get the diff
      let chartPoint = lastPoint[1] || 0;
      if (this.selectedHeadcountChartValueType === 'number') {
        const firstPoint = data[0] || [];
        chartPoint = chartPoint - (firstPoint[1] || 0);
      }
      seriesToChange.addPoint({
        y: chartPoint,
        color: chartPoint >= 0 ? '#8e91ff' : '#f97316',
        name: quickBuildName,
        custom: {
          id: quickBuildId,
        },
      });

      return;
    }

    // this.chart.update({
    //   xAxis: timeseriesChartXaxis,
    // });

    setTimeout(() => {
      this.chart.addSeries({
        custom: { id: quickBuildName + quickBuildId },
        id: quickBuildName + quickBuildId,
        name: quickBuildName,
        data: data,
        type: 'spline',
      });
    }, 300);
  }

  removeQuickbuildFromChart(quickBuildId: string, quickBuildName: string) {
    if (!this.chart) return;
    this.chart.series.forEach((series) => {
      if (series.options.custom?.id === quickBuildName + quickBuildId) {
        series.remove();
      }
    });
    // For column charts, we need to remove the data point, not the series
    if (this.selectedHeadcountChartType === 'column' && this.chart.series.length > 0) {
      const dataIndex = this.chart.series[0].data.findIndex(
        (d) => (d as any).custom.id === quickBuildName + quickBuildId
      );
      if (dataIndex >= 0) {
        this.chart.series[0].data[dataIndex].remove();
      }
    }
  }

  toggleHeadcountValueType(value: MatButtonToggleChange) {
    if (!value) return;

    Log.d('toggleHeadcountValueType', value);
    this.selectedHeadcountChartValueType = value.value;
    this.resetChartData();
    this.getChartData();
  }

  toggleHeadcountChartType(value: MatButtonToggleChange) {
    if (!value) return;

    Log.d('toggleHeadcountChartType', value);
    this.selectedHeadcountChartType = value.value;
    this.resetChartData();
    this.getChartData();
  }

  getFieldDisplayName(field: string): string {
    return getPeopleColumnByName(field).displayName;
  }
}

const demographicsChartNoSeries: Highcharts.Options = {
  title: {
    text: 'Headcount Growth',
  },
  yAxis: [
    {
      allowDecimals: false,
      tickAmount: 10,
      title: {
        text: 'Pct Change',
      },
      stackLabels: {
        enabled: false,
      },
    },
  ],
  plotOptions: {
    column: {
      stacking: 'normal',
      borderWidth: 0,
      borderColor: 'transparent',
    },
  },
  legend: {
    itemStyle: {
      fontSize: '10px',
    },
    verticalAlign: 'bottom',
  },
  lang: {
    loading: 'Loading chart data...',
  },
};
