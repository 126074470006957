import { ColDef, ColGroupDef, ITextFilterParams } from 'ag-grid-community';
import { Job } from 'ldt-people-api';
import * as dayjs from 'dayjs';

const betterDateFilterParams: ITextFilterParams = {
  filterOptions: [
    'equals',
    {
      displayKey: 'greaterThanOrEqualsCustom',
      displayName: 'Greater than or equals',
      predicate: ([filterValue], cellValue) => true,
    },
    {
      displayKey: 'lessThanOrEqualsCustom',
      displayName: 'Less than or equals',
      predicate: ([filterValue], cellValue) => true,
    },
    'notEqual',
    'inRange',
    'blank',
    'notBlank',
  ],
  defaultOption: 'greaterThanOrEqualsCustom',
  maxNumConditions: 1,
};

const numberFilterParams: ITextFilterParams = {
  filterOptions: [
    'equals',
    'notEqual',
    'lessThan',
    'lessThanOrEqual',
    'greaterThan',
    'greaterThanOrEqual',
    'inRange',
    'blank',
    'notBlank',
  ],
  defaultOption: 'greaterThanOrEqual',
  maxNumConditions: 1,
};

export const peoplePreviewGridSettings: (ColDef | ColGroupDef)[] = [
  {
    field: 'id',
    headerName: 'ID',
    headerTooltip: 'Unique ID of this contact in the Live Data system',
  },
  {
    headerName: 'Dates (UTC)',
    children: [
      {
        field: 'company_change_detected_at',
        headerName: 'Job Change',
        headerTooltip: 'The most recent date a company change was detected',
        filter: 'agDateColumnFilter',
        valueFormatter: (params: any) => {
          return params.value ? dayjs(params.value).utc().format('YYYY-MM-DD h:mm A') : '';
        },
        filterParams: betterDateFilterParams,
      },
      {
        field: 'title_change_detected_at',
        headerName: 'Title Change',
        headerTooltip: 'The most recent date a title change was detected',
        filter: 'agDateColumnFilter',
        valueFormatter: (params: any) => {
          return params.value ? dayjs(params.value).utc().format('YYYY-MM-DD h:mm A') : '';
        },
        filterParams: betterDateFilterParams,
      },
      {
        field: 'info_change_detected_at',
        headerName: 'Other Change',
        headerTooltip: 'The most recent date any field was updated',
        filter: 'agDateColumnFilter',
        valueFormatter: (params: any) => {
          return params.value ? dayjs(params.value).utc().format('YYYY-MM-DD h:mm A') : '';
        },
        filterParams: betterDateFilterParams,
      },
      {
        field: 'created_at',
        headerName: 'Created',
        filter: 'agDateColumnFilter',
        valueFormatter: (params: any) => {
          return params.value ? dayjs(params.value).utc().format('YYYY-MM-DD h:mm A') : '';
        },
        filterParams: betterDateFilterParams,
      },
    ],
  },
  {
    headerName: 'Person Info',
    children: [
      {
        field: 'name',
        valueGetter: (params: any) => '<redacted>',
        cellClass: 'tw-text-gray-500 dark:tw-text-gray-400',
      },
      {
        field: 'connections',
        filter: 'agNumberColumnFilter',
        filterParams: numberFilterParams,
        headerTooltip: 'The number of LinkedInconnections this person has',
      },
      {
        field: 'linkedin',
        valueGetter: (params: any) => '<redacted>',
        cellClass: 'tw-text-gray-500 dark:tw-text-gray-400',
        headerTooltip: 'The LinkedIn ID of this person (redacted)',
      },
    ],
  },
  {
    headerName: 'Current Job',
    children: [
      { field: 'position.company.name', headerName: 'Company Name' },
      {
        field: 'position.title',
        headerName: 'Title',
        headerTooltip: 'The verified title this person currently holds',
      },
      {
        field: 'position.level',
        headerName: 'Job Level',
        filterParams: { values: Object.values(Job.LevelEnum) },
        filter: 'agSetColumnFilter',
        headerTooltip: 'The inferred level of the current job, based on the title',
      },
      {
        field: 'position.function',
        headerName: 'Job Function',
        filterParams: { values: Object.values(Job.FunctionEnum) },
        filter: 'agSetColumnFilter',
        headerTooltip: 'The inferred domain of the current job, based on the title',
      },
      {
        field: 'position.started_at',
        headerName: 'Start Date',
        headerTooltip: 'The date the person started at their current job (month granularity)',
        filter: 'agDateColumnFilter',
        valueFormatter: (params: any) => {
          let tString = 'YYYY-MM';
          if (params?.data?.position?.metadata?.started_at_year_only) tString = 'YYYY';
          return params?.data?.position?.started_at
            ? dayjs(params?.data?.position?.started_at).utc().format(tString)
            : '';
        },
        filterParams: betterDateFilterParams,
      },
      { field: 'position.location', headerName: 'Job Location' },
    ],
  },
  {
    headerName: 'Company Firmo',
    children: [
      {
        field: 'position.company.employee_count',
        headerName: 'Employees',
        filterParams: numberFilterParams,
        filter: 'agNumberColumnFilter',
      },
      {
        field: 'position.company.linkedin',
        headerName: 'LinkedIn',
        cellRenderer: (params: { value: any }) => {
          if (!params.value) {
            return '';
          } else {
            return (
              '<a href="https://www.linkedin.com/company/' +
              params.value +
              '" target=_blank>' +
              params.value +
              '</a>'
            );
          }
        },
      },
      { field: 'position.company.domain', headerName: 'Domain' },
    ],
  },
  {
    headerName: 'Previous Job',
    headerTooltip:
      'The previous job this person held. Searching these fields searches ALL previous jobs.',
    children: [
      {
        valueGetter: (params: any) => params?.data?.jobs[1]?.company.name,
        headerName: 'Company Name',
        field: 'jobs.company.name',
        sortable: false,
      },
      {
        valueGetter: (params: any) => params?.data?.jobs[1]?.title,
        headerName: 'Title',
        field: 'jobs.title',
        sortable: false,
        headerTooltip: 'The verified title of this person at this job',
      },
      {
        valueGetter: (params: any) => params?.data?.jobs[1]?.level,
        headerName: 'Job Level',
        field: 'jobs.level',
        sortable: false,
        filterParams: { values: Object.values(Job.LevelEnum) },
        filter: 'agSetColumnFilter',
        headerTooltip: 'The inferred level of the job, based on the title',
      },
      {
        valueGetter: (params: any) => params?.data?.jobs[1]?.function,
        headerName: 'Job Function',
        field: 'jobs.function',
        sortable: false,
        filterParams: { values: Object.values(Job.FunctionEnum) },
        filter: 'agSetColumnFilter',
        headerTooltip: 'The inferred domain of the job, based on the title',
      },
      {
        valueGetter: (params: any) => params?.data?.jobs[1]?.started_at,
        headerName: 'Start Date',
        filter: 'agDateColumnFilter',
        field: 'jobs.started_at',
        sortable: false,
        headerTooltip: 'The date the person started at this job (month granularity)',
        valueFormatter: (params: any) => {
          let tString = 'YYYY-MM';
          if (params?.data?.jobs[1]?.metadata?.started_at_year_only) tString = 'YYYY';
          return params?.data?.jobs[1]?.started_at
            ? dayjs(params?.data?.jobs[1]?.started_at).utc().format(tString)
            : '';
        },
        filterParams: betterDateFilterParams,
      },
      {
        valueGetter: (params: any) => params?.data?.jobs[1]?.ended_at,
        headerName: 'End Date',
        filter: 'agDateColumnFilter',
        field: 'jobs.ended_at',
        sortable: false,
        headerTooltip: 'The date the person ended at this job (month granularity)',
        valueFormatter: (params: any) => {
          let tString = 'YYYY-MM';
          if (params?.data?.jobs[1]?.metadata?.ended_at_year_only) tString = 'YYYY';
          return params?.data?.jobs[1]?.ended_at
            ? dayjs(params?.data?.jobs[1]?.ended_at).utc().format(tString)
            : '';
        },
        filterParams: betterDateFilterParams,
      },
      {
        valueGetter: (params: any) => params?.data?.jobs[1]?.location,
        headerName: 'Job Location',
        field: 'jobs.company.location',
        sortable: false,
      },
      {
        valueGetter: (params: any) => params?.data?.jobs[1]?.company.linkedin,
        headerName: 'Company LinkedIn',
        field: 'jobs.company.linkedin',
        sortable: false,
        cellRenderer: (params: { value: any }) => {
          if (!params.value) {
            return '';
          } else {
            return (
              '<a href="https://www.linkedin.com/company/' +
              params.value +
              '" target=_blank>' +
              params.value +
              '</a>'
            );
          }
        },
      },
    ],
  },
  {
    headerName: 'Previous Job - 2',
    headerTooltip: 'The second previous job this person held',
    children: [
      {
        valueGetter: (params: any) => params?.data?.jobs[2]?.company.name,
        headerName: 'Company Name',
        filter: false,
        sortable: false,
      },
      {
        valueGetter: (params: any) => params?.data?.jobs[2]?.title,
        headerName: 'Title',
        filter: false,
        sortable: false,
        headerTooltip: 'The verified title of this person at this job',
      },
      {
        valueGetter: (params: any) => params?.data?.jobs[2]?.level,
        headerName: 'Job Level',
        filter: false,
        sortable: false,
        headerTooltip: 'The inferred level of the job, based on the title.',
      },
      {
        valueGetter: (params: any) => params?.data?.jobs[2]?.function,
        headerName: 'Job Function',
        filter: false,
        sortable: false,
        headerTooltip: 'The inferred domain of the job, based on the title.',
      },
      {
        valueGetter: (params: any) => params?.data?.jobs[2]?.started_at,
        headerName: 'Start Date',
        filter: false,
        sortable: false,
        headerTooltip: 'The date the person started at this job (month granularity)',
        valueFormatter: (params: any) => {
          let tString = 'YYYY-MM';
          if (params?.data?.jobs[2]?.metadata?.started_at_year_only) tString = 'YYYY';
          return dayjs(params?.data?.jobs[2]?.started_at).utc().format(tString) || '';
        },
      },
      {
        valueGetter: (params: any) => params?.data?.jobs[2]?.ended_at,
        headerName: 'End Date',
        filter: false,
        sortable: false,
        headerTooltip: 'The date the person ended at this job (month granularity)',
        valueFormatter: (params: any) => {
          let tString = 'YYYY-MM';
          if (params?.data?.jobs[2]?.metadata?.ended_at_year_only) tString = 'YYYY';
          return dayjs(params?.data?.jobs[2]?.ended_at).utc().format(tString) || '';
        },
        filterParams: betterDateFilterParams,
      },
      {
        valueGetter: (params: any) => params?.data?.jobs[2]?.location,
        headerName: 'Job Location',
        filter: false,
        sortable: false,
      },
      {
        valueGetter: (params: any) => params?.data?.jobs[2]?.company.linkedin,
        headerName: 'Company LinkedIn',
        filter: false,
        sortable: false,
        cellRenderer: (params: { value: any }) => {
          if (!params.value) {
            return '';
          } else {
            return (
              '<a href="https://www.linkedin.com/company/' +
              params.value +
              '" target=_blank>' +
              params.value +
              '</a>'
            );
          }
        },
      },
    ],
  },
];
