<div
  class="tw-flex tw-flex-col tw-col-span-2 xl:tw-col-span-1 tw-h-full tw-max-h-[500px] tw-justify-end">
  <div class="charts-actions tw-flex tw-flex-row tw-justify-end tw-mt-4 xl:tw-mt-0 tw-mb-2"></div>
  <div class="tw-h-[400px] mat-card-shadow tw-rounded">
    <div
      id="highchartsContainer-{{ chartId }}"
      style="width: 100%; height: 100%"
      class="tw-rounded"></div>
  </div>
</div>
