import { Component, Input, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';
import { CreditUsage, CreditUsageService } from './credit-usage-service.service';
import { AuthService } from 'src/app/auth/service/auth.service';

@Component({
  selector: 'app-credit-usage-indicator',
  templateUrl: './credit-usage-indicator.component.html',
  styleUrls: ['./credit-usage-indicator.component.scss'],
})
export class CreditUsageIndicatorComponent {
  @Input() hideText: boolean = false;
  @Input() hideProgressBar: boolean = false;
  @Input() transparentBackground: boolean = false;

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  orgUsagePolling: Subscription;
  creditUsage: CreditUsage | null = null;

  constructor(
    private router: Router,
    private sseService: CreditUsageService,
    private authService: AuthService
  ) {
    this.orgUsagePolling = this.sseService.creditUsage$.subscribe((creditUsage) => {
      this.creditUsage = creditUsage;
    });
  }

  navigateToSettings() {
    const orgId = this.authService.getSelectedOrgIdValue;
    this.router.navigate([`/${orgId}/settings`]);
  }

  // by default, popover (mat-menu) closes on 'Enter' key, but let's add additional support for escape key so user isn't stuck in the menu
  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.menuTrigger.menuOpen) {
      this.closeMenu();
    }
  }

  closeMenu() {
    if (this.menuTrigger && this.menuTrigger.menuOpen) {
      this.menuTrigger.closeMenu();
    }
  }

  ngOnDestroy() {
    this.orgUsagePolling.unsubscribe();
  }
}
