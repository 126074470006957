import { Component, Input, SimpleChanges } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import * as Highcharts from 'highcharts';
import { arrDepChartNoSeries } from '../highcharts-options';
import { QuickBuildService } from 'src/app/people/quick-build.service';
import { PeopleReportService } from 'src/app/people/report-service.service';
import { departments, levels, ReportVizChartColors } from '../constants';
import * as moment from 'moment';
import { getPeopleColumnByName } from 'src/app/people/people-columns';
import { BaseReportComponent } from '../base-report.component';
import { Log } from 'src/app/logger.service';
import { ChatDialogComponent } from '../../chat-dialog/chat-dialog.component';
import { ChatRequest, ChatService } from 'ldt-chat-api';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'src/app/auth/service/auth.service';
import { NotificationService } from '../../notification-service/notification.service';
import { MarkdownService } from 'ngx-markdown';

const FIELDS_TO_GROUP_BY = ['jobs.function', 'jobs.level'];
const REPORT_NAME = 'arrivals_departures';
@Component({
  selector: 'app-reports-job-starts-and-ends',
  templateUrl: './job-starts-and-ends.component.html',
  styleUrls: ['./job-starts-and-ends.component.scss'],
})
export class JobStartsAndEndsComponent extends BaseReportComponent {
  @Input() dateFrom: moment.Moment;
  @Input() dateTo: moment.Moment;
  @Input() field: string;
  @Input() maxSeries: number = 25;
  @Input() allowFieldSelection = true;
  @Input() showAIAnalysis = false;
  breakdownByOptions = FIELDS_TO_GROUP_BY;
  selectedBreakdownBy: (typeof FIELDS_TO_GROUP_BY)[number] = FIELDS_TO_GROUP_BY[0];

  protected readonly CHART_ID = 'arrivals_departures';

  constructor(
    reportService: PeopleReportService,
    quickBuildService: QuickBuildService,
    private dialog: MatDialog,
    private chatService: ChatService,
    private auth: AuthService,
    private notify: NotificationService,
    private markdownService: MarkdownService
  ) {
    Log.d('JobStartsAndEndsComponent constructor');
    super(reportService, quickBuildService);
    if (!this.dateFrom || !this.dateTo) {
      this.dateFrom = moment().subtract(2, 'year');
      this.dateTo = moment();
    }
  }

  protected initializeChart(): void {
    this.chart = Highcharts.chart(`highchartsContainer-${this.chartId}`, arrDepChartNoSeries);
    this.chart.update(jobStartsAndEndsChartNoSeries, true, true);
    this.chart.showLoading();
  }

  protected shouldRefreshOnChanges(changes: SimpleChanges): boolean {
    if ('field' in changes) {
      this.selectedBreakdownBy = this.field;
      return true;
    }
    return super.shouldRefreshOnChanges(changes);
  }

  arrDepRawResponse: any;
  protected getChartData(): void {
    Log.d('getChartData', this.quickBuild);
    if (!this.quickBuild) return;

    const reportParams = {
      date_from: this.dateFrom.toISOString(),
      date_to: this.dateTo.toISOString(),
      group_by: [this.selectedBreakdownBy],
    };
    const sub = this.reportService
      .getReportDataWithReportDetails(
        this.reportId,
        REPORT_NAME,
        reportParams,
        this.reportTarget,
        this.quickBuild
      )
      .subscribe((data) => {
        Log.d('JobStartsAndEndsComponent data', data);
        this.arrDepRawResponse = data.data.arrivals_departures;
        const series = this.generateChartSeries(
          data.data.arrivals_departures,
          this.selectedBreakdownBy
        );
        let titleText = undefined;
        if (this.selectedBreakdownBy) {
          titleText =
            'Job Starts and Ends by ' + this.getFieldDisplayName(this.selectedBreakdownBy);
        }
        this.updateChart(series, undefined, titleText);
      });
    this.subscriptions.add(sub);
  }

  protected generateChartSeries(inputData: any, byType: string): any[] {
    const totalKeyCounts: Record<string, number> = {};
    let processed = inputData.reduce((acc: any, d: any) => {
      const keyVal = d['group_values'][0]?.value || 'Total';
      if (!acc[keyVal]) {
        acc[keyVal] = {};
        acc[keyVal + 'DEP'] = {};
      }

      if (!totalKeyCounts[keyVal]) {
        totalKeyCounts[keyVal] = 0;
      }
      totalKeyCounts[keyVal] += d.count_arrivals + d.count_departures;

      const dateVal = d.date;

      acc[keyVal][dateVal] = d.count_arrivals;
      acc[keyVal + 'DEP'][dateVal] = -1 * d.count_departures;

      return acc;
    }, {});

    const indexer =
      byType === 'jobs.function'
        ? (val: string) => departments.indexOf(val)
        : (val: string) => levels.indexOf(val);

    const topKeys = Object.entries(totalKeyCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, this.maxSeries)
      .map(([key, _]) => key);

    return Object.entries(processed)
      .filter(([key, _]) => topKeys.includes(this.rootKeyName(key)))
      .map(([key, value]) => {
        const keyName = this.rootKeyName(key);

        return {
          id: key.endsWith('DEP') ? undefined : key,
          linkedTo: key.endsWith('DEP') ? ':previous' : undefined,
          name: keyName,
          data: Object.entries(value as Record<string, number>).map(([date, count]) => [
            new Date(date!).valueOf(),
            count,
          ]),
          color: ReportVizChartColors[indexer(keyName)],
          type: 'column',
        };
      });
  }

  analyzeArrivalsDepartures() {
    this.analyzeChart('default');
  }

  chartAnalyzing = false;
  private analyzeChart(analysisType: 'default' | 'ticker_correlation' = 'default') {
    const dialogRef = this.dialog.open(ChatDialogComponent, {
      data: {
        content: '',
        isLoading: true,
      },
      maxHeight: '100vh',
      panelClass: 'full-w-mobile-dialog',
    });
    this.chartAnalyzing = true;

    let request: ChatRequest | any = {
      model: 'gpt-4o-mini',
      type: 'chart_analysis',
      company_name: this.quickBuild.name,
      arrdep_data: this.jsonToCsv(this.arrDepRawResponse),
      emp_count_data: [], //this.jsonToCsv(this.empCountByDeptRawResponse),
      analysis_type: analysisType,
    };

    this.chatService.chat(this.auth.getSelectedOrgIdValue, request).subscribe({
      next: (res: any) => {
        Log.d('job-starts-and-ends-component chat res', res);
        dialogRef.componentInstance.data = {
          content: {
            response: res.response,
          },
          isLoading: false,
        };
        this.chartAnalyzing = false;
      },
      error: () => {
        this.notify.error('Error analyzing chart');
        dialogRef.close();
        this.chartAnalyzing = false;
      },
    });
  }

  private jsonToCsv(jsonArray: any[]): string {
    if (!jsonArray || jsonArray.length === 0) {
      return '';
    }

    // Get all keys from the first object
    const headers = Object.keys(jsonArray[0]);

    // Build the CSV header row
    const csvRows = [headers.join(',')];

    // Build each data row
    for (const obj of jsonArray) {
      const values = headers.map((header) => {
        // Handle CSV escaping for commas, quotes, etc.
        const val = String(obj[header] ?? '');
        // Escape double quotes
        return `"${val.replace(/"/g, '""')}"`;
      });
      csvRows.push(values.join(','));
    }

    // Join all rows by newline
    return csvRows.join('\n');
  }

  private rootKeyName(key: string): string {
    return key.endsWith('DEP') ? key.replace('DEP', '') : key;
  }

  onBreakdownByChanged(value: MatButtonToggleChange) {
    if (!value) return;

    this.selectedBreakdownBy = value.value;
    this.resetChartData();
    this.getChartData();
  }

  getFieldDisplayName(field: string): string {
    return getPeopleColumnByName(field).displayName;
  }
}

const jobStartsAndEndsChartNoSeries: Highcharts.Options = {
  title: {
    text: 'Job Starts and Ends',
  },
  yAxis: [
    {
      allowDecimals: false,
      tickAmount: 10,
      title: {
        text: 'Ends | Starts',
      },
      stackLabels: {
        enabled: false,
      },
      plotLines: [
        {
          value: 0,
          color: '#3F83F8',
          width: 3,
          zIndex: 2,
        },
      ],
    },
  ],
  plotOptions: {
    column: {
      stacking: 'normal',
      borderWidth: 0,
      borderColor: 'transparent',
    },
  },
  legend: {
    itemStyle: {
      fontSize: '10px',
    },
    verticalAlign: 'bottom',
  },
  lang: {
    loading: 'Loading chart data...',
  },
};
