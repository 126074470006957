import { Injectable } from '@angular/core';
import {
  DefaultService,
  ReplayCompaniesRequest,
  Company as DWCompany,
} from 'ldt-dw-reader-service-api';
import { CompaniesService, Company, FindCompanyRequest } from 'ldt-moneyball-api';
import { ProvidersService } from 'ldt-provider-intake-api';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ChangeLog } from 'src/app/data-warehouse/change-logger.service';
import { ChangeLoggerService } from 'src/app/data-warehouse/change-logger.service';
const VALID_FIELDS_TO_NULL = ['group_id', 'ticker', 'domain'];
const VALID_FIELDS_TO_UPDATE = [
  'name',
  'ticker',
  'employee_count',
  'country',
  'industry',
  'grouped_company_count',
  'group_id',
  'domain',
];
@Injectable({
  providedIn: 'root',
})
export class CompanyInfoService {
  private maxCacheSize = 500; // Maximum number of cached items allowed
  private companyCache: Map<string, Company> = new Map();

  constructor(
    private companiesService: CompaniesService,
    private authService: AuthService,
    protected replayService: DefaultService,
    protected providerService: ProvidersService,
    protected changeLogger: ChangeLoggerService
  ) {}

  // Add the company to the cache
  private addToCache(key: string, response: any): void {
    if (this.companyCache.size >= this.maxCacheSize) {
      const firstKey = this.companyCache.keys().next().value;
      if (firstKey) {
        this.companyCache.delete(firstKey);
      }
    }
    this.companyCache.set(key, response);
  }

  public findMostLikelyCompanyByDomain(domain: string): Observable<Company | undefined> {
    const req: FindCompanyRequest = {
      search_value: domain,
      search_field: 'domain',
    };
    return this.companiesService.findCompany(this.authService.getSelectedOrgIdValue, req).pipe(
      map((response) => {
        if (
          response &&
          response.companies &&
          response.companies instanceof Array &&
          response.companies.length > 0
        ) {
          const company = response.companies[0];
          this.addToCache(company.id, company);
          return company;
        } else {
          return undefined;
        }
      })
    );
  }

  public findMostLikelyCompanyByName(name: string): Observable<Company | undefined> {
    const req: FindCompanyRequest = {
      search_value: name,
      search_field: 'name',
    };
    return this.companiesService.findCompany(this.authService.getSelectedOrgIdValue, req).pipe(
      map((response) => {
        if (
          response &&
          response.companies &&
          response.companies instanceof Array &&
          response.companies.length > 0
        ) {
          const company = response.companies[0];
          this.addToCache(company.id, company);
          return company;
        } else {
          return undefined;
        }
      })
    );
  }

  public getCompanyInfo(companyId: string): Observable<Company | undefined> {
    if (this.companyCache.has(companyId)) {
      return of(this.companyCache.get(companyId));
    }

    const req: FindCompanyRequest = {
      search_value: companyId,
    };
    return this.companiesService.findCompany(this.authService.getSelectedOrgIdValue, req).pipe(
      catchError(() => {
        return of(undefined);
      }),
      map((response) => {
        if (
          response &&
          response.companies &&
          response.companies instanceof Array &&
          response.companies.length > 0
        ) {
          this.addToCache(companyId, response.companies[0]);
          return response.companies[0];
        } else {
          return undefined;
        }
      })
    );
  }

  public updateCompanyField(
    company: DWCompany,
    field: keyof DWCompany,
    value: string | number | null
  ): Observable<void> {
    if (!VALID_FIELDS_TO_UPDATE.includes(field)) {
      throw new Error(`Field ${field} is not valid for updating`);
    }

    if (!VALID_FIELDS_TO_NULL.includes(field) && value === null) {
      throw new Error(`Field ${field} cannot be set to null`);
    }

    if (value === null) {
      const req: ReplayCompaniesRequest = {
        lookups: [company.linkedin!],
        context: 'CU-868ce6jrj',
        context_data: {
          field: field,
        },
      };
      return this.replayService.replayCompanies(req).pipe(
        tap(() => {
          this.addToCache(company.id, { ...company, [field]: value });
          this.logChange(company, field, value);
        })
      );
    }

    const req = {
      data: [
        {
          id: company.id,
          linkedin: company.linkedin,
          [field]: value,
        },
      ],
    };
    return this.providerService.postProviderData('ldtinternal', 'companies', req).pipe(
      tap(() => {
        this.addToCache(company.id, { ...company, [field]: value });
        this.logChange(company, field, value);
      })
    );
  }

  private logChange(company: DWCompany, field: keyof DWCompany, value: string | number | null) {
    const log: ChangeLog = {
      resource_type: 'company',
      resource_id: company.id!,
      action: 'update',
      fields: [field],
      oldValues: { [field]: company[field] },
      newValues: { [field]: value },
      timestamp: new Date(),
      userId: this.authService.getUserValue.id,
      userEmail: this.authService.getUserValue.email,
    };
    this.changeLogger.logChange(log);
  }
}
