// This file contains GLOBAL styles for Highcharts

// Dynamic exporting configuration
// Perfect place to put all export-related global styles and options
const getDynamicExportingOptions = () => ({
  chartOptions: {
    chart: {
      backgroundColor: '#1c1c1c', // --dark-white-dynamic-bg
    },
    title: {
      style: {
        color: '#ffffff', // --dark-white-dynamic-text
      },
    },
    caption: {
      style: {
        color: '#ffffff', // --dark-white-dynamic-text
      },
    },
    xAxis: {
      labels: {
        style: {
          color: '#ffffff', // --dark-white-dynamic-text
        },
      },
      title: {
        style: {
          color: '#ffffff', // --dark-white-dynamic-text
        },
      },
    },
    // some charts have multiple yAxes (on left and right)
    // it shouldn't affect export chart that only have one
    yAxis: [
      {
        labels: {
          style: {
            color: '#ffffff', // --dark-white-dynamic-text (white)
          },
        },
        title: {
          style: {
            color: '#ffffff', // --dark-white-dynamic-text (white)
          },
        },
        gridLineColor: '#737373', // --dynamic-chart-grid-lines (#737373)
      },
      {
        opposite: true,
        labels: {
          style: {
            color: '#ffffff', // --dark-white-dynamic-text (white)
          },
        },
        title: {
          style: {
            color: '#ffffff', // --dark-white-dynamic-text (white)
          },
        },
      },
    ],
    legend: {
      itemStyle: {
        color: '#ffffff', // --dark-white-dynamic-text
      },
      itemHoverStyle: {
        color: '#d4d4d4', // --dynamic-chart-legend-hover
      },
    },
    tooltip: {
      backgroundColor: '#1c1c1c', // --dark-white-dynamic-bg
      style: {
        color: '#e5e5e5', // --dark-light-dynamic-text
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          style: {
            color: '#ffffff', // --dark-white-dynamic-text
          },
        },
      },
    },
  },
});

// Light theme
// Mostly global colors but can container Highcharts options as well (e.g., fonts, etc.)
export const lightTheme = {
  chart: {
    backgroundColor: 'var(--dark-white-dynamic-bg)',
    style: {
      fontFamily: 'SF UI Text, sans-serif',
    },
  },
  caption: {
    style: {
      fontFamily: 'SF UI Text, sans-serif',
      color: 'var(--black-white-dynamic-text)',
    },
  },
  title: {
    style: {
      color: 'var(--dark-white-dynamic-text)',
      fontFamily: 'SF UI Text, sans-serif',
    },
  },
  xAxis: {
    lineColor: 'var(--dark-white-dynamic-text)',
    labels: {
      style: {
        color: 'var(--dark-white-dynamic-text)',
      },
    },
  },
  yAxis: {
    gridLineColor: 'var(--dynamic-chart-grid-lines)',
    labels: {
      style: {
        color: 'var(--dark-white-dynamic-text)',
      },
    },
    title: {
      style: {
        color: 'var(--dark-white-dynamic-text)',
      },
    },
  },
  legend: {
    itemStyle: {
      color: 'var(--dark-white-dynamic-text)',
      fontFamily: 'SF UI Text, sans-serif',
    },
    itemHoverStyle: {
      color: 'var(--dynamic-chart-legend-hover)',
    },
  },
  exporting: {
    // export buttons
    buttons: {
      contextButton: {
        symbolStroke: 'var(--dark-light-dynamic-text)', // Icon color
        symbolFill: 'var(--dark-white-dynamic-bg)', // Background for the symbol
        theme: {
          fill: 'var(--dark-white-dynamic-bg)', // Button background
          style: {
            color: 'var(--dark-light-dynamic-text)', // Text color
          },
          states: {
            hover: {
              fill: 'var(--dynamic-hover-bg)', // Hover background color
              style: {
                color: 'var(--dark-white-dynamic-text)', // Hover text color
              },
            },
          },
        },
      },
    },
    ...getDynamicExportingOptions(), // Include dynamic exporting options
  },
  navigation: {
    breadcrumbs: {
      buttonTheme: {
        style: {
          color: 'var(--dynamic-link-color)',
        },
      },
      separator: {
        style: {
          color: 'var(--dark-white-dynamic-text)',
        },
      },
      position: {
        y: -35,
      },
    },
  },
};

// Dark theme
// Mostly global colors but can container Highcharts options as well (e.g., fonts, etc.)
export const darkTheme = {
  chart: {
    backgroundColor: 'var(--dark-white-dynamic-bg)',
    style: {
      fontFamily: 'SF UI Text, sans-serif',
    },
  },
  caption: {
    style: {
      fontFamily: '"Montserrat", sans-serif',
      color: 'var(--black-white-dynamic-text)',
    },
  },
  title: {
    style: {
      color: 'var(--dark-white-dynamic-text)',
      fontFamily: '"Montserrat", sans-serif',
    },
  },
  xAxis: {
    lineColor: 'var(--dark-white-dynamic-text)',
    labels: {
      style: {
        color: 'var(--dark-white-dynamic-text)',
      },
    },
  },
  yAxis: {
    gridLineColor: 'var(--dynamic-chart-grid-lines)',
    labels: {
      style: {
        color: 'var(--dark-white-dynamic-text)',
      },
    },
    title: {
      style: {
        color: 'var(--dark-white-dynamic-text)',
      },
    },
  },
  legend: {
    itemStyle: {
      color: 'var(--dark-white-dynamic-text)',
    },
    itemHoverStyle: {
      color: 'var(--dynamic-chart-legend-hover)',
    },
  },
  exporting: {
    // export buttons
    buttons: {
      contextButton: {
        symbolStroke: 'var(--dark-light-dynamic-text)', // Icon color
        symbolFill: 'var(--dark-white-dynamic-bg)', // Background for the symbol
        theme: {
          fill: 'var(--dark-white-dynamic-bg)', // Button background
          style: {
            color: 'var(--dark-light-dynamic-text)', // Text color
          },
          states: {
            hover: {
              fill: 'var(--dynamic-hover-bg)', // Hover background color
              style: {
                color: 'var(--dark-white-dynamic-text)', // Hover text color
              },
            },
          },
        },
      },
    },
    ...getDynamicExportingOptions(), // Include dynamic exporting options
    // popup menu styles are in styles.scss, not here
  },
  navigation: {
    breadcrumbs: {
      buttonTheme: {
        style: {
          color: 'var(--dynamic-link-color)',
        },
      },
      separator: {
        style: {
          color: 'var(--dark-white-dynamic-text)',
        },
      },
      position: {
        y: -35,
      },
    },
  },
};
