import { Component, Input, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { timer, Subscription, forkJoin } from 'rxjs';
import { reportSankeyOptions } from '../highcharts-options';
import { QuickBuild, UniqueIdGenerator } from 'src/app/people/quick-build.service';
import { PeopleReportService } from 'src/app/people/report-service.service';
import { getPeopleColumnByName } from 'src/app/people/people-columns';
import { ReportVizChartColors } from '../constants';
import { Log } from 'src/app/logger.service';

const CHART_ID = 'demographics';
const MAX_COMPANIES = 10;

@Component({
  selector: 'app-reports-prev-next-flow',
  templateUrl: './prev-next-flow.component.html',
  styleUrls: ['./prev-next-flow.component.scss'],
})
export class PrevNextFlowComponent {
  @Input() quickBuild: QuickBuild;
  @Input() reportTarget: string;
  @Input() field: string;
  @Input() allowFieldSelection = true;
  @Input() dateFrom: moment.Moment;
  @Input() dateTo: moment.Moment;
  @Input() reportId: string;
  chart: Highcharts.Chart;
  chartId: string;
  subscriptions: Subscription = new Subscription();

  constructor(private reportService: PeopleReportService) {
    this.chartId = UniqueIdGenerator.generate();
    if (!this.reportId) {
      this.reportId = UniqueIdGenerator.generate();
    }
  }

  ngAfterViewInit(): void {
    timer(100).subscribe(() => {
      try {
        this.initializeChart();
      } catch (error) {
        timer(100).subscribe(() => {
          this.initializeChart();
        });
      }
    });
  }

  initializeChart() {
    this.chart = Highcharts.chart(`highchartsContainer-${this.chartId}`, reportSankeyOptions);
    this.chart.update(
      { title: { text: 'Active Employees' }, yAxis: { title: { text: '% of employees' } } },
      true,
      true
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.resetChartData();
    this.getChartData();
  }

  getChartData() {
    if (!this.quickBuild) return;

    const reportParams = {
      aggregate_type: 'range',
      date_from: this.dateFrom.toISOString(),
      date_to: this.dateTo.toISOString(),
      group_by: ['jobs.metadata.previous_job.company.name'],
    };
    const reportParams2 = {
      aggregate_type: 'range',
      date_from: this.dateFrom.toISOString(),
      date_to: this.dateTo.toISOString(),
      group_by: ['jobs.metadata.next_job.company.name'],
    };
    forkJoin([
      this.reportService.getReportDataWithReportDetails(
        this.reportId,
        CHART_ID,
        reportParams,
        this.reportTarget,
        this.quickBuild
      ),
      this.reportService.getReportDataWithReportDetails(
        this.reportId,
        CHART_ID,
        reportParams2,
        this.reportTarget,
        this.quickBuild
      ),
    ]).subscribe(([prev_company_data, next_company_data]) => {
      const series = this.generateChartSeries(
        prev_company_data.data.demographics,
        next_company_data.data.demographics
      );
      this.updateChart(series);
    });
  }

  private updateChart(series: any[]) {
    if (!this.chart) {
      // If chart isn't ready yet, wait for next tick when it should be initialized
      timer(0).subscribe(() => {
        if (this.chart) {
          this.chart.update(
            {
              series,
            },
            true,
            true,
            true
          );
        }
      });
      return;
    }
    this.chart.update(
      {
        series,
      },
      true,
      true,
      true
    );
    Log.d('sankey report chart', this.chart.options);
  }

  resetChartData() {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    this.removeAllSeriesFromChart();
  }

  removeAllSeriesFromChart() {
    if (!this.chart) return;

    while (this.chart.series.length > 0) {
      this.chart.series[0].remove(false);
    }
  }

  generateChartSeries(prevData: any, nextData: any): any[] {
    Log.d('PrevNextFlowComponent generateChartSeries', prevData, nextData);
    let data: (string | number)[][] = [];

    data = data.concat(
      prevData
        .filter((c: any) => c.group_values[0].value !== this.quickBuild.name)
        .slice(0, MAX_COMPANIES)
        .map((c: any, i: any) => [
          c.group_values[0].value + ' ',
          ' ' + this.quickBuild.name + ' ',
          c.count_employees,
          // reportChartColors[i],
        ])
    );

    data = data.concat(
      nextData
        .filter((c: any) => c.group_values[0].value !== this.quickBuild.name)
        .slice(0, MAX_COMPANIES)
        .map((c: any, i: any) => [
          ' ' + this.quickBuild.name + ' ',
          c.group_values[0].value,
          c.count_employees,
          // reportChartColors[16 + i],
        ])
    );

    let sData: Highcharts.SeriesSankeyOptions[] = [
      {
        colors: ReportVizChartColors,
        keys: ['from', 'to', 'weight', 'color'],
        type: 'sankey',
        data: data,
        name: 'Employee Flow',
      },
    ];

    return sData;
  }

  getFieldDisplayName(field: string): string {
    return getPeopleColumnByName(field).displayName;
  }
}
