import { Component } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import * as Highcharts from 'highcharts';
import { reportChartBarOptions } from '../highcharts-options';
import { QuickBuildService } from 'src/app/people/quick-build.service';
import { PeopleReportService } from 'src/app/people/report-service.service';
import { getPeopleColumnByName } from 'src/app/people/people-columns';
import { BaseReportComponent } from '../base-report.component';
import { Log } from 'src/app/logger.service';
const FIELDS_TO_GROUP_BY = [
  'jobs.function',
  'jobs.level',
  'jobs.metadata.previous_job.company.name',
  'education.school',
];

@Component({
  selector: 'app-reports-tenure-current-breakdown',
  templateUrl: './tenure-current-breakdown.component.html',
  styleUrls: ['./tenure-current-breakdown.component.scss'],
})
export class TenureCurrentBreakdownComponent extends BaseReportComponent {
  breakdownByOptions = FIELDS_TO_GROUP_BY;
  selectedBreakdownBy: (typeof FIELDS_TO_GROUP_BY)[number] = FIELDS_TO_GROUP_BY[0];

  protected readonly CHART_ID = 'tenure';

  constructor(reportService: PeopleReportService, quickBuildService: QuickBuildService) {
    super(reportService, quickBuildService);
  }

  protected initializeChart(): void {
    this.chart = Highcharts.chart(`highchartsContainer-${this.chartId}`, reportChartBarOptions);
    this.chart.update(
      {
        title: { text: 'Active Employee Tenure' },
        yAxis: { title: { text: 'Average Tenure (months)' } },
      },
      true,
      true
    );
  }

  protected getChartData(): void {
    if (!this.quickBuild) return;

    const reportParams = {
      group_by: [this.selectedBreakdownBy],
    };
    const sub = this.reportService
      .getReportDataWithReportDetails(
        this.reportId,
        this.CHART_ID,
        reportParams,
        this.reportTarget,
        this.quickBuild
      )
      .subscribe((data) => {
        Log.d('TenureCurrentBreakdownComponent data', data);
        const [series, categories] = this.generateChartSeries(
          data.count,
          data.data.tenure,
          this.selectedBreakdownBy
        );
        this.updateChart(series, categories);
      });
    this.subscriptions.add(sub);
  }

  protected generateChartSeries(total: number, data: any, key: string): [any[], any[]] {
    Log.d('TenureCurrentBreakdownComponent generateChartSeries', total, data);
    if (!data) {
      return [[], []];
    }

    const seriesData = data.slice(0, 30).map((c: any) => {
      return c.avg_tenure;
    });

    const categories = data.slice(0, 30).map((c: any) => {
      return c.group_values[0]?.value;
    });

    return [
      [
        {
          id: key,
          name: key,
          showInLegend: false,
          dataLabels: {
            format: '{point.y:.0f}',
          },
          data: seriesData,
          type: 'bar',
        },
      ],
      categories,
    ];
  }

  onBreakdownByChanged(value: MatButtonToggleChange) {
    if (!value) return;

    this.selectedBreakdownBy = value.value;
    this.resetChartData();
    this.getChartData();
  }

  getFieldDisplayName(field: string): string {
    return getPeopleColumnByName(field).displayName;
  }
}
