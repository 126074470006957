import { Component, Input } from '@angular/core';
import { QuickBuild, QuickBuildService } from 'src/app/people/quick-build.service';
import { VIZ_TYPES } from '../constants';
import * as moment from 'moment';
import { Log } from 'src/app/logger.service';

@Component({
  selector: 'app-viz-from-quickbuild-factory',
  templateUrl: './viz-from-quickbuild-factory.component.html',
  styleUrls: ['./viz-from-quickbuild-factory.component.scss'],
})
export class VizFromQuickbuildFactoryComponent {
  @Input() quickBuild!: QuickBuild;
  @Input() reportId!: string;
  vizInfo: { type: VIZ_TYPES | null; params: any; reportTarget: string } = {
    type: null,
    params: null,
    reportTarget: '',
  };

  VIZ_TYPES = VIZ_TYPES;

  constructor(private quickBuildService: QuickBuildService) {}

  ngOnInit() {
    Log.d('viz-from-quickbuild-factory', this.quickBuild, this.reportId);
    const reportInfo = QuickBuildService.getReportById(this.quickBuild, this.reportId);

    if (!reportInfo) {
      Log.d('viz-from-quickbuild-factory no report info', this.quickBuild, this.reportId);
      this.vizInfo = { type: null, params: null, reportTarget: '' };
      return;
    }

    const [reportDef, groupId] = reportInfo;

    let vizType: VIZ_TYPES | null = null;
    let params: any = null;
    switch (reportDef.name) {
      case 'demographics':
        const vizInfoDemo = this.getDemographicsVizFromParams(reportDef.params);
        vizType = vizInfoDemo.type;
        params = vizInfoDemo.params;
        break;
      case 'arrivals_departures':
        params = {
          date_from: moment(reportDef.params?.date_from),
          date_to: moment(reportDef.params?.date_to),
          field: reportDef.params?.group_by ? reportDef.params?.group_by[0] : null,
        };
        vizType = VIZ_TYPES.JOB_STARTS_AND_ENDS;
        break;
      case 'tenure':
        vizType = this.getTenureVizFromParams(reportDef.params);
        break;
    }
    this.vizInfo = { type: vizType, params: params, reportTarget: groupId };
    Log.d('vizInfo', this.vizInfo);
  }

  private getDemographicsVizFromParams(params: any): { type: VIZ_TYPES; params: any } {
    if ('group_by' in params) {
      if (
        params.group_by.length === 2 &&
        params.group_by[0] === 'jobs.function' &&
        params.group_by[1] === 'jobs.level'
      ) {
        return { type: VIZ_TYPES.FUNCTIONAL_DISTRIBUTION, params: null };
      }

      if (params.group_by.length === 1 && params.group_by[0] === 'location') {
        return { type: VIZ_TYPES.MAP_US_STATES, params: null };
      }
    }

    // if either date_from or date_to is in params, return demographics
    // TODO - let the AI send us these dates
    if ('date_from' in params || 'date_to' in params) {
      params = {
        date_from: params.date_from
          ? moment(params.date_from).day(1)
          : moment().subtract(5, 'year').day(1),
        date_to: moment(params.date_to),
        field: params.group_by ? params.group_by[0] : null,
      };
      return { type: VIZ_TYPES.DEMOGRAPHICS, params: params };
    }

    params = {
      field: params.group_by ? params.group_by[0] : null,
      aggregate_type: 'range',
    };
    return { type: VIZ_TYPES.DEMOGRAPHICS_CURRENT, params: params };
  }

  private getTenureVizFromParams(params: any): VIZ_TYPES | null {
    // if either date_from or date_to is in params, return demographics
    if ('date_from' in params || 'date_to' in params) {
      return null;
    }

    if ('group_by' in params && params.group_by.length === 1) {
      return VIZ_TYPES.TENURE_CURRENT_BREAKDOWN;
    }

    return null;
  }
}
