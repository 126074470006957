export const levels = [
  'C-Team',
  'VP',
  'Director',
  'Manager',
  'Senior Staff',
  'Staff',
  'Intern',
  'Consultant',
  'Other',
];
export const departments = [
  'Business Management',
  'Sales and Support',
  'Engineering',
  'Finance and Administration',
  'Operations',
  'Marketing and Product',
  'Healthcare',
  'Education',
  'Human Resources',
  'Information Technology',
  'Program and Project Management',
  'Legal',
  'Banking and Wealth Management',
  'Consulting',
  'Risk, Safety, Compliance',
  'Quality',
  'Real Estate',
  'Publishing, Editorial and Reporting',
  'Other',
];

// Ideally we'd use the `var` keyword here, but that doesn't work with the export for highcharts
export const ReportVizChartColors: any[] = [
  // colors from BattleComponent
  '#3B82F6', // blue-500
  '#ffb900', // amber-400
  '#8AC926', // lime-400
  '#9333EA', // purple-600
  '#73D2DE', // cyan-300 (soft cyan)
  '#EF4444', // red-500
  '#FFD166', // amber-300 (yellow)
  '#F87171', // red-400
  '#34D399', // green-400
  '#62748e', // slate-500
  '#818CF8', // indigo-400
  '#E74694', // pink-500
  '#60A5FA', // blue-400
  '#F97316', // orange-500
  '#FFB5A7', // light pink (custom)
  '#D946EF', // fuchsia-500
  '#BE185D', // rose-700
  '#008000', // green
  // OLD COLORS
  // colors from BattleComponent
  '#3B82F6', // blue-500
  '#FF5733', // orange-400 (darker orange)
  '#FFCA3A', // amber-300
  '#8AC926', // lime-400
  '#1982C4', // blue-600
  '#6A4C93', // purple-500
  '#FFB5A7', // light pink (custom)
  '#FFD166', // amber-300 (yellow)
  '#73D2DE', // cyan-300 (soft cyan)
  '#F4A261', // orange-300
  '#E76F51', // dark orange
  // additional colors from TailwindCSS (need more testing)
  '#F87171', // red-400
  '#FBBF24', // yellow-400
  '#34D399', // green-400
  '#818CF8', // indigo-400
  '#E74694', // pink-500
  '#A5B4FC', // indigo-300
  '#60A5FA', // blue-400
  '#F59E0B', // amber-400
  '#D97706', // amber-600
  '#D69E2E', // yellow-600
  '#EAB308', // yellow-500 (might be too close)
  '#F97316', // orange-500
  '#EF4444', // red-500
  '#D946EF', // fuchsia-500
  '#BE185D', // rose-700
  '#9333EA', // purple-600
];

export enum VIZ_TYPES {
  DEMOGRAPHICS = 'demographics',
  DEMOGRAPHICS_CURRENT = 'demographics-current',
  TENURE_CURRENT_BREAKDOWN = 'tenure-current-breakdown',
  FUNCTIONAL_DISTRIBUTION = 'functional-distribution',
  MAP_US_STATES = 'map-us-states',
  JOB_STARTS_AND_ENDS = 'job-starts-and-ends',
  PREV_NEXT_FLOW = 'prev-next-flow',
}
