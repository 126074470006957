<div
  id="filters-collapsed"
  *ngIf="!showFilters"
  class="tw-flex tw-items-center tw-text-sm tw-p-2"
  [ngClass]="{'tw-pb-3': hasFilters(rootFilterGroup)}"
  [ngClass]="{'tw-text-gray-800 tw-rounded tw-bg-gray-50 tw-border tw-border-solid tw-border-gray-300 dark:tw-border-gray-700 dark:tw-bg-gray-800 dark:tw-text-white': !chipsOnly}">
  <div
    *ngIf="hasFilters(rootFilterGroup); else noFilters"
    class="tw-flex tw-items-center tw-flex-wrap tw-gap-1">
    <h3
      *ngIf="!chipsOnly"
      class="tw-font-semibold tw-text-gray-800 tw-mt-0 tw-mb-0 tw-mr-4 tw-ml-1 dark:tw-text-gray-200">
      Active Filters
    </h3>
    <div
      class="tw-flex tw-flex-wrap tw-items-center tw-mr-2 tw-gap-1"
      *ngFor="let group of displayedGroupedFilters; trackBy: trackByGroupName">
      <span *ngIf="!chipsOnly" class="tw-font-medium tw-mr-1"
        >{{ getCategoryDisplayName(group[0]) }}:</span
      >
      <ng-container *ngFor="let filter of group[1]">
        <div
          class="badge-selected-items tw-bg-gray-500 dark:tw-bg-gray-600 tw-flex tw-items-center tw-gap-1">
          <button
            *ngIf="isFilterNameRepeated(filter[0], group[1])"
            class="operator-symbol"
            [matTooltip]="getFilterSymbol(filter[0]).tooltip"
            matTooltipPosition="above">
            <span class="tw-absolute tw-left-[4px] tw-bottom-[11px]">
              {{ getFilterSymbol(filter[0]).symbol }}
            </span>
          </button>
          <span class="tw-font-semibold">{{ filter[1] }}</span>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #noFilters>
    <div class="tw-font-semibold tw-text-dark-light-dynamic">No Active Filters</div>
  </ng-template>
</div>
