<div *ngIf="canAdmin" class="tw-flex tw-flex-col lg:tw-flex-row tw-gap-2 md:tw-gap-4 tw-my-4">
  <a
    target="_blank"
    href="https://docs.gotlivedata.com/docs/auth#api-keys"
    class="tw-flex tw-items-center lg:tw-order-1 tw-gap-1 tw-font-medium tw-text-link-color-dynamic-darker tw-text-sm hover:tw-underline tw-ml-auto">
    <mat-icon class="info-icon tw-h-4 tw-w-4">school</mat-icon>
    Learn about using API Keys
  </a>

  <div class="tw-flex tw-flex-wrap tw-gap-2 md:tw-gap-4 tw-flex-row">
    <button
      mat-raised-button
      [class.spinner]="refreshing"
      [disabled]="refreshing"
      (click)="refreshApiKeys()">
      Refresh
    </button>
    <button mat-raised-button color="primary" (click)="showCreateDialog()">Create API Key</button>
    <button
      mat-flat-button
      color="warn"
      [disabled]="!rowsSelected"
      (click)="removeSelected()"
      class="disabled:tw-border-gray-400 disabled:tw-text-gray-400 disabled:tw-cursor-not-allowed">
      Remove Selected API Keys
    </button>
  </div>
</div>
<div class="tw-w-full tw-overflow-x-auto">
  <div class="tw-min-w-[1000px] tw-w-full">
    <ag-grid-angular
      style="width: 100%;"
      [ngClass]="{ 'ag-theme-quartz': !(darkModeService.darkMode$ | async), 'ag-theme-quartz-dark': darkModeService.darkMode$ | async }"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [tooltipShowDelay]="tooltipShowDelay"
      rowSelection="multiple"
      enableCellTextSelection="true"
      ensureDomOrder="true"
      domLayout="autoHeight"
      (gridReady)="onGridReady($event)"
      (selectionChanged)="onSelectionChanged($event)">
    </ag-grid-angular>
  </div>
</div>
