<div
  class="field-wrapper filter-name tw-relative tw-w-[190px] tw-h-[26px] tw-bg-indigo-500 tw-text-white tw-flex tw-items-center tw-justify-between tw-rounded tw-border tw-border-solid tw-border-indigo-500 tw-py-1 tw-pl-2 tw-ml-8 tw-mr-1">
  <span class="tw-font-semibold tw-leading-[.9rem]">{{column.displayName}}</span>
  <button
    class="delete-icon-btn tw-relative tw-text-white hover:tw-bg-gray-300 hover:tw-text-gray-700 hover:tw-border-transparent hover:tw-font-bold tw-transition-all tw-mr-1"
    title="Delete this filter"
    (click)="onRemove()">
    <mat-icon class="delete-icon tw-absolute hover:tw-font-bold">delete_outline</mat-icon>
  </button>
</div>

<!-- operators -->
<div
  [ngClass]="{ 'field-wrapper-last': selectedOperator.inputsRequired < 1 }"
  class="field-wrapper tw-relative tw-h-[34px] tw-rounded tw-bg-white tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400 dark:tw-bg-gray-800 tw-pl-2 tw-mx-1">
  <mat-select
    [(ngModel)]="selectedOperator"
    (ngModelChange)="setFilterParams()"
    class="operator-dropdown tw-w-40 tw-mr-3">
    <mat-option *ngFor="let item of availableOperators" [value]="item">
      {{item.displayName}}
    </mat-option>
  </mat-select>
</div>

<!-- Normal strings display -->
<div
  *ngIf="column.type === ColumnType.string && selectedOperator.inputsRequired >= 1"
  class="string-display-container field-wrapper tw-max-w-[34rem] 2xl:tw-max-w-[1000px] tw-flex-wrap tw-gap-x-1 tw-gap-y-2 field-wrapper-last tw-relative tw-bg-white tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400 tw-shrink-0 dark:tw-bg-gray-800 tw-pr-1 tw-pl-2 tw-py-[4px] tw-ml-1">
  <app-badge
    *ngFor="let value of displayedBadges"
    [value]="value"
    [color]="'dark'"
    (remove)="removeBadge(value)">
  </app-badge>

  <button
    mat-stroked-button
    color="primary"
    *ngIf="filter.filter.string_values && filter.filter.string_values.length > displayBadgeLimit"
    (click)="toggleShowMore()"
    class="tw-h-[24px] tw-leading-[20px] transition duration-300 tw-ml-1 tw-px-[5px]"
    [ngClass]="{'animate-hover-effect': animateNum }">
    <ng-container *ngIf="showMoreBadges; else showMoreLabel">
      <span class="tw-flex tw-items-center">
        <mat-icon class="tw-text-[20px] tw-w-[20px] tw-h-[20px]"> keyboard_arrow_left </mat-icon>
        <span>Show fewer</span>
      </span>
    </ng-container>
    <ng-template #showMoreLabel>
      <span [ngClass]="{'tw-animate-pulse': animateNum }">
        ...and
        <span id="num-badges" class="tw-font-semibold tw-transition-all tw-duration-300">
          {{ filter.filter.string_values.length - displayBadgeLimit }}
        </span>
        more
      </span>
    </ng-template>
  </button>

  <textarea
    type="text"
    rows="1"
    #firstInputElem
    class="tw-text-sm tw-bg-gray-100 dark:tw-bg-gray-800 dark:tw-text-white tw-border tw-border-dashed tw-border-gray-300 dark:tw-border-gray-400 dark:tw-placeholder-gray-300 tw-mx-1"
    placeholder="Add value & hit Enter"
    (keyup.enter)="addBadge($event)"
    (blur)="addBadge($event)"></textarea>
</div>

<!-- number_min value -->
<div
  *ngIf="column.type === ColumnType.number && selectedOperator.inputsRequired >= 1"
  [ngClass]="{ 'field-wrapper-last': !(column.type === ColumnType.number && selectedOperator.inputsRequired >= 2) }"
  class="field-wrapper tw-bg-white tw-relative tw-border tw-border-solid tw-border-neutral-400 tw-rounded tw-flex tw-items-center tw-max-w-28 dark:tw-bg-gray-800 tw-pl-2 tw-ml-1">
  <input
    matInput
    #firstInputElem
    type="number"
    [(ngModel)]="firstInput"
    (ngModelChange)="setFilterParams()"
    class="tw-text-dark-light-dynamic tw-border-0 tw-pr-1 tw-max-w-16 tw-bg-transparent" />
</div>

<!-- number_max value -->
<div
  *ngIf="column.type === ColumnType.number && selectedOperator.inputsRequired >= 2"
  class="field-wrapper field-wrapper-last tw-bg-white tw-relative tw-border tw-border-solid tw-border-neutral-400 tw-rounded tw-flex tw-items-center tw-max-w-28 dark:tw-bg-gray-800 tw-pl-2 tw-ml-2">
  <input
    matInput
    type="number"
    [(ngModel)]="secondInput"
    (ngModelChange)="setFilterParams()"
    class="tw-text-dark-light-dynamic tw-border-0 tw-pr-1 tw-max-w-16 tw-bg-transparent" />
</div>

<!-- date value picker -->
<div
  *ngIf="column.type === ColumnType.date && selectedOperator.inputsRequired >= 1"
  class="field-wrapper tw-relative tw-bg-white tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400 dark:tw-bg-gray-800 tw-pl-2 tw-mx-1"
  [ngClass]="{ 'field-wrapper-last': !(column.type === ColumnType.date && selectedOperator.inputsRequired >= 2) }">
  <span class="tw-text-dark-light-dynamic tw-inline-block tw-mr-1">From:</span>
  <input
    matInput
    #firstInputElem
    [(ngModel)]="firstInput"
    [matDatepicker]="picker"
    (ngModelChange)="setFilterParams()"
    class="tw-text-dark-light-dynamic tw-border-0 tw-pr-1 tw-min-w-16 tw-bg-transparent" />
  <mat-datepicker-toggle
    matIconSuffix
    [for]="picker"
    class="tw-text-dark-light-dynamic"></mat-datepicker-toggle>
  <mat-datepicker #picker class="tw-text-dark-light-dynamic"></mat-datepicker>
</div>

<div
  *ngIf="column.type === ColumnType.date && selectedOperator.inputsRequired >= 2"
  class="field-wrapper field-wrapper-last tw-relative tw-bg-white tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400 dark:tw-bg-gray-800 tw-pl-2 tw-ml-1">
  <span class="tw-text-dark-light-dynamic tw-inline-block tw-mr-1">To:</span>
  <input
    id="people-filter-date-to"
    type="date"
    [(ngModel)]="secondInput"
    (ngModelChange)="setFilterParams()"
    class="tw-text-dark-light-dynamic tw-border-0 tw-pr-1 tw-min-w-16 tw-bg-transparent" />
</div>

<!-- job level value -->
<div
  *ngIf="column.type === ColumnType.joblevel && selectedOperator.inputsRequired >= 1"
  class="field-wrapper field-wrapper-last tw-relative tw-bg-white dark:tw-bg-gray-800 tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400 tw-min-w-[146px] tw-pl-2 tw-ml-1">
  <mat-select [(ngModel)]="listInput" (ngModelChange)="setFilterParams()" multiple>
    <mat-option *ngFor="let item of jobLevelOptions" [value]="item">{{item}}</mat-option>
  </mat-select>
</div>

<!-- job functions value -->
<div
  *ngIf="column.type === ColumnType.jobfunction && selectedOperator.inputsRequired >= 1"
  class="field-wrapper field-wrapper-last tw-relative tw-bg-white dark:tw-bg-gray-800 tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400 tw-min-w-48 tw-max-w-[600px] tw-pl-2 tw-ml-1">
  <mat-select [(ngModel)]="listInput" (ngModelChange)="setFilterParams()" multiple>
    <div id="job-function-dropdown">
      <div class="select-all tw-flex tw-items-center tw-py-4">
        <button mat-stroked-button class="tw-text-sm tw-ml-4 tw-mr-3" (click)="selectAllOptions()">
          Select All
        </button>
        <span class="tw-font-light"> | </span>
        <button
          mat-stroked-button
          class="tw-text-sm tw-ml-4 tw-mr-3"
          (click)="deselectAllOptions()">
          Unselect All
        </button>
      </div>

      <div class="tw-columns-2 tw-pb-4">
        <mat-option *ngFor="let item of jobFunctionOptions" [value]="item">{{item}}</mat-option>
      </div>
    </div>
  </mat-select>
</div>

<!-- boolean value -->
<div
  *ngIf="column.type === ColumnType.boolean && selectedOperator.inputsRequired >= 1"
  class="field-wrapper field-wrapper-last tw-relative tw-bg-white dark:tw-bg-gray-800 tw-pl-2 tw-rounded tw-flex tw-items-center tw-border tw-border-solid tw-border-neutral-400 tw-w-48 tw-ml-1">
  <mat-select [(ngModel)]="firstInput" (ngModelChange)="setFilterParams()" multiple>
    <mat-option [value]="true">Yes</mat-option>
    <mat-option [value]="false">No</mat-option>
  </mat-select>
</div>

<div
  class="connector-and"
  [ngClass]="{'tw-bg-white': !isEvenDepth(), 'tw-bg-gray-100': isEvenDepth(), 'tw-text-gray-500': isEvenDepth()}">
  {{ operator }}
</div>
