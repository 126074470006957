<button
  *ngIf="creditUsage?.peopleQuota && (!hideText || !hideProgressBar || creditUsage?.peopleQuotaWarning !== null)"
  type="button"
  (keydown)="handleKeydown($event)"
  [class]="transparentBackground ? 
    'tw-flex tw-flex-row tw-items-center tw-gap-3 tw-p-2 tw-rounded-lg dark:tw-text-white tw-cursor-pointer' : 
    'tw-flex tw-flex-row tw-items-center tw-gap-3 tw-bg-gray-200 dark:tw-text-white dark:tw-bg-gray-800 tw-p-2 tw-rounded-lg dark:hover:tw-bg-gray-900 focus:tw-outline-none tw-ring-2 tw-ring-gray-300 dark:tw-ring-gray-700 focus:tw-ring-3 focus:tw-ring-gray-400 dark:focus:tw-ring-gray-600 tw-cursor-pointer'"
  [matMenuTriggerFor]="popoverMenu">
  <span *ngIf="!hideText" class="tw-flex tw-items-center tw-gap-2">
    <img src="../../../assets/icons/creditsw.svg" class="ldt-logo tw-w-6" />
    Credits
  </span>

  <mat-progress-bar
    *ngIf="!hideProgressBar"
    class="tw-w-20"
    mode="determinate"
    [value]="creditUsage?.peopleUsagePercentage"></mat-progress-bar>

  <!-- warning or error icon -->
  <svg
    *ngIf="creditUsage?.peopleQuotaWarning !== null"
    class="tw-h-4"
    viewBox="0 0 486.463 486.463">
    <g>
      <g>
        <path
          [attr.fill]="creditUsage?.peopleQuotaWarning === 'error' ? 'var(--red-500)' : 'var(--warning-color)'"
          d="M243.225,333.382c-13.6,0-25,11.4-25,25s11.4,25,25,25c13.1,0,25-11.4,24.4-24.4
          C268.225,344.682,256.925,333.382,243.225,333.382z" />
        <path
          [attr.fill]="creditUsage?.peopleQuotaWarning === 'error' ? 'var(--red-500)' : 'var(--warning-color)'"
          d="M474.625,421.982c15.7-27.1,15.8-59.4,0.2-86.4l-156.6-271.2c-15.5-27.3-43.5-43.5-74.9-43.5s-59.4,16.3-74.9,43.4
          l-156.8,271.5c-15.6,27.3-15.5,59.8,0.3,86.9c15.6,26.8,43.5,42.9,74.7,42.9h312.8
          C430.725,465.582,458.825,449.282,474.625,421.982z M440.625,402.382c-8.7,15-24.1,23.9-41.3,23.9h-312.8
          c-17,0-32.3-8.7-40.8-23.4c-8.6-14.9-8.7-32.7-0.1-47.7l156.8-271.4c8.5-14.9,23.7-23.7,40.9-23.7c17.1,0,32.4,8.9,40.9,23.8
          l156.7,271.4C449.325,369.882,449.225,387.482,440.625,402.382z" />
        <path
          [attr.fill]="creditUsage?.peopleQuotaWarning === 'error' ? 'var(--red-500)' : 'var(--warning-color)'"
          d="M237.025,157.882c-11.9,3.4-19.3,14.2-19.3,27.3c0.6,7.9,1.1,15.9,1.7,23.8c1.7,30.1,3.4,59.6,5.1,89.7
          c0.6,10.2,8.5,17.6,18.7,17.6c10.2,0,18.2-7.9,18.7-18.2c0-6.2,0-11.9,0.6-18.2c1.1-19.3,2.3-38.6,3.4-57.9
          c0.6-12.5,1.7-25,2.3-37.5c0-4.5-0.6-8.5-2.3-12.5C260.825,160.782,248.925,155.082,237.025,157.882z" />
      </g>
    </g>
  </svg>
</button>

<!-- mat-menu as popover -->
<mat-menu #popoverMenu="matMenu" class="custom-popover" [overlapTrigger]="false">
  <div
    *ngIf="creditUsage?.peopleQuota"
    (click)="$event.stopPropagation()"
    class="popover-content tw-text-dark-light-dynamic tw-flex tw-flex-col tw-gap-3 tw-p-4">
    <button mat-icon-button class="tw-absolute tw-top-0 tw-right-0" (click)="closeMenu()">
      <mat-icon class="sm-close-icon">close</mat-icon>
    </button>

    <div class="tw-text-lg tw-font-semibold">Your account balance</div>

    <div>
      <span class="tw-font-bold">{{ creditUsage?.peopleUsage | number }} </span> of
      <span class="tw-font-bold">{{ creditUsage?.peopleQuota | number }}</span> credits used
      <span class="tw-ml-2">|</span>
      <span
        *ngIf="creditUsage?.peopleUsagePercentage"
        class="tw-text-base tw-font-bold tw-text-gray-600 dark:tw-text-gray-400 tw-ml-2">
        {{ creditUsage?.peopleUsagePercentage | number : '1.0-0' }}%
      </span>
    </div>

    <div
      class="tw-flex tw-items-center tw-gap-2 tw-text-sm tw-text-blue-800 tw-border tw-border-blue-300 tw-rounded-lg tw-bg-blue-50 dark:tw-bg-gray-700 dark:tw-text-gray-100 tw-p-2"
      role="alert">
      <mat-icon class="material-icons-outlined tw-flex-shrink-0 tw-inline tw-opacity-75">
        info_outline
      </mat-icon>
      <div>Monthly credits renew on {{ creditUsage?.resetDate | date:'MMM d':'UTC' }}.</div>
    </div>

    <!-- when we're ready for the Billing button -->
    <!-- <button mat-stroked-button color="primary" (click)="navigateToSettings()">
      Plan & Billing Settings
    </button> -->
  </div>
</mat-menu>
