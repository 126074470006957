import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import * as dayjs from 'dayjs';
import { Subject, takeUntil } from 'rxjs';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import {
  AdminOnboardingService,
  CompanyWithCount,
  EnrichedStream,
} from '../admin-onboarding-service.service';
import { Org, OrganizationsService } from 'ldt-identity-service-api';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';
import { lightTheme, darkTheme } from 'src/app/shared/highcharts-themes';

const ninetyDaysAgo = new Date(new Date().getTime() - 90 * 24 * 3600 * 1000);
const sevenDaysAgo = new Date(new Date().getTime() - 7 * 24 * 3600 * 1000);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  private destroy$ = new Subject<void>();

  Highcharts: typeof Highcharts = Highcharts; // required
  signupChart: Highcharts.Chart | undefined;
  cumSignupChart: Highcharts.Chart | undefined;
  activeChart: Highcharts.Chart | undefined;
  weeklyGrowthChart: Highcharts.Chart | undefined;
  companiesChart: Highcharts.Chart | undefined;

  streamData: EnrichedStream[] = [];
  companyCountData: CompanyWithCount[] = [];

  activeMBTrialCount: number = 0;
  expiredMBTrialCount: number = 0;
  recentlyExpiredMBTrialCount: number = 0;
  expiringSoonMBTrialCount: number = 0;
  recentlyCreatedMBTrialCount: number = 0;

  constructor(
    private notify: NotificationService,
    public streamsService: AdminOnboardingService,
    private orgService: OrganizationsService,
    public darkModeService: DarkModeService
  ) {}

  ngOnInit(): void {
    this.streamsService.streams$.pipe(takeUntil(this.destroy$)).subscribe((streams) => {
      if (!streams || !streams[0].enriched_companies) return;

      this.streamData = streams;

      this.generateStreamsSignupChartData(streams);
      this.generateActiveChartData(streams);
      this.generateWeeklyGrowthChartData(streams);
    });

    this.streamsService.companyCount$.pipe(takeUntil(this.destroy$)).subscribe((companies) => {
      if (!companies) return;

      this.companyCountData = companies;
      this.generateCompanyChartData(companies);
    });

    this.orgService.getOrgs().subscribe({
      next: (orgs) => {
        this.generateMBSignupChartData(orgs);
      },
      error: (error) => {
        this.notify.error('Failed to fetch MB trial data');
      },
    });

    // init theme for highcharts
    this.applyChartTheme(this.darkModeService.currentDarkModeValue);
  }

  applyChartTheme(isDarkMode: boolean) {
    Highcharts.setOptions(isDarkMode ? darkTheme : lightTheme);
  }

  generateStreamsSignupChartData(stream: EnrichedStream[]) {
    if (!stream) return;

    const counts = stream
      .filter((row) => row.email && !row.email.endsWith('@livedatatechnologies.com'))
      .reduce(
        (acc, item) => {
          // Extract date in YYYY-MM-DD format
          const date = item.created_at!.split('T')[0] || '';
          // Increment count for this date
          acc[date] = acc[date] ? acc[date] + 1 : 1;

          return acc;
        },
        {} as Record<string, number>
      );

    // Convert counts to an array suitable for Highcharts
    const chartData = Object.entries(counts).map(([date, count]) => {
      return [Date.parse(date), count];
    });

    const sortedData = Object.keys(counts).sort();
    let cumTotal = 0;
    const cumData = sortedData.map((date) => {
      cumTotal += counts[date];
      return [Date.parse(date), cumTotal];
    });

    if (this.signupChart && this.signupChart.series.length > 0) {
      this.signupChart.series[0].setData(chartData);
    }

    if (this.cumSignupChart && this.cumSignupChart.series.length > 0) {
      this.cumSignupChart.series[0].setData(cumData);
    }
  }

  generateMBSignupChartData(orgs: Org[]) {
    const trials = orgs.filter((org) => org.settings?.moneyball?.subType === 'trial');
    this.activeMBTrialCount = 0;
    this.expiredMBTrialCount = 0;
    this.recentlyExpiredMBTrialCount = 0;
    this.recentlyCreatedMBTrialCount = 0;
    this.expiringSoonMBTrialCount = 0;

    const counts = trials.reduce(
      (acc, item) => {
        // Extract date in YYYY-MM-DD format
        const date = item.createdAt!.split('T')[0] || '';
        // Increment count for this date
        acc[date] = acc[date] ? acc[date] + 1 : 1;

        if (
          item.settings?.moneyball?.subType === 'trial' &&
          item.settings?.moneyball?.expiresAt &&
          new Date(item.settings?.moneyball?.expiresAt) < new Date()
        ) {
          this.expiredMBTrialCount++;
          // If it expired within the last 7 days, increment recent expired count
          if (new Date(item.settings?.moneyball?.expiresAt) > sevenDaysAgo) {
            this.recentlyExpiredMBTrialCount++;
          }
        } else {
          this.activeMBTrialCount++;
          // If it is expiring within the next 7 days, increment expiring soon count
          if (
            new Date(item.settings?.moneyball?.expiresAt) <
            new Date(new Date().getTime() + 7 * 24 * 3600 * 1000)
          ) {
            this.expiringSoonMBTrialCount++;
          }
        }

        if (item.createdAt && new Date(item.createdAt) > sevenDaysAgo) {
          this.recentlyCreatedMBTrialCount++;
        }

        return acc;
      },
      {} as Record<string, number>
    );

    // Convert counts to an array suitable for Highcharts
    const chartData = Object.entries(counts).map(([date, count]) => {
      return [Date.parse(date), count];
    });

    if (this.signupChart && this.signupChart.series.length > 0) {
      this.signupChart.series[1].setData(chartData.sort((a, b) => a[0] - b[0]));
    }
  }

  generateActiveChartData(stream: EnrichedStream[]) {
    if (!stream) return;

    let deletedCount = 0;
    let notDeletedCount = 0;

    stream
      .filter((row) => row.email && !row.email.endsWith('@livedatatechnologies.com'))
      .forEach((item) => {
        if (item.deleted_at) {
          deletedCount++;
        } else {
          notDeletedCount++;
        }
      });

    const chartData = [
      { name: 'Unsubscribed', y: deletedCount },
      { name: 'Active', y: notDeletedCount },
    ];
    if (this.activeChart && this.activeChart.series.length > 0) {
      this.activeChart.series[0].setData(chartData);
    }
  }

  generateWeeklyGrowthChartData(stream: EnrichedStream[]) {
    if (!stream) return;

    // Step 1: Parse timestamps and filter out the current week
    const parsedItems = stream
      .filter((row) => row.email && !row.email.endsWith('@livedatatechnologies.com'))
      .map((item) => ({
        ...item,
        createdAt: dayjs(item.created_at),
      }));

    // Step 2: Group items by week
    const groupedByWeek = parsedItems.reduce(
      (acc, item) => {
        const createdWeek = item.createdAt.startOf('w').valueOf();

        if (!acc[createdWeek]) {
          acc[createdWeek] = { created: 0, canceled: 0 };
        }
        acc[createdWeek].created += 1;

        if (item.deleted_at) {
          const deletedWeek = dayjs(item.deleted_at).startOf('w').valueOf();
          if (!acc[deletedWeek]) {
            acc[deletedWeek] = { created: 0, canceled: 0 };
          }
          acc[deletedWeek].canceled += 1;
        }
        return acc;
      },
      {} as Record<number, { created: number; canceled: number }>
    );

    // Step 3: Calculate week-over-week growth
    const weeklyGrowth: any[] = [];
    const weeks = Object.keys(groupedByWeek)
      .map(Number)
      .sort((a, b) => a - b);

    let previousWeekNet = 0;
    let startWeek = 2;

    for (let i = 0; i < weeks.length; i++) {
      const currentWeekData = groupedByWeek[weeks[i]];
      const currentWeekNet = currentWeekData.created - currentWeekData.canceled;

      const growth = i > startWeek ? (currentWeekNet / previousWeekNet) * 100 : 0;

      weeklyGrowth.push([weeks[i], i > 0 ? growth : null]);
      previousWeekNet += currentWeekNet;
    }

    // Step 4: Format data for Highcharts
    const data = weeklyGrowth.map((wg) => wg.growth);

    if (this.weeklyGrowthChart && this.weeklyGrowthChart.series.length > 0) {
      this.weeklyGrowthChart.series[0].setData(weeklyGrowth);
    }
  }

  generateCompanyChartData(companies: CompanyWithCount[]) {
    if (!companies) return;

    const chartData = companies.sort((a, b) => b.count - a.count).slice(0, 20);
    const categories = chartData.map((item) => item.name || '');
    const data = chartData.map((item) => item.count);

    if (this.companiesChart && this.companiesChart.series.length > 0) {
      this.companiesChart.xAxis[0].setCategories(categories);
      this.companiesChart.series[0].setData(data);
    }
  }

  ngAfterViewInit(): void {
    this.signupChart = Highcharts.chart(
      'dashboard-signupChartContainer',
      this.signupHistogramOptions
    );
    this.cumSignupChart = Highcharts.chart(
      'dashboard-cumSignupChartContainer',
      this.cumSignupHistogramOptions
    );
    this.activeChart = Highcharts.chart(
      'dashboard-activeChartContainer',
      this.activePieChartOptions
    );
    this.weeklyGrowthChart = Highcharts.chart(
      'dashboard-weeklyGrowthChartContainer',
      this.weeklyGrowthChartOptions
    );
    this.companiesChart = Highcharts.chart(
      'dashboard-topCompaniesChartContainer',
      this.companiesOptions
    );
    this.generateStreamsSignupChartData(this.streamData);
    this.generateActiveChartData(this.streamData);
    this.generateWeeklyGrowthChartData(this.streamData);
    this.generateCompanyChartData(this.companyCountData);
  }

  getActiveSubscriptionCount() {
    if (!this.streamData) return '';

    return this.streamData.filter(
      (row) => row.email && !row.email.endsWith('@livedatatechnologies.com') && !row.deleted_at
    ).length;
  }

  getRecentSubscriptCount() {
    if (!this.streamData) return '';

    return this.streamData.filter(
      (row) =>
        row.email &&
        !row.email.endsWith('@livedatatechnologies.com') &&
        !row.deleted_at &&
        dayjs(row.created_at).isAfter(dayjs().subtract(1, 'week'))
    ).length;
  }

  getRecentUnsubCount() {
    if (!this.streamData) return '';

    return this.streamData.filter(
      (row) =>
        row.email &&
        !row.email.endsWith('@livedatatechnologies.com') &&
        row.deleted_at &&
        dayjs(row.deleted_at).isAfter(dayjs().subtract(1, 'week'))
    ).length;
  }

  ngOnDestroy() {
    if (this.signupChart) {
      this.signupChart.destroy();
      this.signupChart = undefined;
    }
    if (this.cumSignupChart) {
      this.cumSignupChart.destroy();
      this.cumSignupChart = undefined;
    }
    if (this.activeChart) {
      this.activeChart.destroy();
      this.activeChart = undefined;
    }
    if (this.weeklyGrowthChart) {
      this.weeklyGrowthChart.destroy();
      this.weeklyGrowthChart = undefined;
    }
    if (this.companiesChart) {
      this.companiesChart.destroy();
      this.companiesChart = undefined;
    }
    this.destroy$.next();
    this.destroy$.complete();
  }

  signupHistogramOptions: Highcharts.Options = {
    colors: ['#8e91ff', '#FD974D'], // var(--primary-purple-500), var(--orange-500)
    credits: {
      enabled: false,
    },
    chart: {
      type: 'column',
      zooming: {
        type: 'x',
      },
      events: {
        load() {
          const chart = this;

          // Set initial zoom to the last 90 days
          const now = new Date().getTime();
          const ninetyDaysAgo = new Date().getTime() - 180 * 24 * 60 * 60 * 1000;
          chart.xAxis[0].setExtremes(ninetyDaysAgo, now, true, false);
        },
      },
    },
    title: {
      text: 'Daily Signups',
      style: {
        fontSize: '18px',
      },
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e %b %Y', // format for the dates on the xAxis
      },
    },
    yAxis: {
      title: {
        text: undefined,
      },
      min: 0,
    },
    series: [
      {
        type: 'column',
        name: 'Streams',
        showInLegend: false,
      },
      {
        type: 'column',
        name: 'Moneyball',
        showInLegend: false,
      },
    ],
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        borderColor: 'transparent',
      },
    },
    exporting: {
      buttons: {
        contextButton: {
          symbol: 'menuball',
          symbolSize: 10,
        },
      },
    },
  };

  cumSignupHistogramOptions: Highcharts.Options = {
    colors: ['#8e91ff'], // var(--primary-purple-500)
    credits: {
      enabled: false,
    },
    chart: {
      type: 'spline',
    },
    title: {
      text: 'Total Streams Signups',
      style: {
        fontSize: '18px',
      },
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e %b %Y', // format for the dates on the xAxis
      },
    },
    yAxis: {
      title: {
        text: undefined,
      },
      min: 0,
    },
    series: [
      {
        type: 'spline',
        name: 'Signups',
        showInLegend: false,
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          symbol: 'menuball',
          symbolSize: 10,
        },
      },
    },
  };

  weeklyGrowthChartOptions: Highcharts.Options = {
    colors: ['#8e91ff'], // var(--primary-purple-500)
    credits: {
      enabled: false,
    },
    chart: {
      type: 'column',
    },
    title: {
      text: 'Streams Weekly Growth Rate (%)',
      style: {
        fontSize: '18px',
      },
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        day: '%e %b %Y', // format for the dates on the xAxis
      },
    },
    yAxis: {
      title: {
        text: undefined,
      },
      min: 0,
      labels: {
        formatter: function () {
          return (this.value as number).toFixed(1) + '%';
        },
      },
    },
    tooltip: {
      formatter: function () {
        let start = dayjs(this.x).format('MMM D');
        let end = dayjs(this.x).add(6, 'day').format('MMM D');
        return `<b>${start} - ${end}</b><br/>Growth: <b>${(this.y as number).toFixed(1)}%</b>`;
      },
      pointFormatter: function () {
        return `<span style="color:${this.color}">\u25CF</span> ${this.series.name}: <b>${(this.y as number).toFixed(1)}%</b><br/>`;
      },
    },
    series: [
      {
        type: 'column',
        name: 'Growth',
        showInLegend: false,
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          symbol: 'menuball',
          symbolSize: 10,
        },
      },
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        borderColor: 'transparent',
      },
    },
  };

  activePieChartOptions: Highcharts.Options = {
    colors: ['#8e91ff', '#FD974D'], // var(--primary-purple-500), var(--orange-500)
    credits: {
      enabled: false,
    },
    chart: {
      type: 'pie',
    },
    title: {
      text: 'Streams Subscription Status',
      style: {
        fontSize: '18px',
      },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: false,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.y}<br /> ({point.percentage:.0f} %) ',
        },
      },
    },
    series: [
      {
        type: 'pie',
        name: 'Subscriptions',
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          symbol: 'menuball',
          symbolSize: 10,
        },
      },
    },
  };

  companiesOptions: Highcharts.Options = {
    colors: ['#8e91ff'], // var(--primary-purple-500)
    credits: {
      enabled: false,
    },
    chart: {
      type: 'bar',
    },
    title: {
      text: 'Streams Top Companies',
      style: {
        fontSize: '18px',
      },
    },
    xAxis: {
      type: 'linear',
      labels: {
        style: {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: 100,
        },
        overflow: 'justify',
      },
    },
    yAxis: {
      title: {
        text: undefined,
      },
      min: 0,
    },
    series: [
      {
        type: 'bar',
        name: 'Count',
        showInLegend: false,
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          symbol: 'menuball',
          symbolSize: 10,
        },
      },
    },
    plotOptions: {
      bar: {
        borderWidth: 0,
        borderColor: 'transparent',
      },
    },
  };
}
