<div
  class="tw-flex tw-flex-col tw-col-span-2 xl:tw-col-span-1 tw-h-full tw-max-h-[500px] tw-justify-end">
  <div class="charts-actions tw-flex tw-items-center tw-justify-end tw-mt-4 xl:tw-mt-0 tw-mb-2">
    <!-- AI btn -->
    <button
      *ngIf="showAIAnalysis"
      [class.spinner]="chartAnalyzing"
      [disabled]="chartAnalyzing"
      mat-stroked-button
      color="primary"
      (click)="analyzeArrivalsDepartures()"
      class="tw-shrink-0 tw-order-2 md:tw-order-1 md:tw-mr-auto">
      <mat-icon class="tw-font-bold tw-mr-0">auto_awesome</mat-icon>
    </button>

    <!-- breakdown by -->
    <div
      *ngIf="allowFieldSelection"
      class="tw-flex tw-flex-wrap tw-items-center tw-space-x-1 tw-order-1 md:tw-order-2 tw-w-full md:tw-w-auto tw-mb-2 md:tw-mb-0">
      <span class="tw-text-sm md:tw-text-sm tw-font-medium tw-text-gray-800 dark:tw-text-gray-200">
        Breakdown by:
      </span>
      <mat-button-toggle-group
        #group="matButtonToggleGroup"
        name="values"
        aria-label="Values"
        (change)="onBreakdownByChanged($event)"
        class="moneyBattleToggle tw-h-6 tw-border tw-border-solid tw-border-gray-300 dark:tw-border-none">
        <mat-button-toggle
          *ngFor="let breakdownBy of breakdownByOptions"
          [value]="breakdownBy"
          class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
          [checked]="selectedBreakdownBy === breakdownBy">
          <ng-container *ngIf="selectedBreakdownBy === breakdownBy">
            <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
          </ng-container>
          {{ getFieldDisplayName(breakdownBy) }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <!-- chart -->
  <div class="tw-h-[400px] mat-card-shadow tw-rounded">
    <div
      id="highchartsContainer-{{ chartId }}"
      style="width: 100%; height: 100%"
      class="tw-rounded"></div>
  </div>
</div>
