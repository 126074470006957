import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, finalize, shareReplay, tap, timeout } from 'rxjs/operators';
import { NotificationService } from '../shared/notification-service/notification.service';
import { AuthService } from './service/auth.service';
import * as Sentry from '@sentry/angular-ivy';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private readonly EXPIRY_BUFFER_MS = 30 * 1000;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private readonly auth: AuthService,
    private route: Router,
    private notify: NotificationService
  ) {}

  private _shouldRefreshToken(): boolean {
    const expiresAt = this.auth.getTokenExpirationTime();
    if (!expiresAt) return false;
    return Date.now() + this.EXPIRY_BUFFER_MS >= expiresAt;
  }

  private refreshCall$: Observable<any> | null = null;

  private _handleTokenRefresh(): Observable<any> {
    if (!this.refreshCall$) {
      // Only create a new refresh request if one isn't already in progress
      this.refreshCall$ = this.auth.refreshSession().pipe(
        timeout({
          first: 10000, // 10 seconds timeout
          with: () => throwError(() => new Error('Token refresh timeout')),
        }),
        tap((token) => {
          // Update the refresh token subject for all waiting requests
          this.refreshTokenSubject.next(token);
        }),
        catchError((err) => {
          // Return the error - let the caller handle it
          return throwError(() => err);
        }),
        finalize(() => {
          // Cleanup when request completes, allowing new calls in the future
          this.refreshCall$ = null;
        }),
        shareReplay(1) // Ensure the result is shared across subscribers
      );
    }

    // Wait for the refresh token and emit it when ready
    return this.refreshCall$;
  }

  private _checkTokenExpiryErr(error: HttpErrorResponse): boolean {
    return (
      error.status === 401 &&
      error.error &&
      ((error.error.message && error.error.message.includes('expired')) ||
        (typeof error.error === 'string' && error.error.includes('expired')))
    );
  }

  private handleError(req: HttpRequest<any>, err: HttpErrorResponse): Observable<any> {
    // If grantType == password, the user is trying to login with their password. Pass the error along
    // Also if a recaptcha is in the request, the 401 should be returned since it's likely a recaptcha failure
    if (
      err.status === 401 &&
      !(req.body?.grantType && req.body?.grantType === 'password') &&
      !req.body?.recaptchaToken
    ) {
      this.notify.error('Your session has expired. Please login again.');
      this.auth.logOut();
      this.route.navigateByUrl(`/login`);
      return of(true); // Do not log/throw 401s
    }
    if (err.status === 403) {
      this.notify.error('You do not have permission to access this product/resource.');
    }
    if (err.status === 402) {
      this.notify.error(
        'You have reached your limit of API requests. Please upgrade your account.'
      );
    }
    if (err.status === 503) {
      this.notify.error('The system is currently undergoing maintenance. Please try again later.');
      return of(true); // Do not log/throw 503s
    }

    // Group errors together based on their request and response
    Sentry.withScope(function (scope) {
      // Skip 404 errors from Clean jobs
      if (
        err?.status === 404 &&
        req.url.startsWith('https://gotlivedata.io/api/clean/v2/cleanJobItem')
      ) {
        return;
      }
      let logUrl = req.url
        .split('/')
        .filter((x) => !x.includes('_'))
        .join('/');
      scope.setFingerprint([req.method, logUrl, String(err.status)]);
      scope.setTransactionName(req.method + ' ' + logUrl + ' ' + err.status);
      Sentry.captureException(err);
    });
    return throwError(() => err);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Skip token handling for logout and session endpoints
    if (req.url.endsWith('/logout') || req.url.endsWith('/session')) {
      return next.handle(req).pipe(catchError((err) => this.handleError(req, err)));
    }

    // Check if token needs refresh before making request
    if (this._shouldRefreshToken()) {
      return this._handleTokenRefresh().pipe(
        switchMap(() => {
          return next.handle(this.updateHeader(req));
        }),
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            return this.handleError(req, error);
          }
          return throwError(() => error);
        })
      );
    }

    // Handle regular requests and potential token expiry during request
    return next.handle(req).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && this._checkTokenExpiryErr(error)) {
          return this._handleTokenRefresh().pipe(
            switchMap(() => {
              return next.handle(this.updateHeader(req));
            })
          );
        }
        return this.handleError(req, error);
      })
    );
  }

  updateHeader(req: HttpRequest<any>) {
    const authToken = this.auth.getAccessTokenValue;
    return req.clone({
      headers: req.headers.set('Authorization', `Bearer ${authToken}`),
    });
  }
}
