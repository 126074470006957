import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompaniesService, Company } from 'ldt-dw-reader-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { SelectedCompany } from 'src/app/shared/company-search/company-search.component';
import { CompanyInfoService } from 'src/app/shared/company-search/company-info-service.service';

@Component({
  selector: 'app-edit-company-group-dialog',
  templateUrl: './edit-company-group.component.html',
  styleUrls: ['./edit-company-group.component.scss'],
})
export class EditCompanyGroupDialogComponent implements OnInit {
  company: Company;
  childCompanies: Company[] = [];
  currentParentCompany: Company | null = null;
  loading = false;
  saving = false;
  newParentCompany: Company | null = null;

  constructor(
    protected dialogRef: MatDialogRef<EditCompanyGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    protected data: {
      company: Company;
      childCompanies: Company[];
    },
    protected companiesService: CompaniesService,
    protected notify: NotificationService,
    protected companyInfoService: CompanyInfoService
  ) {}

  ngOnInit(): void {
    this.company = this.data.company;
    this.childCompanies = this.data.childCompanies;
    this.loadData();
  }

  protected loadData() {
    const currentValue = this.company.group_id;
    if (currentValue) {
      this.companiesService.getCompanyByLookup(currentValue).subscribe((company) => {
        this.currentParentCompany = company as Company;
      });
    }
  }

  parentCompanySelected(company: SelectedCompany) {
    this.newParentCompany = company.company;
  }

  removeValue() {
    this.saving = true;
    this.companyInfoService.updateCompanyField(this.company, 'group_id', null).subscribe({
      next: () => {
        this.notify.success(`Group ID removed successfully`);
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.notify.error(`Failed to remove Group ID`);
      },
      complete: () => {
        this.saving = false;
      },
    });
  }

  updateCompanyAndChildren() {
    if (!this.newParentCompany) return;

    this.saving = true;
    try {
      const newValue = this.newParentCompany?.id;
      if (!newValue) {
        this.notify.error(`New Group ID is required`);
        return;
      }

      // First move the other companies
      for (const company of this.childCompanies) {
        this.companyInfoService.updateCompanyField(company, 'group_id', newValue).subscribe({
          next: () => {
            // Nothing to do here
          },
          error: (err) => {
            this.notify.error(`Failed to change Group ID for other companies`);
          },
        });
      }

      // Commenting this out temporarily. WE might want to bring this back some day
      // // Then move the aliases
      // const aliasesToDelete = this.aliasSources.map((alias) => {
      //   return {
      //     key: alias.key,
      //     key_type: alias.keyType,
      //     id: alias.id,
      //   };
      // });
      // let deleteRequest: DeleteCompanyAliasesRequest = {
      //   keys: aliasesToDelete,
      // };
      // this.companiesService.deleteCompanyAliases(deleteRequest).subscribe({
      //   next: () => {
      //     const log: ChangeLog = {
      //       resource_type: 'alias',
      //       resource_id: `${aliasesToDelete.map((alias) => `${alias.key_type}:${alias.key}`).join(',')}`,
      //       action: 'delete',
      //       fields: [],
      //       oldValues: { id: this.company.id, linkedin: this.company.linkedin },
      //       newValues: {},
      //       timestamp: new Date(),
      //       userId: this.auth.getUserValue.id,
      //       userEmail: this.auth.getUserValue.email,
      //     };
      //     this.changeLogger.logChange(log);

      //     let createReq: CreateCompanyAliasRequestInner[] = this.aliasSources.map((alias) => {
      //       if (alias.keyType === 'linkedin') {
      //         return { linkedin: alias.key };
      //       } else {
      //         return { name: alias.key };
      //       }
      //     });

      //     this.companiesService
      //       .createCompanyAlias(this.newParentCompany?.id!, createReq)
      //       .subscribe({
      //         next: () => {
      //           const log: ChangeLog = {
      //             resource_type: 'alias',
      //             resource_id: `${createReq.map((alias) => `${Object.keys(alias)[0]}:${Object.values(alias)[0]}`).join(',')}`,
      //             action: 'create',
      //             fields: [],
      //             oldValues: {},
      //             newValues: {
      //               id: this.newParentCompany?.id,
      //             },
      //             timestamp: new Date(),
      //             userId: this.auth.getUserValue.id,
      //             userEmail: this.auth.getUserValue.email,
      //           };
      //           this.changeLogger.logChange(log);

      //           this.notify.success(`Alias moved successfully`);
      //         },
      //       });
      //   },
      //   error: (err) => {
      //     this.notify.error(`Failed to move alias`);
      //     console.error(err);
      //   },
      // });
      // Then set the new value on this company
      this.companyInfoService.updateCompanyField(this.company, 'group_id', newValue).subscribe({
        next: () => {
          this.notify.success(`Group ID updated and moved children`);
          this.dialogRef.close(true);
        },
        error: (err) => {
          this.notify.error(`Failed to update Group ID`);
        },
        complete: () => {
          this.saving = false;
        },
      });
    } catch (err) {
      this.notify.error(`Failed to update Group ID`);
      console.error(err);
    } finally {
      this.saving = false;
    }
  }

  updateCompanyOnly() {
    if (!this.newParentCompany) return;

    this.saving = true;
    try {
      const newValue = this.newParentCompany?.id;
      if (!newValue) {
        this.notify.error(`New Group ID is required`);
        return;
      }

      return this.companyInfoService
        .updateCompanyField(this.company, 'group_id', newValue)
        .subscribe({
          next: () => {
            this.notify.success(`Group ID updated successfully`);
            this.dialogRef.close(true);
          },
          error: (err) => {
            this.notify.error(`Failed to update Group ID`);
          },
          complete: () => {
            this.saving = false;
          },
        });
    } catch (err) {
      this.notify.error(`Failed to update Group ID`);
    } finally {
      this.saving = false;
    }

    return;
  }

  close() {
    this.dialogRef.close();
  }
}
