<div
  class="subscription-drawer-open tw-fixed tw-inset-0 tw-z-50 tw-flex tw-justify-end"
  *ngIf="isOpen"
  [@overlayAnimation]>
  <!-- Backdrop -->
  <div
    class="tw-absolute tw-inset-0 tw-bg-black/50 dark:tw-bg-black/70"
    (click)="dismiss()"
    [@backdropAnimation]></div>

  <!-- Drawer Content -->
  <div
    class="tw-relative tw-w-full tw-max-w-6xl tw-bg-white tw-shadow-lg tw-shadow-gray-700 dark:tw-shadow-gray-600 tw-flex tw-flex-col tw-border-l tw-border-gray-200 dark:tw-bg-gray-900 tw-p-4 tw-pb-0"
    [@drawerAnimation]
    role="dialog"
    aria-modal="true">
    <!-- header -->
    <div
      class="tw-flex tw-justify-between tw-items-center tw-text-gray-800 dark:tw-text-gray-200 tw-mb-4">
      <h2 class="tw-font-semibold tw-text-xl tw-my-1">{{ title }}</h2>
      <button mat-icon-button (click)="dismiss()" aria-label="Close drawer">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <!-- STEP 1: Filters -->
    <div *ngIf="mode=='filters'" class="tw-flex-1 tw-overflow-y-auto">
      <app-filters
        [setQuickBuild]="quickBuild"
        embedded="true"
        [selectedTab]="filtersSelectedTab"></app-filters>
    </div>

    <!-- STEP 2: Data Selection -->
    <div *ngIf="mode=='data-selection'" class="tw-flex-1 tw-overflow-y-auto">
      <!-- info message -->
      <app-alert
        [alertType]="alertType.Info"
        [closeable]="true"
        class="tw-mb-6"
        [hasBorder]="false">
        <div class="tw-text-sm">
          Data purchased here, as well as your subscription information, will become available on
          the
          <a
            [routerLink]="getDownloadsUrl()"
            class="tw-text-blue-500 tw-font-medium hover:tw-underline">
            Downloads
          </a>
          page.
        </div>
      </app-alert>

      <!-- available credits -->
      <div class="tw-mb-6">
        <h3 class="tw-text-lg tw-font-semibold tw-text-gray-700 dark:tw-text-gray-300 tw-my-0">
          Available Credits
        </h3>

        <div class="tw-ml-1 tw-mt-2">
          <app-credit-usage-indicator [displayMode]="'detailed'"></app-credit-usage-indicator>
        </div>
      </div>

      <!-- choose your data access -->
      <div class="tw-mb-4">
        <h3 class="tw-text-lg tw-font-semibold tw-text-gray-700 dark:tw-text-gray-300 tw-my-0">
          Choose Your Data Access
        </h3>
        <p class="tw-mt-1 tw-text-sm tw-text-gray-600 dark:tw-text-gray-400">
          Based on your filters, you can download current records or subscribe to updates.
          <span class="tw-font-medium">You can select one or both options.</span>
        </p>
      </div>

      <div class="tw-space-y-6 tw-mb-4 tw-ml-1 tw-mt-2">
        <!-- Download Option -->
        <mat-card
          class="tw-border tw-border-solid "
          [ngClass]="selectedOptions.download ? 'tw-border-link-color-dynamic-darker' : 'tw-border-gray-200 dark:tw-border-gray-700'">
          <mat-card-content class="tw-p-4">
            <div class="tw-flex tw-items-start">
              <mat-checkbox
                [checked]="selectedOptions.download"
                (change)="onOptionSelect('download', $event.checked)"
                class="tw-mt-1">
              </mat-checkbox>

              <div class="tw-ml-6 tw-flex-1">
                <h4
                  class="tw-text-lg tw-font-medium tw-text-gray-700 dark:tw-text-gray-300 tw-my-0">
                  Download Current Records
                </h4>
                <p class="tw-mt-2 tw-text-gray-600 dark:tw-text-gray-400">
                  Get immediate access to {{matchingRecords}} matching professionals
                </p>

                <div class="tw-mt-6 tw-flex tw-justify-between tw-items-center">
                  <div>
                    <span class="tw-text-xl tw-font-semibold tw-text-blue-600 tw-mr-1">
                      100 credits
                    </span>
                    <span class="tw-text-sm tw-italic tw-text-gray-400">
                      (you have xxx credits remaining)
                    </span>
                  </div>
                  <span class="tw-text-sm tw-text-gray-500">One-time payment</span>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <!-- Subscribe Option -->
        <mat-card
          class="tw-border tw-border-solid"
          [ngClass]="selectedOptions.subscribe ? 'tw-border-link-color-dynamic-darker' : 'tw-border-gray-200 dark:tw-border-gray-700'">
          <mat-card-content class="tw-p-4">
            <div class="tw-flex tw-items-start">
              <mat-checkbox
                [checked]="selectedOptions.subscribe"
                (change)="onOptionSelect('subscribe', $event.checked)"
                class="tw-mt-1">
              </mat-checkbox>

              <div class="tw-ml-6 tw-flex-1">
                <h4
                  class="tw-text-lg tw-font-medium tw-text-gray-700 dark:tw-text-gray-300 tw-my-0">
                  Subscribe to Updates
                </h4>
                <p class="tw-mt-2 tw-text-gray-600 dark:tw-text-gray-400">
                  Get notified when new professionals match your filters
                </p>

                <div class="tw-mt-6 tw-flex tw-justify-between tw-items-center">
                  <span class="tw-text-xl tw-font-semibold tw-text-blue-600">
                    10 credits
                    <span class="tw-text-sm tw-text-gray-600 dark:tw-text-gray-400"> /month </span>
                  </span>
                  <span class="tw-text-sm tw-text-gray-500">Cancel anytime</span>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- Warning message when no options selected -->
      <app-alert
        *ngIf="!hasSelectedOption()"
        [alertType]="alertType.Warning"
        [closeable]="true"
        class="tw-mb-6"
        [hasBorder]="false">
        <div class="tw-text-sm">Please select at least one option to continue.</div>
      </app-alert>
    </div>

    <!-- Sticky Footer -->
    <div
      class="tw-p-4 tw-bg-white dark:tw-bg-gray-900 tw-flex tw-shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.2)] dark:tw-shadow-[0_-4px_6px_-1px_rgba(68,68,68,0.3)] tw-relative tw-mt-1 -tw-mx-4"
      [ngClass]="{'tw-justify-between': mode=='data-selection', 'tw-justify-center': mode=='filters'}">
      <!-- Back button (only visible in data-selection mode) -->
      <button *ngIf="mode=='data-selection'" mat-button (click)="goBack()">
        <span class="tw-flex tw-items-center tw-gap-2 tw-font-semibold">
          <mat-icon class="tw-text">arrow_back</mat-icon>
          <span>Back to Filters</span>
        </span>
      </button>

      <!-- Continue/Finalize button -->
      <!-- <button
        class="tw-text-white tw-shrink-0 tw-relative tw-inline-flex tw-items-center tw-justify-center tw-overflow-hidden tw-text-sm tw-rounded-md tw-bg-gradient-to-br tw-from-purple-600 tw-to-blue-600 hover:tw-from-blue-600 hover:tw-to-purple-600 focus:tw-outline-none focus:tw-ring-blue-500 tw-transition-all tw-p-0.5"
        [disabled]="mode=='data-selection' && !hasSelectedOption()"
        [ngClass]="{'tw-opacity-50 tw-cursor-not-allowed': mode=='data-selection' && !hasSelectedOption()}"
        (click)="finalize()">
        <span class="tw-relative tw-px-5 tw-py-2 tw-rounded-md tw-font-semibold">
          <ng-container *ngIf="mode=='filters'"> Finalize Data and Continue </ng-container>
          <ng-container *ngIf="mode=='data-selection'"> Complete Purchase </ng-container>
        </span>
      </button> -->
    </div>
  </div>
</div>
