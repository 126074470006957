import { Component, Inject, InjectionToken, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { QueryDwRequest, Company } from 'ldt-dw-reader-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { SearchService } from 'ldt-dw-reader-service-api';
import { forkJoin, Observable } from 'rxjs';
import { CompanyInfoService } from 'src/app/shared/company-search/company-info-service.service';
export interface EditCompanyFieldConfig {
  fieldName: string;
  fieldKey: keyof Company;
  title: string;
  forceUpperCase?: boolean;
}
export const COMPANY_FIELD_CONFIG = new InjectionToken<EditCompanyFieldConfig>(
  'COMPANY_FIELD_CONFIG'
);

@Component({
  selector: 'app-edit-company-identifier-base',
  template: '',
  styleUrls: ['./edit-company-identifier-base.component.scss'],
})
export abstract class EditCompanyIdentifierBaseComponent implements OnInit {
  @Input() company!: Company;

  currentValueCompanies: Company[] = [];
  newValueCompanies: Company[] = [];
  loading: boolean = true;
  saving: boolean = false;
  inputForm: FormGroup;

  constructor(
    protected dialogRef: MatDialogRef<any>,
    protected dwService: SearchService,
    protected fb: FormBuilder,
    protected notify: NotificationService,
    protected companyInfoService: CompanyInfoService,
    @Inject(COMPANY_FIELD_CONFIG) protected config: EditCompanyFieldConfig
  ) {
    this.inputForm = this.fb.group({
      newValue: ['', [Validators.required, Validators.minLength(1)]],
    });
  }

  ngOnInit(): void {
    // Combined subscription for case conversion and company lookup
    this.inputForm.get('newValue')?.valueChanges.subscribe((value) => {
      if (value) {
        let processedValue = value;
        if (this.config.forceUpperCase) {
          processedValue = value.toUpperCase();
          if (processedValue !== value) {
            this.inputForm.patchValue({ newValue: processedValue }, { emitEvent: false });
          }
        }

        this.findCompaniesWithValue(processedValue).then((companies) => {
          this.newValueCompanies = companies.filter((c) => c.id !== this.company.id);
        });
      } else {
        this.newValueCompanies = [];
      }
    });
  }

  protected loadData() {
    this.loading = true;
    const currentValue = this.company[this.config.fieldKey];
    this.inputForm.patchValue({ newValue: currentValue || '' });

    if (currentValue) {
      this.findOtherCompaniesWithValue(currentValue as string);
    } else {
      this.loading = false;
    }
  }

  private findCompaniesWithValue(value: string): Promise<Company[]> {
    const query = {
      query: {
        term: {
          [`${this.config.fieldKey}.keyword`]: value,
        },
      },
      size: 1000,
    };

    const req: QueryDwRequest = {
      query: query,
      index: 'companies',
    };

    return new Promise((resolve) => {
      this.dwService.queryDw(req).subscribe({
        next: (res: any) => {
          resolve(res.hits.hits.map((hit: any) => hit._source));
        },
        error: (err) => {
          console.error(`Error finding companies with ${this.config.fieldName}:`, err);
          resolve([]);
        },
      });
    });
  }

  private findOtherCompaniesWithValue(value: string) {
    const query = {
      query: {
        bool: {
          must: [
            { term: { [`${this.config.fieldKey}.keyword`]: value } },
            { bool: { must_not: { term: { 'id.keyword': this.company.id } } } },
          ],
        },
      },
      size: 1000,
    };

    const req: QueryDwRequest = {
      query: query,
      index: 'companies',
    };

    this.dwService.queryDw(req).subscribe({
      next: (res: any) => {
        this.currentValueCompanies = this.newValueCompanies = res.hits.hits.map(
          (hit: any) => hit._source
        );
        this.loading = false;
      },
      error: (err) => {
        this.notify.error(`Failed to find other companies with the same ${this.config.fieldName}`);
        this.loading = false;
      },
    });
  }

  removeValue() {
    this.saving = true;

    this.updateCompanyField(this.company, null).subscribe({
      next: () => {
        this.notify.success(`${this.config.fieldName} removed successfully`);
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.notify.error(`Failed to remove ${this.config.fieldName}`);
      },
      complete: () => {
        this.saving = false;
      },
    });
  }

  removeFromOthers() {
    if (!this.inputForm.valid) return;

    this.saving = true;
    const requests = this.newValueCompanies.map((company) =>
      this.updateCompanyField(company, null)
    );

    forkJoin(requests).subscribe({
      next: () => {
        this.notify.success(`${this.config.fieldName} removed from other companies`);
        this.dialogRef.close(true);
      },
      error: (err) => {
        this.notify.error(`Failed to remove ${this.config.fieldName} from other companies`);
      },
      complete: () => {
        this.saving = false;
      },
    });
  }

  updateValueAndClearOthers() {
    if (!this.inputForm.valid) return;

    this.saving = true;
    try {
      const newValue = this.inputForm.get('newValue')?.value;
      if (!newValue) {
        this.notify.error(`New ${this.config.fieldName} is required`);
        return;
      }

      // First clear the value from other companies that have it
      for (const company of this.newValueCompanies) {
        this.updateCompanyField(company, null).subscribe({
          next: () => {
            this.notify.success(`${this.config.fieldName} removed from other companies`);
          },
          error: (err) => {
            this.notify.error(`Failed to remove ${this.config.fieldName} from other companies`);
          },
        });
      }

      // Then set the new value on this company
      this.updateCompanyField(this.company, newValue).subscribe({
        next: () => {
          this.notify.success(`${this.config.fieldName} updated and cleared from other companies`);
          this.dialogRef.close(true);
        },
        error: (err) => {
          this.notify.error(`Failed to update ${this.config.fieldName}`);
        },
        complete: () => {
          this.saving = false;
        },
      });
    } catch (err) {
      this.notify.success(`${this.config.fieldName} updated and cleared from other companies`);
      this.dialogRef.close(true);
    } finally {
      this.saving = false;
    }
  }

  updateValueOnly() {
    if (!this.inputForm.valid) return;

    this.saving = true;
    try {
      const newValue = this.inputForm.get('newValue')?.value;
      if (!newValue) {
        this.notify.error(`New ${this.config.fieldName} is required`);
        return;
      }
      this.updateCompanyField(this.company, newValue).subscribe({
        next: () => {
          this.notify.success(`${this.config.fieldName} updated successfully`);
          this.dialogRef.close(true);
        },
        error: (err) => {
          this.notify.error(`Failed to update ${this.config.fieldName}`);
        },
        complete: () => {
          this.saving = false;
        },
      });
    } catch (err) {
      this.notify.error(`Failed to update ${this.config.fieldName}`);
    } finally {
      this.saving = false;
    }
  }

  protected abstract updateCompanyField(company: Company, value: string | null): Observable<void>;

  close() {
    this.dialogRef.close();
  }
}
