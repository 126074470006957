import { ColDef, ColGroupDef } from 'ag-grid-community';

export const defaultColDef: ColDef = {
  sortable: true,
  resizable: true,
  flex: 1,
  minWidth: 100,
  suppressMenu: true,
  cellStyle: {
    cursor: 'pointer',
  },
  cellClass: 'ag-cell-hover',
};

export const columnDefs: (ColDef | ColGroupDef)[] = [
  { field: 'created_at' },
  { field: 'user_email' },
  { field: 'first_prompt' },
  { field: 'quickbuild_count' },
  { field: 'total_people_count' },
  { field: 'prompt_count' },

  // make this table highlight each cell, when hovered show pointer and highlight row, when clicked update url
];
