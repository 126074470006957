<div *ngIf="vizInfo.type !== null" [ngSwitch]="vizInfo.type">
  <app-reports-demographics
    *ngSwitchCase="VIZ_TYPES.DEMOGRAPHICS"
    [quickBuild]="quickBuild"
    [reportTarget]="vizInfo.reportTarget"
    [dateFrom]="vizInfo.params.date_from"
    [dateTo]="vizInfo.params.date_to"
    [field]="vizInfo.params.field"
    [allowFieldSelection]="false">
  </app-reports-demographics>

  <app-reports-demographics-current
    *ngSwitchCase="VIZ_TYPES.DEMOGRAPHICS_CURRENT"
    [quickBuild]="quickBuild"
    [reportTarget]="vizInfo.reportTarget"
    [field]="vizInfo.params.field"
    [allowFieldSelection]="false">
  </app-reports-demographics-current>

  <app-reports-tenure-current-breakdown
    *ngSwitchCase="VIZ_TYPES.TENURE_CURRENT_BREAKDOWN"
    [quickBuild]="quickBuild"
    [reportTarget]="vizInfo.reportTarget">
  </app-reports-tenure-current-breakdown>

  <app-reports-functional-distribution
    *ngSwitchCase="VIZ_TYPES.FUNCTIONAL_DISTRIBUTION"
    [quickBuild]="quickBuild"
    [reportTarget]="vizInfo.reportTarget">
  </app-reports-functional-distribution>

  <app-reports-map-us-states
    *ngSwitchCase="VIZ_TYPES.MAP_US_STATES"
    [quickBuild]="quickBuild"
    [reportTarget]="vizInfo.reportTarget">
  </app-reports-map-us-states>

  <app-reports-job-starts-and-ends
    *ngSwitchCase="VIZ_TYPES.JOB_STARTS_AND_ENDS"
    [quickBuild]="quickBuild"
    [field]="vizInfo.params.field"
    [reportTarget]="vizInfo.reportTarget"
    [dateFrom]="vizInfo.params.date_from"
    [dateTo]="vizInfo.params.date_to"
    [allowFieldSelection]="false">
  </app-reports-job-starts-and-ends>

  <div *ngSwitchDefault>Unsupported visualization type: {{vizInfo.type}}</div>
</div>
