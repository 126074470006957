import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';

import { NotificationService } from './notification-service/notification.service';
import { FilterPipe } from './pipes/filter.pipe';
import { CustomStepperComponent } from './stepper/stepper.component';
import { TestStatusBadgeComponent } from './test-status-badge/test-status-badge.component';
import { JobFunctionDropdownComponent } from './job-function-dropdown/job-function-dropdown.component';
import { BadgeComponent } from './badge/badge.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DateRangePickerCustomRangePanelComponent } from './date-range-picker/custom-range-panel/custom-range-panel.component';
import { SavedListsComponent } from './saved-lists/saved-lists.component';
import { JobLevelDropdownComponent } from './job-level-dropdown/job-level-dropdown.component';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DarkModeToggleComponent } from './dark-mode-toggle/dark-mode-toggle.component';
import { SideImgDecorationComponent } from './side-img-decoration/side-img-decoration.component';
import { CreditUsageIndicatorComponent } from './credit-usage-indicator/credit-usage-indicator.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { CreditUsageService } from './credit-usage-indicator/credit-usage-service.service';
import { AlertComponent } from './alert/alert.component';
import { JobStartsAndEndsComponent } from './report-visualizations/job-starts-and-ends/job-starts-and-ends.component';
import { DemographicsComponent } from './report-visualizations/demographics/demographics.component';
import { HeadcountComparisonComponent } from './report-visualizations/headcount-comparison/headcount-comparison.component';
import { DemographicsCurrentComponent } from './report-visualizations/demographics-current/demographics-current.component';
import { TenureCurrentBreakdownComponent } from './report-visualizations/tenure-current-breakdown/tenure-current-breakdown.component';
import { MapUsStatesComponent } from './report-visualizations/map-us-states/map-us-states.component';
import { FunctionalDistributionComponent } from './report-visualizations/functional-distribution/functional-distribution.component';
import { PrevNextFlowComponent } from './report-visualizations/prev-next-flow/prev-next-flow.component';
import { VizFromQuickbuildFactoryComponent } from './report-visualizations/viz-from-quickbuild-factory/viz-from-quickbuild-factory.component';
import { ChatDialogComponent } from './chat-dialog/chat-dialog.component';
import { MarkdownModule } from 'ngx-markdown';

@NgModule({
  declarations: [
    FilterPipe,
    CustomStepperComponent,
    TestStatusBadgeComponent,
    JobFunctionDropdownComponent,
    JobLevelDropdownComponent,
    BadgeComponent,
    DateRangePickerComponent,
    DateRangePickerCustomRangePanelComponent,
    SavedListsComponent,
    DarkModeToggleComponent,
    SideImgDecorationComponent,
    CreditUsageIndicatorComponent,
    ClickOutsideDirective,
    AlertComponent,
    JobStartsAndEndsComponent,
    DemographicsComponent,
    HeadcountComparisonComponent,
    DemographicsCurrentComponent,
    TenureCurrentBreakdownComponent,
    MapUsStatesComponent,
    FunctionalDistributionComponent,
    PrevNextFlowComponent,
    VizFromQuickbuildFactoryComponent,
    ChatDialogComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatMenuModule,
    MatButtonToggleModule,
    MarkdownModule,
  ],
  exports: [
    ReactiveFormsModule,
    FilterPipe,
    CustomStepperComponent,
    TestStatusBadgeComponent,
    JobFunctionDropdownComponent,
    JobLevelDropdownComponent,
    BadgeComponent,
    MatIconModule,
    DateRangePickerComponent,
    DarkModeToggleComponent,
    SideImgDecorationComponent,
    CreditUsageIndicatorComponent,
    ClickOutsideDirective,
    AlertComponent,
    JobStartsAndEndsComponent,
    DemographicsComponent,
    HeadcountComparisonComponent,
    DemographicsCurrentComponent,
    TenureCurrentBreakdownComponent,
    MapUsStatesComponent,
    FunctionalDistributionComponent,
    PrevNextFlowComponent,
    VizFromQuickbuildFactoryComponent,
    ChatDialogComponent,
  ],
  providers: [NotificationService, CreditUsageService],
})
export class SharedModule {}
