<!-- action btns -->
<div class="tw-flex tw-gap-4 tw-flex-row tw-items-center tw-my-4 tw-ml-[.1rem]">
  <button
    mat-raised-button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    (click)="refreshOrgInvitations()">
    Refresh
  </button>
</div>

<!-- ag-grid table -->
<div class="tw-w-full tw-overflow-x-auto">
  <div class="tw-min-w-[1000px] tw-w-full">
    <ag-grid-angular
      style="width: 100%;"
      [ngClass]="{ 'ag-theme-quartz': !(darkModeService.darkMode$ | async), 'ag-theme-quartz-dark': darkModeService.darkMode$ | async }"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [components]="components"
      [rowSelection]="rowSelection"
      [defaultColDef]="defaultColDef"
      [tooltipShowDelay]="tooltipShowDelay"
      enableCellTextSelection="true"
      ensureDomOrder="true"
      domLayout="autoHeight"
      (gridReady)="onGridReady($event)"
      (selectionChanged)="onSelectionChanged($event)">
    </ag-grid-angular>
  </div>
</div>
