<div class="content-title tw-flex-wrap tw-items-center tw-mt-4">
  <h1 class="tw-text-2xl tw-font-bold tw-my-0">{{org.name}} Team</h1>

  <!-- Org ID -->
  <div
    (click)="onIdClick()"
    class="tw-text-base tw-text-dark-light-dynamic dark:tw-bg-gray-800 badge-md-light tw-flex tw-items-center tw-cursor-copy">
    ID:
    <span
      class="font-code-pro tw-text-sm tw-rounded tw-px-1 tw-py-0.5 tw-border tw-border-gray-200 tw-select-all">
      {{org.id}}
    </span>
    <mat-icon
      class="tw-text-gray-500 dark:tw-text-gray-300 tw-cursor-pointer tw-text-sm tw-w-[unset] tw-h-[unset]">
      content_copy
    </mat-icon>
  </div>

  <button
    mat-raised-button
    color="primary"
    class="button tw-shrink-0 tw-ml-auto"
    (click)="showInviteDialog()">
    <div class="invite-button-text tw-flex tw-items-center tw-gap-2 tw-max-w-32 md:tw-max-w-none">
      <mat-icon class="tw-hidden md:tw-block">add</mat-icon>
      Invite Users
    </div>
  </button>
</div>
<div id="team-tabs" class="team-tabs">
  <mat-tab-group class="mat-tab-fill-height">
    <mat-tab label="Users">
      <app-team-users [canAdmin]="canAdmin" [org]="org"></app-team-users>
    </mat-tab>
    <mat-tab label="Invitations">
      <ng-template matTabContent>
        <app-team-invitations [canAdmin]="canAdmin" [org]="org"></app-team-invitations>
      </ng-template>
    </mat-tab>
    <mat-tab label="Service Accounts">
      <ng-template matTabContent>
        <app-team-service-accounts [canAdmin]="canAdmin" [org]="org"></app-team-service-accounts>
      </ng-template>
    </mat-tab>
    <mat-tab label="API Keys" *ngIf="canAdmin || org.settings?.people.capability">
      <ng-template matTabContent>
        <app-team-api-keys [canAdmin]="canAdmin" [org]="org"></app-team-api-keys>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
