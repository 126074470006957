import { Component, Input, OnDestroy, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { timer, Subscription } from 'rxjs';
import { Log } from 'src/app/logger.service';
import {
  QuickBuild,
  UniqueIdGenerator,
  QuickBuildService,
} from 'src/app/people/quick-build.service';
import { PeopleReportService } from 'src/app/people/report-service.service';

@Component({
  template: '',
})
export abstract class BaseReportComponent implements OnDestroy {
  @Input() quickBuild: QuickBuild;
  @Input() reportTarget: string;
  @Input() reportId: string;

  protected chart: Highcharts.Chart;
  protected chartId: string;
  protected subscriptions: Subscription = new Subscription();

  protected abstract readonly CHART_ID: string;

  constructor(
    protected reportService: PeopleReportService,
    protected quickBuildService: QuickBuildService
  ) {
    this.chartId = UniqueIdGenerator.generate();
    if (!this.reportId) {
      this.reportId = UniqueIdGenerator.generate();
    }
    // this.validateReportTypeToChartId();
  }

  ngAfterViewInit(): void {
    // timer(100).subscribe(() => {
    requestAnimationFrame(() => {
      try {
        this.initializeChart();
      } catch (error) {
        timer(100).subscribe(() => {
          this.initializeChart();
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    Log.d('changes', changes);

    if (this.shouldRefreshOnChanges(changes)) {
      this.resetChartData();
      this.getChartData();
    }
  }

  protected abstract initializeChart(): void;
  protected abstract generateChartSeries(...args: any[]): any;
  protected abstract getChartData(): void;

  protected shouldRefreshOnChanges(changes: SimpleChanges): boolean {
    // Default implementation - override in child components if needed
    return 'quickBuild' in changes || 'reportTarget' in changes || 'reportId' in changes;
  }

  protected resetChartData() {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    this.removeAllSeriesFromChart();
  }

  protected removeAllSeriesFromChart() {
    if (!this.chart) return;
    while (this.chart.series.length > 0) {
      this.chart.series[0].remove(false);
    }
  }

  protected updateChart(series: any[], categories?: any[], title?: string | undefined) {
    const valueToSet: Highcharts.Options = {
      series,
      xAxis: categories ? { categories } : undefined,
    };
    if (title) {
      valueToSet.title = { text: title };
    }
    if (!this.chart) {
      // If chart isn't ready, retry up to 3 times with increasing delays
      let attempts = 0;
      const maxAttempts = 3;

      const attemptUpdate = () => {
        timer(attempts * 100).subscribe(() => {
          // Increasing delay: 0ms, 100ms, 200ms
          if (this.chart) {
            this.chart.hideLoading();
            this.chart.update(valueToSet, true, true, true);
          } else if (attempts < maxAttempts) {
            attempts++;
            attemptUpdate();
          } else {
            console.warn(
              `Failed to update chart after ${maxAttempts} attempts - chart not initialized`
            );
          }
        });
      };

      attemptUpdate();
      return;
    }

    // Chart is ready, update immediately
    this.chart.hideLoading();
    this.chart.update(valueToSet, true, true, true);
  }

  // private validateReportTypeToChartId(): void {
  //   if (!this.quickBuild) {
  //     return;
  //   }
  //   if (!this.reportId) {
  //     throw new Error('Report ID is required');
  //   }

  //   const reportInfo = this.quickBuildService.getReportById(this.quickBuild, this.reportId);
  //   if (!reportInfo) {
  //     throw new Error(`Report with ID ${this.reportId} not found`);
  //   }

  //   const [report, _] = reportInfo;

  //   // if (report.type !== this.CHART_ID) {
  //   //   throw new Error(`Report type ${report.type} does not match chart ID ${this.CHART_ID}`);
  //   // }
  // }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
