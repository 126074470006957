import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnInit,
} from '@angular/core';
import { UIFilter, UIFilterGroup } from 'src/app/people/quick-build.service';
import { ColumnCategory } from 'src/app/data-warehouse/dw-column';
import { getPeopleColumnByName } from '../../people-columns';

@Component({
  selector: 'app-filter-chips',
  templateUrl: './filter-chips.component.html',
  styleUrls: ['./filter-chips.component.scss'],
})
export class FilterChipsComponent implements OnChanges, OnInit {
  @Input() rootFilterGroup: UIFilterGroup;
  @Input() showFilters: boolean;
  @Input() categories: ColumnCategory[] = [];
  @Input() chipsOnly: boolean = false; // only show chips, not filter group

  @Output() filterAdded = new EventEmitter<UIFilter>();
  @Output() filterRemoved = new EventEmitter<UIFilter>();

  // This will hold the grouped filters to display in the template to prevent re-calculating it on every change detection cycle
  displayedGroupedFilters: [string, [UIFilter, string][]][] | null = null;

  ngOnInit(): void {
    if (!this.showFilters) {
      this.displayedGroupedFilters = this.getGroupedFilters();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // only recalc if showFilters is false
    if (changes['rootFilterGroup'] || changes['showFilters']) {
      this.displayedGroupedFilters = this.getGroupedFilters();
    }
  }

  // Group filters by their category
  getGroupedFilters(): [string, [UIFilter, string][]][] {
    const flatFilters = this.flattenFilters(this.rootFilterGroup);

    const grouped = flatFilters.reduce(
      (acc, filter) => {
        const col = getPeopleColumnByName(filter.filter.field!);
        const category = col.group;
        if (!category) {
          return acc;
        }
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push([filter, col.displayName]);
        return acc;
      },
      {} as Record<string, [UIFilter, string][]>
    );

    return Object.entries(grouped); // convert grouped object to array
  }

  /**
   * Retrieves the display name of a category based on its group name.
   * @param groupName - The group name of the category.
   * @returns The display name of the category (string), or undefined if no matching category is found.
   */
  getCategoryDisplayName(groupName: string) {
    return this.categories.find((category) => category.name === groupName)?.displayName;
  }

  trackByGroupName(index: number, group: [string, [UIFilter, string][]]): string {
    return group[0]; // category name (first item in the tuple) as the unique identifier
  }

  // helper to add appropriate symbol and tooltip to filter chips with the same text value
  // TODO: add more filter types for boolean, number, date, etc.
  getFilterSymbol(filter: UIFilter): { symbol: string; tooltip: string } {
    const type = filter.filter.type;
    const matchType = filter.filter.match_type;

    switch (type) {
      case 'must':
        if (matchType === 'fuzzy') {
          return { symbol: '≈', tooltip: 'Matches (fuzzy)' };
        } else if (matchType === 'exists') {
          return { symbol: '✓', tooltip: 'Not blank' };
        } else {
          return { symbol: '=', tooltip: 'Equals' };
        }
      case 'must_not':
        if (matchType === 'fuzzy') {
          return { symbol: '≉', tooltip: 'Does not match (fuzzy)' };
        } else if (matchType === 'exists') {
          return { symbol: '∅', tooltip: 'Blank' };
        } else {
          return { symbol: '≠', tooltip: 'Does not equal' };
        }
      default:
        return { symbol: '', tooltip: '' };
    }
  }

  isFilterNameRepeated(currentFilter: UIFilter, groupFilters: [UIFilter, string][]): boolean {
    const filtersWithName = groupFilters.filter(
      (filter) => filter[0].filter.field === currentFilter.filter.field
    );
    return filtersWithName.length > 1;
  }

  private flattenFilters(group: UIFilterGroup): UIFilter[] {
    let flatFilters: UIFilter[] = [];
    for (const filter of group.filters) {
      if (this.isUIFilter(filter)) {
        flatFilters.push(filter);
      } else {
        flatFilters = flatFilters.concat(this.flattenFilters(filter));
      }
    }
    return flatFilters;
  }

  private isUIFilter(filter: UIFilter | UIFilterGroup): filter is UIFilter {
    return 'filter' in filter;
  }

  hasFilters(group: UIFilterGroup): boolean {
    return group.filters.some((f) => this.isUIFilter(f) || (f as UIFilterGroup).filters.length > 0);
  }
}
