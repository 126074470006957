import { Component, Input } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import * as Highcharts from 'highcharts';
import { reportUSMapOptions } from '../highcharts-options';
import { QuickBuildService } from 'src/app/people/quick-build.service';
import { PeopleReportService } from 'src/app/people/report-service.service';
import { getPeopleColumnByName } from 'src/app/people/people-columns';
import { MoneyballService } from 'src/app/moneyball/moneyball.service';
import { BaseReportComponent } from '../base-report.component';
import { Log } from 'src/app/logger.service';

var us_map = require('@highcharts/map-collection/countries/us/us-all.topo.json');
import HC_map from 'highcharts/modules/map';
HC_map(Highcharts);

const FIELDS_TO_GROUP_BY = ['jobs.function', 'jobs.level'];
const REPORT_NAME = 'demographics';

@Component({
  selector: 'app-reports-map-us-states',
  templateUrl: './map-us-states.component.html',
  styleUrls: ['./map-us-states.component.scss'],
})
export class MapUsStatesComponent extends BaseReportComponent {
  @Input() field: string;
  breakdownByOptions = FIELDS_TO_GROUP_BY;
  selectedBreakdownBy: (typeof FIELDS_TO_GROUP_BY)[number] = FIELDS_TO_GROUP_BY[0];

  protected readonly CHART_ID = 'map-us-states';

  constructor(
    reportService: PeopleReportService,
    private mbService: MoneyballService,
    quickBuildService: QuickBuildService
  ) {
    super(reportService, quickBuildService);
  }

  protected initializeChart(): void {
    this.chart = Highcharts.mapChart(
      `highchartsContainer-${this.chartId}`,
      Highcharts.merge(reportUSMapOptions)
    );
    this.chart.update(
      { title: { text: '% Active US Employees' }, yAxis: { title: { text: '% of employees' } } },
      true,
      true
    );
  }

  protected getChartData(): void {
    if (!this.quickBuild) return;

    const reportParams = {
      group_by: ['location'],
    };
    const sub = this.reportService
      .getReportDataWithReportDetails(
        this.reportId,
        REPORT_NAME,
        reportParams,
        this.reportTarget,
        this.quickBuild
      )
      .subscribe((data) => {
        Log.d('demographicscurrent data', data);
        const [series] = this.generateChartSeries(
          data.count,
          data.data.demographics,
          this.selectedBreakdownBy
        );
        this.updateChart(series);
      });
    this.subscriptions.add(sub);
  }

  protected generateChartSeries(total: number, data: any[], key: string): any[] {
    Log.d('MapUsStatesComponent generateChartSeries', total, data);
    const counts = data
      .map((d) => {
        const st = this.mbService.LocationToState.find(
          (l: any) => l.key === d.group_values[0].value
        )?.state;
        let ret = { state: st || 'UNKNOWN', count: d.count_employees };
        return ret;
      })
      .filter((d: any) => d && d.state !== 'UNKNOWN')
      .reduce((r: any, i: any) => ((r[i.state] = (r[i.state] || 0) + i.count), r), {});
    const totalPoints: any = Object.values(counts).reduce((a: any, b: any) => a + b, 0);
    let processed: any[] = [];
    if (totalPoints) {
      processed = Object.keys(counts).map((k: any) => {
        return ['us-' + k.toLowerCase(), +((100 * counts[k]) / totalPoints).toFixed(2)];
      });
    }

    return [
      {
        name: 'Employee count',
        type: 'map',
        states: {
          // hover: {
          //   color: this.colors[1],
          // },
        },
        dataLabels: {
          enabled: true,
          format: '{point.name}',
        },
        data: processed,
      },
    ];
  }

  onBreakdownByChanged(value: MatButtonToggleChange) {
    if (!value) return;

    this.selectedBreakdownBy = value.value;
    this.resetChartData();
    this.getChartData();
  }

  getFieldDisplayName(field: string): string {
    return getPeopleColumnByName(field).displayName;
  }
}
