import { Component, OnInit, ViewChild } from '@angular/core';
import { ChatService, ConverseRequest, FeedbackRequest } from 'ldt-chat-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { QuickBuildSchema } from '../people/quick-build-validators';
import { MarkdownService } from 'ngx-markdown';
import { AuthService } from 'src/app/auth/service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { darkTheme } from 'src/app/shared/highcharts-themes';
import { StreamsSubscribeDrawerService } from 'src/app/shared/streams-subscribe-drawer/streams-subscribe-drawer.service';
import { Log } from 'src/app/logger.service';
import { QuickBuild, UniqueIdGenerator } from '../people/quick-build.service';
import { columnDefs, defaultColDef } from '../people/chat/ag-grid-options';
import { MatDrawerContent } from '@angular/material/sidenav';
import { SearchFilter } from 'ldt-people-api';
import { FilterGroup } from 'ldt-people-api';

interface Report {
  quickBuildId: string;
  reportId: string;
}

interface ChatMessage {
  type: 'user' | 'assistant';
  content?: string;
  quickBuilds?: QuickBuild[];
  isLoading?: boolean;
  followUpQuestions?: string[];
  charts?: {
    id: string;
    options: Highcharts.Options;
  }[];
  rated?: boolean;
  showFeedbackInput?: boolean;
  feedbackText?: string;
  isPositiveFeedback?: boolean;
  sections?: {
    content: string;
    reports?: Report[];
  }[];
}

export interface CompanyLookups {
  [key: string]: string; // company name: LDC ID string
}

@Component({
  selector: 'app-workforce',
  templateUrl: './workforce.component.html',
  styleUrls: ['./workforce.component.scss'],
})
export class WorkforceComponent implements OnInit {
  @ViewChild('drawerContent') drawerContent: MatDrawerContent;
  messages: ChatMessage[] = [];
  currentPrompt: string = '';
  threadId?: string;
  isLoading = false;
  orgId: string;
  Highcharts: typeof Highcharts = Highcharts;
  accuracyRating = 0;
  feedbackPlaceholder = 'What was wrong with the answer?';
  quickBuildForSubscription: QuickBuild;
  chatHistory: any[] = [];
  columnDefs = columnDefs;
  defaultColDef = defaultColDef;
  chatsRefreshing = true;
  historyDrawerOpen = false;
  quickBuildsInThread: QuickBuild[] = [];
  companyLookups: CompanyLookups | undefined;
  companyBattleQBs: QuickBuild[] = [];
  hasScrolled = false;
  sampleQuestions = [
    'Who hires the most people analytics titles in the US?',
    'Which US school produces the most Google employees?',
    'Is the product team at Netflix growing or shrinking year over year?',
    'Which US companies had the highest net gains of senior software engineers in the last 18 months?',
    'What is the role distribution in the telecom industry in the US?',
  ];

  constructor(
    private chatService: ChatService,
    private notify: NotificationService,
    private markdownService: MarkdownService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    public drawerService: StreamsSubscribeDrawerService
  ) {}

  showCharts = localStorage.getItem('showChatCharts') === 'true';

  ngOnInit() {
    // Force dark mode by adding tw-dark class to html element
    document.documentElement.classList.add('tw-dark');

    this.orgId = 'o_2abca1a0';
    this.getChats();

    this.route.queryParams.subscribe((params) => {
      if ('threadId' in params && params['threadId']) {
        this.resetChat();
        this.threadId = params['threadId'];
        this.initFromThreadId(this.threadId!);
      }
    });
    //subscribe to localstorage changes

    Highcharts.setOptions(darkTheme);

    setTimeout(() => {
      this.checkScroll(); // check for scroll position
    });
  }

  // check for scrolling so we can apply shadow to the drawer
  checkScroll() {
    if (this.drawerContent) {
      this.hasScrolled = this.drawerContent.getElementRef().nativeElement.scrollTop > 0;
    }
  }

  getChats() {
    this.chatsRefreshing = true;
    this.chatService.getChats(this.orgId).subscribe({
      next: (data: any) => {
        this.chatHistory = data.threads;
        this.chatsRefreshing = false;
      },
      error: (err: any) => {
        Log.e('Error getting chats:', err);
        this.chatsRefreshing = false;
      },
    });
  }

  sendMessage() {
    if (!this.currentPrompt?.trim()) return;

    // Add user message to chat
    this.messages.push({
      type: 'user',
      content: this.currentPrompt,
    });

    // Add placeholder for assistant response
    this.messages.push({
      type: 'assistant',
      content: '',
      isLoading: true,
    });

    this.isLoading = true;
    const req: ConverseRequest = {
      prompt: this.currentPrompt,
      thread_id: this.threadId,
    };

    this.chatService.converse(this.orgId, req).subscribe({
      next: (response: any) => {
        // Store threadId for continuation
        this.threadId = response.thread_id;

        // Poll for updates
        this.pollForUpdates(response.thread_id);
      },
      error: (err) => {
        this.isLoading = false;
        Log.e('Error in chat:', err);
        this.notify.error('Error connecting with chat. Please try again.');
        // Remove loading message
        this.messages.pop();
      },
    });

    this.currentPrompt = '';
  }

  private initFromThreadId(threadId: string) {
    this.messages = [];
    this.quickBuildsInThread = [];
    this.chatService.getChatThread(this.orgId, threadId).subscribe({
      next: (data: any) => {
        if (data.status !== 'idle') {
          this.notify.error('Chat still processing...');
          return;
        }
        this.processMessages(data.messages);
        this.processCompanyLookups(data.company_lookups);
        Log.d('companyLookups', this.companyLookups);
      },
      error: (err) => {
        if (err.status === 404) {
          this.notify.error('Chat thread not found');
          this.startNewChat();
        }
        Log.e('Error getting chat thread:', err);
      },
    });
  }

  private pollForUpdates(threadId: string) {
    const startTime = Date.now();
    const MAX_DURATION = 240000; // 4 minutes

    const poll = () => {
      // Check if we've exceeded max duration
      if (Date.now() - startTime > MAX_DURATION) {
        this.isLoading = false;
        this.notify.error('Chat timed out. Please try again.');
        return;
      }

      this.chatService.getChatThread(this.orgId, threadId).subscribe({
        next: (data: any) => {
          if (data.status === 'idle') {
            this.messages[this.messages.length - 1] = this.processAIMessage(
              data.messages[data.messages.length - 1]
            );
            this.isLoading = false;

            if (data.company_lookups) {
              this.processCompanyLookups(data.company_lookups);
            }

            return; // Stop polling
          }

          if (data.status === 'error') {
            this.notify.error(
              'The AI is having trouble with this question. Please try starting a new thread.'
            );
            this.isLoading = false;
            return; // Stop polling
          }

          if (data.company_lookups) {
            this.processCompanyLookups(data.company_lookups);
          }

          // Schedule next poll after 2 second delay
          setTimeout(poll, 2000);
        },
        error: (err) => {
          Log.e('Error polling updates:', err);
          this.isLoading = false;
        },
      });
    };

    // Start polling
    poll();
  }

  private processMessages(messages: any[]) {
    messages.forEach((m: any) => {
      if (m.role === 'user') {
        this.messages.push({
          type: 'user',
          content: m.content,
        });
      } else if (m.role === 'ai') {
        this.messages.push(this.processAIMessage(m));
      }
    });
  }

  // takes companyLookups from the chat response and converts it into a list of QuickBuilds if there are at least 2 companies
  private processCompanyLookups(companyLookups: CompanyLookups | undefined) {
    if (!companyLookups) {
      this.companyBattleQBs = [];
      this.companyLookups = undefined;
      return;
    }
    this.companyLookups = companyLookups;
    if (Object.keys(this.companyLookups).length >= 2) {
      this.companyBattleQBs = Object.entries(this.companyLookups).map(([key, value]) => {
        const qb: QuickBuild = {
          id: UniqueIdGenerator.generate(),
          name: key,
          rootFilterGroup: {
            id: UniqueIdGenerator.generate(),
            operator: FilterGroup.OperatorEnum.And,
            filters: [
              {
                filter: {
                  field: 'jobs_search',
                  match_type: SearchFilter.MatchTypeEnum.Exact,
                  string_values: [value as string],
                  type: SearchFilter.TypeEnum.Must,
                },
              },
            ],
          },
        };
        return qb;
      });
    }
  }

  private reportToLog(report: Report): string {
    return `${report.quickBuildId} (${report.reportId})`;
  }

  private processAIMessage(m: any): ChatMessage {
    Log.d('processAIMessage m', m);
    let reportLog: string[] = [];

    const charts: { id: string; options: Highcharts.Options }[] = [];

    // Process quickBuilds if they exist
    const quickBuilds = m.quickBuilds
      ? m.quickBuilds.map((q: any) => {
          const result = QuickBuildSchema.safeParse(q);
          if (!result.success) {
            Log.e('Invalid quickbuild schema:', result.error, q);
            return null;
          }
          return result.data as QuickBuild;
        })
      : [];

    this.quickBuildsInThread.push(...quickBuilds);

    // Extract follow-up questions if they exist
    const followUpQuestions = m.content.followUpQuestions
      ? m.content.followUpQuestions.map((q: any) => q.question)
      : [];

    // Make sure to preserve the reports in each section
    const sectionsWithReports = m.content.sections.map(
      (section: { content: string; reports?: Report[] }) => {
        // Only show each report once
        const newReports = section.reports
          ? section.reports.filter((r: Report) => !reportLog.includes(this.reportToLog(r)))
          : [];
        reportLog.push(...newReports.map((r: Report) => this.reportToLog(r)));
        return {
          content: section.content,
          reports: newReports,
        };
      }
    );

    Log.d('processAIMessage sectionsWithReports', sectionsWithReports);

    return {
      type: 'assistant' as 'assistant',
      sections: sectionsWithReports,
      followUpQuestions: followUpQuestions,
      quickBuilds: quickBuilds,
      charts: charts,
      rated: false,
    };
  }

  toggleShowCharts() {
    this.showCharts = !this.showCharts;
    localStorage.setItem('showChatCharts', this.showCharts.toString());
  }

  getQuickBuildById(id: string | number, qbs: QuickBuild[]) {
    const qb = qbs.find((qb) => qb.id === id);
    if (!qb) {
      return null;
    }
    return qb;
  }

  sendFollowUpOrSampleQuestion(question: string) {
    this.currentPrompt = question;
    this.sendMessage();
  }

  showFeedbackInput(message: ChatMessage, isPositive: boolean) {
    this.feedbackPlaceholder = isPositive
      ? 'Any additional feedback is helpful!'
      : 'What was wrong with the answer?';

    // Force change detection
    setTimeout(() => {
      message.showFeedbackInput = true;
      message.isPositiveFeedback = isPositive;
    }, 0);

    // Submit initial feedback immediately
    const req: FeedbackRequest = {
      action: isPositive ? 'thumbs_up' : 'thumbs_down',
    };
    this.chatService.feedback(this.orgId, this.threadId || 'unknown', req).subscribe({
      next: () => {
        // Nothing to do here
      },
      error: () => this.notify.error('Error submitting feedback'),
    });
  }

  submitFeedback(message: ChatMessage) {
    if (!this.threadId) return;

    message.rated = true;
    message.showFeedbackInput = false;
    this.notify.success('Thank you for your feedback!');

    const req = {
      accuracy: this.accuracyRating || 0,
      feedback: message.feedbackText || '',
      action: message.isPositiveFeedback ? 'thumbs_up' : 'thumbs_down',
    };

    this.chatService.feedback(this.orgId, this.threadId, req).subscribe({
      next: () => {
        // Nothing to do here
      },
      error: () => {
        this.notify.error('Error submitting feedback');
        message.rated = false;
        message.showFeedbackInput = true;
      },
    });
  }

  openDrawer(quickBuild: QuickBuild) {
    this.quickBuildForSubscription = quickBuild;
    this.drawerService.openDrawer();
  }

  openCompanyDrawer(companyName: string, companyId: string) {
    const qb: QuickBuild = {
      id: UniqueIdGenerator.generate(),
      name: companyName,
      rootFilterGroup: {
        id: companyId,
        operator: FilterGroup.OperatorEnum.And,
        filters: [
          {
            filter: {
              field: 'jobs_search',
              match_type: SearchFilter.MatchTypeEnum.Exact,
              string_values: [companyId],
              type: SearchFilter.TypeEnum.Must,
            },
          },
        ],
      },
    };
    this.quickBuildForSubscription = qb;
    this.drawerService.openDrawer('moneyball');
  }

  private resetChat() {
    this.messages = [];
    this.quickBuildsInThread = [];
    this.currentPrompt = '';
    this.threadId = undefined;
    this.isLoading = false;
    this.companyLookups = undefined;
    this.companyBattleQBs = [];
  }

  startNewChat() {
    this.resetChat();
    // remove the threadId query param to start a new chat
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {}, // remove query params
      queryParamsHandling: '',
    });
  }

  toggleHistoryDrawer() {
    this.historyDrawerOpen = !this.historyDrawerOpen;
    if (this.historyDrawerOpen) {
      this.getChats();
    }
  }
}
