import { Injectable } from '@angular/core';
import { DWColumn } from '../data-warehouse/dw-column';
import { PeopleColumns } from './people-columns';
import {
  CreateSearchReport,
  SearchService as DWSearchService,
  SearchReport,
} from 'ldt-dw-reader-service-api'; // TEMP TODO prototype quick builds
import { NotificationService } from '../shared/notification-service/notification.service';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/service/auth.service';
import {
  AggRequest,
  FilterGroup,
  FilterGroupFiltersInner,
  SearchFilter,
  SearchReportRequest,
} from 'ldt-people-api';
import { Log } from '../logger.service';

export interface QuickBuild {
  id: string;
  name: string;
  rootFilterGroup: UIFilterGroup;
  description?: string;
  reports?: any;
}

export interface UIFilter {
  filter: SearchFilter;
}

export interface UIReportRequest {
  name: string;
  params: any;
}

// params object for a report request
interface ReportParams {
  field?: string;
  group_by?: string[];
  [key: string]: any;
}

// The UI's representation of a filter group, including some UI-specific properties
export interface UIFilterGroup {
  operator: 'and' | 'or';
  filters: Array<UIFilter | UIFilterGroup>;
  isJobsGroup?: boolean;
  jobsGroupType?: 'any' | 'active' | 'ended';
  fieldsToChart?: FieldsToChart[];
  id?: string;
  name?: string;
  positionStatus?: PositionStatus;
  reports?: any;
}

// Where supported (root filter group and job groups), holds the user-selected fields to chart and the chart type
export interface FieldsToChart {
  columnName: string;
  chartType: ChartType;
}

export enum ChartType {
  Bar = 'bar',
  Line = 'line',
  Pie = 'pie',
}

export enum PositionStatus {
  All = 'all',
  First = 'first',
  Last = 'last',
  Promotion = 'promotion',
}

// A simple class to generate unique IDs for each filter group for this session
export class UniqueIdGenerator {
  public static generate(): string {
    const timestamp = Date.now();
    const randomStr = Math.random().toString(36).substring(2, 8);
    return `${timestamp}-${randomStr}`;
  }
}

@Injectable({
  providedIn: 'root',
})
export class QuickBuildService {
  private quickBuildsSource = new BehaviorSubject<QuickBuild[] | null>(null);
  private refreshing = new BehaviorSubject<boolean>(false);

  quickBuilds$ = this.quickBuildsSource.asObservable();
  refreshing$ = this.refreshing.asObservable();

  // While this is an internal-only service, we track employee status and don't do work unless they're an employee
  isEmployee = false;

  constructor(
    private notify: NotificationService,
    private dwReader: DWSearchService, // TEMP: TODO: prototype for quick builds
    private auth: AuthService
  ) {
    this.isEmployee = this.auth.isAdminValue;
    this.getQuickBuilds();
  }

  ngOnInit() {
    this.auth.$isAdmin.subscribe((isAdmin) => {
      this.isEmployee = isAdmin;
    });
  }

  public refresh() {
    this.getQuickBuilds();
  }

  private getQuickBuilds() {
    if (!this.isEmployee) {
      return;
    }

    this.refreshing.next(true);
    this.dwReader.getSearchReports().subscribe({
      next: (r: SearchReport[]) => {
        // Use this as a hack to find QBs in search reports
        const quickBuilds = r
          .filter((qb) => qb.parameters.includes('"quickBuild":true'))
          .map((qb) => {
            const qbFromParams = QuickBuildService.deserializeQuickBuild(qb.parameters);
            qbFromParams.id = String(qb.id);
            qbFromParams.name = qb.name;
            return qbFromParams;
          });

        // Cheating until we aren't casting SearchReports to QuickBuilds
        this.quickBuildsSource.next(quickBuilds); // For demo purposes
        this.refreshing.next(false);
      },
      error: () => {
        this.notify.error('Error getting saved searches for quick builds');
        this.refreshing.next(false);
      },
    });
  }

  getQuickBuildByName(name: string): QuickBuild | undefined {
    return this.quickBuildsSource.value?.find((qb) => qb.name === name);
  }

  createQuickBuild(qb: QuickBuild): void {
    const serializedQB = QuickBuildService.serializeQuickBuild(qb);
    let report: CreateSearchReport = {
      name: qb.name,
      parameters: serializedQB,
    };

    this.dwReader.createSearchReport(report).subscribe({
      next: () => {
        this.notify.success('New quick build saved');
        this.refresh();
      },
      error: () => {
        this.notify.error('Error saving quick build');
      },
    });
  }

  deleteQuickBuild(id: string): void {
    const idAsString = id.toString();
    this.dwReader.deleteSearchReport(idAsString).subscribe({
      next: () => {
        this.refresh();
      },
      error: () => {
        this.notify.error('Error deleting saved search');
      },
    });
  }

  // TODO (some day):
  // Improve serialization to add compression
  static serializeQuickBuild(qb: QuickBuild): string {
    const searchParams = {
      rootFilterGroup: qb.rootFilterGroup,
      quickBuild: true,
    };

    return JSON.stringify(searchParams);
  }

  // TODO (some day):
  // Improve deserialization to add schema validation
  static deserializeQuickBuild(serializedString: string): QuickBuild {
    let parsedParams = JSON.parse(serializedString);

    const qb: QuickBuild = {
      id: parsedParams.id || 0,
      name: parsedParams.name || '',
      rootFilterGroup: parsedParams.rootFilterGroup,
    };

    return qb;
  }

  // Collect all the report IDs in the provided QB. Can be used by components to
  // gather the reports for display using the viz gactory
  static getAllReportIDsInQuickbuildGrouped(quickBuild: QuickBuild): { [key: string]: string[] } {
    const reports: { [key: string]: string[] } = {};
    const searchFilterGroups = (group: UIFilterGroup): void => {
      if (group.reports) {
        group.reports.forEach((r: any) => {
          if (!reports[group.name || group.id || '']) {
            reports[group.name || group.id || ''] = [];
          }
          reports[group.name || group.id || ''].push(r.id);
        });
      }
      group.filters.forEach((filter) => {
        if ('filters' in filter) {
          searchFilterGroups(filter as UIFilterGroup);
        }
      });
    };
    searchFilterGroups(quickBuild.rootFilterGroup);
    return reports;
  }

  // Given a quick build and a report ID, return the report and the ID of the filter group
  // that contains it.
  static getReportById(
    quickBuild: QuickBuild,
    reportId: string
  ): [UIReportRequest, string] | undefined {
    // First check root level reports
    const rootReport = quickBuild.rootFilterGroup.reports?.find((r: any) => r.id === reportId);
    if (rootReport) {
      return [rootReport, quickBuild.rootFilterGroup.id || ''];
    }

    // Helper function to recursively search through filter groups
    const searchFilterGroups = (group: UIFilterGroup): [UIReportRequest, string] | undefined => {
      Log.d('quick-build.service getReportById searchFilterGroups', group, reportId);

      // Check reports in current group
      const report = group.reports?.find((r: any) => r.id === reportId);
      if (report) {
        return [report, group.id || ''];
      }

      // Search through nested filter groups
      for (const filter of group.filters) {
        if ('filters' in filter) {
          // This is a FilterGroup
          const nestedReport = searchFilterGroups(filter as UIFilterGroup);
          if (nestedReport) {
            return nestedReport;
          }
        }
      }
      return undefined;
    };

    return searchFilterGroups(quickBuild.rootFilterGroup);
  }

  // Create People API search filters from a root filter group and return the filters and aggs
  static rootFilterGroupToAPIFilters(
    rootFilterGroup: UIFilterGroup,
    reportIdToInclude: string | null = null
  ): [FilterGroup, AggRequest[]] {
    Log.d('rootFilterGroupToAPIFilters', rootFilterGroup, reportIdToInclude);
    const [filters, rootAggs] = QuickBuildService.filterGroupToAPIFilters(
      rootFilterGroup,
      reportIdToInclude
    );

    rootAggs.push(
      ...(rootFilterGroup.fieldsToChart?.map((f) => {
        return {
          id: 'root#' + f.columnName,
          field_name: f.columnName,
          interval: QuickBuildService.getDefaultIntervalForAgg(f.columnName),
          max_buckets: 50,
        };
      }) || [])
    );

    const topFilter: FilterGroup = {
      filters: filters,
      report: reportIdToInclude
        ? (() => {
            const report = rootFilterGroup.reports?.find((r: any) => r.id === reportIdToInclude);
            if (report) {
              return this.uiReportToAPIReport(report);
            }
            return undefined;
          })()
        : undefined,
    };
    Log.d('topFilter', topFilter);

    // If the top-level UI operator is an or, we need to wrap it since the top-level
    // filters only support and
    if (rootFilterGroup.operator === FilterGroup.OperatorEnum.Or) {
      topFilter.filters = [
        {
          operator: 'or',
          filters: filters,
        },
      ];
    }

    return [topFilter, rootAggs];
  }

  public static copyQuickBuild(qb: QuickBuild): QuickBuild {
    return JSON.parse(JSON.stringify(qb));
  }

  public static copyQuickBuildWithoutReports(qb: QuickBuild): QuickBuild {
    const copy = this.copyQuickBuild(qb);
    // recursively delete reports from all filter groups
    const deleteReports = (group: UIFilterGroup): void => {
      delete group.reports;
      group.filters.forEach((filter) => {
        if ('filters' in filter) {
          deleteReports(filter as UIFilterGroup);
        }
      });
    };
    deleteReports(copy.rootFilterGroup);
    return copy;
  }

  // Converts a UI report request to an API report request
  private static uiReportToAPIReport(
    uiReport: UIReportRequest, // the UI report request to convert
    changeJobsToPosition: boolean = false // transform 'jobs' fields to 'position' fields
  ): SearchReportRequest | undefined {
    if (!uiReport) {
      return undefined;
    }

    try {
      let params: ReportParams = JSON.parse(JSON.stringify(uiReport.params));
      if (changeJobsToPosition) {
        params.field = params.field?.replace(/^jobs/, 'position');
        if (params.group_by) {
          params.group_by = params.group_by.map((g: string) => g.replace(/^jobs/, 'position'));
        }
      }

      // returns the API report request (or undefined if the input is invalid)
      return {
        name: uiReport.name as SearchReportRequest.NameEnum,
        params: params,
      };
    } catch (e) {
      Log.e('Error converting UI report to API report', e);
      return undefined;
    }
  }

  private static filterGroupToAPIFilters(
    group: UIFilterGroup,
    reportIdToInclude: string | number | null = null
  ): [FilterGroupFiltersInner[], AggRequest[]] {
    const filters: FilterGroupFiltersInner[] = [];
    const rootAggs: AggRequest[] = [];

    if (group.filters) {
      group.filters.forEach((filter, idx) => {
        if ('filters' in filter) {
          // This is a FilterGroup
          filter = filter as UIFilterGroup;

          // If this is a jobs group, we handle all the filters without recursion so we can
          // modify them in special ways
          if (filter.isJobsGroup) {
            const groupFilters: FilterGroupFiltersInner[] = [];
            const jobsGroupType = filter.jobsGroupType || 'any';

            // If they only want ended jobs, add a filter for that
            if (jobsGroupType === 'ended') {
              groupFilters.push({
                field: 'jobs.ended_at',
                type: SearchFilter.TypeEnum.Must,
                match_type: SearchFilter.MatchTypeEnum.Exists,
              });
            }

            // Set filters for position status
            switch (filter.positionStatus) {
              case PositionStatus.First:
                groupFilters.push({
                  field:
                    jobsGroupType === 'active'
                      ? 'position.is_first_at_company'
                      : 'jobs.is_first_at_company',
                  type: SearchFilter.TypeEnum.Must,
                  match_type: SearchFilter.MatchTypeEnum.Exact,
                  boolean_value: true,
                });
                break;
              case PositionStatus.Last:
                groupFilters.push({
                  field:
                    jobsGroupType === 'active'
                      ? 'position.is_last_at_company'
                      : 'jobs.is_last_at_company',
                  type: SearchFilter.TypeEnum.Must,
                  match_type: SearchFilter.MatchTypeEnum.Exact,
                  boolean_value: true,
                });
                break;
              case PositionStatus.Promotion:
                groupFilters.push({
                  field:
                    jobsGroupType === 'active'
                      ? 'position.is_first_at_company'
                      : 'jobs.is_first_at_company',
                  type: SearchFilter.TypeEnum.Must,
                  match_type: SearchFilter.MatchTypeEnum.Exact,
                  boolean_value: false,
                });
                break;
            }

            filter.filters.forEach((f) => {
              // All filters in job groups are UI filters. Get a copy for modification...
              const apiFilter = JSON.parse(JSON.stringify(f)) as UIFilter;

              if (!this.hasValues(apiFilter.filter)) {
                return;
              }

              // If searching active jobs, use the position field instead of the jobs field
              if (jobsGroupType === 'active') {
                apiFilter.filter.field = apiFilter.filter.field!.replace(/^jobs/, 'position');
              }

              if (apiFilter.filter.field?.endsWith('_search')) {
                const searchFilters = this.generateCompanySearchAPIFilters(apiFilter);
                if (searchFilters) {
                  groupFilters.push(searchFilters);
                }
              } else {
                groupFilters.push(apiFilter.filter);
              }
            });

            // Create all the requested agg objects
            let groupAggs: AggRequest[] | undefined = undefined;
            groupAggs = filter.fieldsToChart?.map((f) => {
              return {
                id:
                  ((filter as UIFilterGroup).id || UniqueIdGenerator.generate()) +
                  '#' +
                  f.columnName,
                field_name: f.columnName,
                interval: this.getDefaultIntervalForAgg(f.columnName),
              };
            });

            // If this group is active jobs, these are top-level position aggs, so make
            // them so and return them back up to the caller to collect up
            if (jobsGroupType === 'active') {
              groupAggs?.forEach((agg) => {
                agg.field_name = agg.field_name.replace(/^jobs/, 'position');
              });
              rootAggs.push(...(groupAggs || []));
            }

            filters.push({
              operator: 'and',
              filters: groupFilters,
              aggs: jobsGroupType !== 'active' ? groupAggs : undefined,
              report: reportIdToInclude
                ? QuickBuildService.uiReportToAPIReport(
                    filter.reports?.find((r: any) => r.id === reportIdToInclude),
                    jobsGroupType === 'active'
                  )
                : undefined,
            });
          } else {
            const [innerFilters, rootAggs] = this.filterGroupToAPIFilters(
              filter,
              reportIdToInclude
            );
            rootAggs.push(...rootAggs);

            filters.push({
              operator: filter.operator,
              filters: innerFilters,
              report: reportIdToInclude
                ? QuickBuildService.uiReportToAPIReport(
                    filter.reports?.find((r: any) => r.id === reportIdToInclude),
                    filter.isJobsGroup && filter.jobsGroupType === 'active'
                  )
                : undefined,
            });
          }
        } else {
          // This is a UIFilter
          filter = filter as UIFilter;

          if (!this.hasValues(filter.filter)) {
            return;
          }

          // Change _search filters to search for company ID
          if (filter.filter.field?.endsWith('_search')) {
            const searchFilters = this.generateCompanySearchAPIFilters(filter);
            if (searchFilters) {
              filters.push(searchFilters);
            }
          } else {
            filters.push(filter.filter);
          }
        }
      });
    }
    return [filters, rootAggs];
  }

  // Given a UIFilter that represents a company search, generates the nested filters to find
  // matching companies and company groups
  private static generateCompanySearchAPIFilters(filter: UIFilter): FilterGroupFiltersInner | null {
    // If this is a company search field, we need to create the API filters for the companies and groups
    if (!filter.filter.string_values) return null;

    const groupValues = filter.filter.string_values.filter((v) => v.endsWith('-group')) || [];

    // We add a filter the company ID for all provided string values. This ensures we capture the actual
    // parents
    const filtersForCompany: FilterGroupFiltersInner[] = [];
    if (filter.filter.string_values.length > 0) {
      filtersForCompany.push({
        field: filter.filter.field!.replace('_search', '.company.id'),
        type: filter.filter.type,
        match_type: filter.filter.match_type,
        string_values: filter.filter.string_values.map((v) => v.replace('-group', '')),
      });
    }

    // For any grouped companies, we add another filter for the group ID
    if (groupValues.length > 0) {
      filtersForCompany.push({
        field: filter.filter.field!.replace('_search', '.company.group_id'),
        type: filter.filter.type,
        match_type: filter.filter.match_type,
        string_values: groupValues.map((v) => v.replace('-group', '')),
      });
    }

    // Match on either the provided company, or the parent companies if any were provided
    return {
      operator: filter.filter.type === SearchFilter.TypeEnum.Must ? 'or' : 'and',
      filters: filtersForCompany,
    };
  }

  // For numberic columns, we want to return a good default interval for the field
  private static getDefaultIntervalForAgg(columnName: string): number | undefined {
    if (columnName.includes('connections')) {
      return 50;
    }

    if (columnName.includes('tenure') || columnName.includes('duration')) {
      return 6;
    }

    if (columnName.includes('employee_count')) {
      return 100;
    }

    return undefined;
  }

  private static hasValues(filter: SearchFilter): boolean {
    if (filter.match_type === SearchFilter.MatchTypeEnum.Exists) {
      return true;
    }

    if (filter.string_values && filter.string_values.length > 0) {
      return true;
    }

    if (
      (filter.boolean_value !== null && filter.boolean_value !== undefined) ||
      filter.date_from ||
      filter.date_to ||
      filter.number_max ||
      (filter.number_min !== null && filter.number_min !== undefined)
    ) {
      return true;
    }

    return false;
  }
}
