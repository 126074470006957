import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import * as Highcharts from 'highcharts';
import { SystemService } from 'ldt-dw-reader-service-api';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';
import { lightTheme, darkTheme } from 'src/app/shared/highcharts-themes';

@Component({
  selector: 'app-stats',
  templateUrl: './stats.component.html',
  styleUrls: ['./stats.component.scss'],
})
export class StatsComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts; // required

  loading: boolean = true;

  total_hits: number;
  exists_position_email: number;
  exists_position_title: number;
  exists_country: number;
  exists_location: number;
  exists_education: number;
  exists_startdate: number;
  inferred_startdate: number;
  exists_cell_phone: number;
  exists_direct_dial: number;
  exists_contact_info: number;
  new_jobs: number;
  new_jobs_missing_email: number;
  top_countries: any[];
  top_locations: any[];
  top_levels: any[];
  top_functions: any[];
  top_email_status: any[];
  top_schools: any[];
  top_success_dates: any[];
  top_company_types: any[];
  created_dates: any[];
  jobchanged_dates: any[];

  top_country_chart_options: any = {};
  top_location_chart_options: any = {};
  top_level_chart_options: any = {};
  top_function_chart_options: any = {};
  top_email_status_chart_options: any = {};
  top_schools_chart_options: any = {};
  top_company_types_options: any = {};
  success_chart_options: any = {};
  created_chart_options: any = {};
  jobchanged_chart_options: any = {};

  constructor(
    private notify: NotificationService,
    private dwStatsService: SystemService,
    public darkModeService: DarkModeService
  ) {}

  ngOnInit(): void {
    this.getDwStats();

    // apply correct DM/LM theme for highcharts
    this.applyChartTheme(this.darkModeService.currentDarkModeValue);
  }

  applyChartTheme(darkMode: boolean) {
    Highcharts.setOptions(darkMode ? darkTheme : lightTheme);
  }

  getDwStats() {
    this.dwStatsService.getStats().subscribe(
      (r: any) => {
        this.total_hits = r.hits.total.value;
        this.exists_position_email = r.aggregations.position_email_exists.doc_count;
        this.exists_position_title = r.aggregations.position_title_exists.doc_count;
        this.exists_country = r.aggregations.country_exists.doc_count;
        this.exists_location = r.aggregations.location_exists.doc_count;
        this.exists_education = r.aggregations.education_exists.doc_count;
        this.exists_startdate = r.aggregations.position_started_at_exists.doc_count;
        this.inferred_startdate = r.aggregations.position_start_dates_inferred.doc_count;
        this.exists_cell_phone = r.aggregations.cell_phone_exists.doc_count;
        this.exists_direct_dial = r.aggregations.direct_dial_for_position.doc_count;
        this.exists_contact_info = r.aggregations.has_contact_info.doc_count;
        this.new_jobs = r.aggregations.newly_changed_job_email_stats.doc_count;
        this.new_jobs_missing_email =
          r.aggregations.newly_changed_job_email_stats.email_missing.doc_count;

        this.top_countries = r.aggregations.top_countries.buckets;
        this.top_locations = r.aggregations.top_locations.buckets;
        this.top_levels = r.aggregations.top_levels.buckets;
        this.top_functions = r.aggregations.top_functions.buckets;
        this.top_email_status = r.aggregations.top_email_status.buckets;
        this.top_success_dates = r.aggregations.by_success.buckets.filter(
          (b: any) => b.key >= Date.now() - 1000 * 60 * 60 * 24 * 550
        );
        this.top_schools = r.aggregations.top_schools.buckets;
        this.top_company_types = r.aggregations.position_company_types.buckets;
        this.created_dates = r.aggregations.by_created?.buckets || [];
        this.jobchanged_dates = r.aggregations.by_company_change_detected_at.buckets;

        this.renderChartOptions();
      },
      () => {
        this.notify.error('Error getting data');
        this.loading = false;
      },
      () => {
        this.loading = false;
      }
    );
  }

  renderChartOptions() {
    let country_chart_options: any = JSON.parse(JSON.stringify(this.topNChartOptions));
    country_chart_options.xAxis.categories = this.top_countries.map((c) => c.key);
    country_chart_options.series[0].data = this.top_countries.map((c) => c.doc_count);
    country_chart_options.yAxis.type = 'logarithmic';
    this.top_country_chart_options = country_chart_options;

    let location_chart_options: any = JSON.parse(JSON.stringify(this.topNChartOptions));
    location_chart_options.xAxis.categories = this.top_locations.map((c) => c.key);
    location_chart_options.series[0].data = this.top_locations.map((c) => c.doc_count);
    this.top_location_chart_options = location_chart_options;

    let level_chart_options: any = JSON.parse(JSON.stringify(this.topNChartOptions));
    level_chart_options.xAxis.categories = this.top_levels.map((c) => c.key);
    level_chart_options.series[0].data = this.top_levels.map((c) => c.doc_count);
    this.top_level_chart_options = level_chart_options;

    let function_chart_options: any = JSON.parse(JSON.stringify(this.topNChartOptions));
    function_chart_options.xAxis.categories = this.top_functions.map((c) => c.key);
    function_chart_options.series[0].data = this.top_functions.map((c) => c.doc_count);
    this.top_function_chart_options = function_chart_options;

    let email_status_chart_options: any = JSON.parse(JSON.stringify(this.topNChartOptions));
    email_status_chart_options.xAxis.categories = this.top_email_status.map((c) => c.key);
    email_status_chart_options.series[0].data = this.top_email_status.map((c) => c.doc_count);
    email_status_chart_options.yAxis.title.text = 'Email count';
    this.top_email_status_chart_options = email_status_chart_options;

    let education_chart_options: any = JSON.parse(JSON.stringify(this.topNChartOptions));
    education_chart_options.xAxis.categories = this.top_schools.map((c) => c.key);
    education_chart_options.series[0].data = this.top_schools.map((c) => c.doc_count);
    this.top_schools_chart_options = education_chart_options;

    let success_chart_options: any = JSON.parse(JSON.stringify(this.topNChartOptions));
    success_chart_options.chart.type = 'column';
    success_chart_options.xAxis.categories = this.top_success_dates.map((c) => c.key_as_string);
    success_chart_options.series[0].data = this.top_success_dates.map((c) => c.doc_count);
    this.success_chart_options = success_chart_options;

    let created_chart_options: any = JSON.parse(JSON.stringify(this.topNChartOptions));
    created_chart_options.chart.type = 'column';
    created_chart_options.xAxis.categories = this.created_dates.map((c) => c.key_as_string);
    created_chart_options.series[0].data = this.created_dates.map((c) => c.doc_count);
    this.created_chart_options = created_chart_options;

    let jobchanged_chart_options: any = JSON.parse(JSON.stringify(this.topNChartOptions));
    jobchanged_chart_options.chart.type = 'column';
    jobchanged_chart_options.xAxis.categories = this.jobchanged_dates.map((c) => c.key_as_string);
    jobchanged_chart_options.series[0].data = this.jobchanged_dates.map((c) => c.doc_count);
    this.jobchanged_chart_options = jobchanged_chart_options;

    let company_types_options: any = JSON.parse(JSON.stringify(this.topNChartOptions));
    company_types_options.xAxis.categories = this.top_company_types.map((c) => c.key);
    company_types_options.series[0].data = this.top_company_types.map((c) => c.doc_count);
    this.top_company_types_options = company_types_options;
  }

  topNChartOptions = {
    credits: { enabled: false },
    chart: {
      type: 'bar',
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: [],
      title: {
        text: null,
      },
    },
    yAxis: {
      title: {
        text: 'People',
      },
      min: 1,
    },
    series: [
      {
        data: [],
        showInLegend: false,
        color: '#8e91ff', // var(--primary-purple-500)
      },
    ],
    plotOptions: {
      bar: {
        borderWidth: 0,
        borderColor: 'transparent',
      },
      column: {
        borderWidth: 0,
        borderColor: 'transparent',
      },
    },
  };
}
