import { Component, Input, SimpleChanges } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import * as Highcharts from 'highcharts';
import { reportChartBarOptions } from '../highcharts-options';
import { QuickBuildService } from 'src/app/people/quick-build.service';
import { PeopleReportService } from 'src/app/people/report-service.service';
import { getPeopleColumnByName } from 'src/app/people/people-columns';
import { BaseReportComponent } from '../base-report.component';
import { Log } from 'src/app/logger.service';

const FIELDS_TO_GROUP_BY = ['jobs.function', 'jobs.level'];
const REPORT_NAME = 'demographics';

@Component({
  selector: 'app-reports-demographics-current',
  templateUrl: './demographics-current.component.html',
  styleUrls: ['./demographics-current.component.scss'],
})
export class DemographicsCurrentComponent extends BaseReportComponent {
  @Input() field: string;
  @Input() allowFieldSelection = true;
  breakdownByOptions = FIELDS_TO_GROUP_BY;
  selectedBreakdownBy: (typeof FIELDS_TO_GROUP_BY)[number] = FIELDS_TO_GROUP_BY[0];

  protected readonly CHART_ID = 'demographics';

  constructor(reportService: PeopleReportService, quickBuildService: QuickBuildService) {
    super(reportService, quickBuildService);
  }

  protected initializeChart(): void {
    this.chart = Highcharts.chart(`highchartsContainer-${this.chartId}`, reportChartBarOptions);
    this.chart.update(
      {
        title: {
          text: 'Active Employees by ' + this.getFieldDisplayName(this.selectedBreakdownBy),
        },
        yAxis: { title: { text: '% of employees' } },
      },
      true,
      true
    );
  }

  protected shouldRefreshOnChanges(changes: SimpleChanges): boolean {
    if ('field' in changes) {
      this.selectedBreakdownBy = this.field;
      return true;
    }
    return super.shouldRefreshOnChanges(changes);
  }

  protected getChartData(): void {
    if (!this.quickBuild) return;

    const reportParams = {
      group_by: [this.selectedBreakdownBy],
      // aggregate_type: 'range',
    };
    const sub = this.reportService
      .getReportDataWithReportDetails(
        this.reportId,
        REPORT_NAME,
        reportParams,
        this.reportTarget,
        this.quickBuild
      )
      .subscribe((data) => {
        Log.d('demographicscurrent data', data);
        const [series, categories] = this.generateChartSeries(
          data.count,
          data.data.demographics,
          this.selectedBreakdownBy
        );
        this.updateChart(series, categories);
      });
    this.subscriptions.add(sub);
  }

  protected generateChartSeries(total: number, data: any, key: string): [any[], any[]] {
    Log.d('generateChartSeries total', total);
    const usePct = true; // total > 0 && data[0].count_employees / total > 0.05;
    const seriesData = data.slice(0, 10).map((c: any) => {
      return usePct ? 100 * (c.count_employees / (total || 1)) : c.count_employees;
    });

    const categories = data.slice(0, 10).map((c: any) => {
      return c.group_values[0]?.value;
    });

    return [
      [
        {
          id: key,
          name: key,
          showInLegend: false,
          dataLabels: {
            format: usePct ? '{point.y:.2f}%' : '{point.y:.0f}',
          },
          data: seriesData,
          type: 'bar',
        },
      ],
      categories,
    ];
  }

  onBreakdownByChanged(value: MatButtonToggleChange) {
    if (!value) return;

    this.selectedBreakdownBy = value.value;
    this.resetChartData();
    this.getChartData();
  }

  getFieldDisplayName(field: string): string {
    return getPeopleColumnByName(field).displayName;
  }
}
