<div id="warehouse-companies-page" class="tw-flex tw-flex-col tw-h-full tw-py-6">
  <!-- Search Form -->
  <div class="tw-flex tw-flex-wrap tw-flex-row tw-mb-6 tw-content-center">
    <form
      id="company-search-form"
      [formGroup]="inputFormGroup"
      class="tw-flex tw-items-center tw-gap-2">
      <app-company-search
        [labelText]="'Search for a company'"
        [showConglomerates]="false"
        (companySelected)="companySelected($event)"
        class="people-filters-company-search">
      </app-company-search>
      <div class="tw-flex tw-gap-2 tw-h-10">
        <button
          type="submit"
          mat-stroked-button
          [disabled]="!companyJson || refreshing"
          color="primary"
          [class.spinner]="refreshing"
          (click)="refresh()">
          Refresh
        </button>
        <button
          mat-stroked-button
          [disabled]="!companyJson || refreshing || replaying"
          color="primary"
          [class.spinner]="refreshing || replaying"
          (click)="replayCompany()">
          IS Replay
        </button>
      </div>
    </form>
  </div>

  <!-- Alias Away Warning -->
  <app-alert [alertType]="AlertType.Error" [visible]="aliasAway">
    This company is aliased to another company:
    <a
      [href]="'/datawarehouse/companies?id=' + aliasAway?.id"
      target="_blank"
      class="tw-font-bold hover:tw-underline">
      {{aliasAway?.name}}
      <span *ngIf="aliasAway?.linkedin">({{aliasAway?.linkedin}})</span>
      [ID: {{aliasAway?.id}}]
    </a>
  </app-alert>

  <!-- Company Information Display -->
  <div
    *ngIf="companyJson"
    class="tw-bg-white dark:tw-bg-gray-800 tw-rounded-lg tw-shadow-md tw-p-2 lg:tw-p-4 xxl:tw-p-6">
    <!-- Header Section -->
    <div class="tw-flex tw-flex-col tw-mb-4">
      <!-- heading w/ ticker & edit/add btn -->
      <div class="tw-flex tw-gap-2 tw-items-center">
        <h1 class="tw-text-2xl tw-my-0 tw-text-gray-700 dark:tw-text-dark-light-dynamic">
          {{companyJson.name}}
        </h1>
        <span
          *ngIf="companyJson.ticker"
          class="tw-text-sm tw-font-semibold tw-text-white tw-bg-gray-600 tw-px-2 tw-py-1 tw-rounded">
          {{companyJson.ticker}}
        </span>
        <button
          mat-icon-button
          class="tw-border tw-border-dotted tw-border-gray-400 dark:tw-border-gray-500 tw-rounded-full tw-ml-1"
          (click)="openEditTickerDialog()"
          [matTooltip]="companyJson.ticker ? 'Edit Ticker' : 'Add Ticker'"
          [matTooltipPosition]="'above'">
          <mat-icon class="tw-text-lg tw-leading-[21px]">
            {{companyJson.ticker ? 'edit' : 'add'}}
          </mat-icon>
        </button>
      </div>

      <!-- company metadata w/ type, founded, domain, linkedin -->
      <div
        class="tw-flex tw-flex-wrap tw-gap-x-6 tw-gap-y-2 tw-text-gray-600 dark:tw-text-gray-300 tw-items-center tw-text-sm">
        <span *ngIf="companyJson.type" class="tw-flex tw-gap-1">
          <mat-icon class="metadata-icon tw-mr-1">business</mat-icon>
          {{companyJson.type}}
        </span>

        <span *ngIf="companyJson.founded_year" class="tw-flex tw-gap-1">
          <mat-icon class="metadata-icon tw-mr-1">event</mat-icon>Founded:
          <span class="tw-font-semibold">{{companyJson.founded_year}}</span>
        </span>

        <span>
          <mat-icon class="metadata-icon tw-mr-1">dns</mat-icon>
          <a
            *ngIf="companyJson.domain"
            href="https://{{companyJson.domain}}"
            target="_blank"
            class="hover:tw-underline">
            {{companyJson.domain}}
          </a>
          <button
            mat-icon-button
            class="tw-w-8 tw-h-8 tw-leading-6 tw-border tw-border-dotted tw-border-gray-400 dark:tw-border-gray-500 tw-rounded-full tw-ml-1"
            (click)="openEditDomainDialog()"
            [matTooltip]="companyJson.domain ? 'Edit Domain' : 'Add Domain'"
            [matTooltipPosition]="'above'">
            <mat-icon class="tw-text-lg tw-leading-[21px]">
              {{companyJson.domain ? 'edit' : 'add'}}
            </mat-icon>
          </button>
        </span>

        <span *ngIf="companyJson.linkedin" class="tw-flex tw-gap-1">
          <img class="black-inverted-img tw-h-5 tw-w-5 tw-mr-1" src="assets/icons/linkedin.svg" />

          <a
            href="https://linkedin.com/company/{{companyJson.linkedin}}"
            target="_blank"
            class="hover:tw-underline">
            {{companyJson.linkedin}}
          </a>
          <span
            *ngIf="companyJson.linkedin_numeric_id"
            class="tw-flex"
            matTooltip="Company numberic LinkedIn ID"
            matTooltipPosition="above">
            (<a
              href="https://linkedin.com/company/{{companyJson.linkedin_numeric_id}}"
              target="_blank"
              class="hover:tw-underline">
              {{companyJson.linkedin_numeric_id}} </a
            >)
          </span>
        </span>

        <span class="tw-font-medium tw-flex tw-gap-1">
          <mat-icon class="metadata-icon tw-mr-1">people</mat-icon>
          All Time Employees:
          <span class="tw-font-semibold tw-ml-1">{{count_all_time_employees | number}}</span>
        </span>
      </div>
      <div *ngIf="companyStats"></div>
    </div>

    <!-- Main Content Grid -->
    <div class="tw-grid tw-grid-cols-1 lgx:tw-grid-cols-2 tw-gap-y-5 lgx:tw-gap-3 tw-align-top">
      <!-- Company Details -->
      <div class="tw-bg-gray-100 dark:tw-bg-gray-700 tw-p-2 lg:tw-p-4 tw-rounded">
        <h2 class="tw-text-lg tw-font-semibold tw-mt-0 dark:tw-text-white">Company Details</h2>
        <div class="tw-space-y-3 dark:tw-text-gray-200">
          <div *ngIf="companyJson.id" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2 tw-text-gray-500">fingerprint</mat-icon>
            <span>{{companyJson.id}}</span>
            <!-- copy to clipboard -->
            <button
              mat-icon-button
              (click)="copyToClipboard(companyJson.id)"
              class="tw-w-8 tw-h-8 tw-leading-5 tw-ml-1">
              <mat-icon class="tw-text-gray-500">
                {{copyIconState[companyJson.id] || 'content_copy'}}
              </mat-icon>
            </button>
          </div>
          <div *ngIf="companyJson.employee_count" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2 tw-text-gray-500">people</mat-icon>
            <span>{{companyJson.employee_count | number}} Employees</span>
          </div>
          <div *ngIf="companyJson.industry" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2 tw-text-gray-500">category</mat-icon>
            <span>{{companyJson.industry}}</span>
          </div>
          <div *ngIf="companyJson.location" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2 tw-text-gray-500">location_on</mat-icon>
            <span>{{companyJson.location}}</span>
          </div>
          <div *ngIf="companyJson.country" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2 tw-text-gray-500">flag</mat-icon>
            <span>{{companyJson.country}}</span>
          </div>
          <div *ngIf="companyJson.address" class="tw-flex tw-items-center">
            <mat-icon class="tw-mr-2 tw-text-gray-500">home</mat-icon>
            <span>{{companyJson.address}}</span>
          </div>
        </div>
      </div>

      <!-- Classification -->
      <div class="tw-bg-gray-100 dark:tw-bg-gray-700 tw-p-2 lg:tw-p-4 tw-rounded">
        <h2 class="tw-text-lg tw-font-semibold tw-mt-0 dark:tw-text-white">Classification</h2>
        <div class="tw-space-y-3">
          <div *ngIf="companyJson.naics_codes">
            <h3 class="tw-font-medium tw-text-base tw-text-gray-700 dark:tw-text-gray-200 tw-mb-1">
              NAICS Codes:
            </h3>
            <div class="tw-flex tw-flex-wrap tw-gap-2 tw-mt-1">
              <span
                *ngFor="let code of companyJson.naics_codes"
                class="tw-bg-primary_purple-200 dark:tw-bg-primary_purple-600 tw-px-2 tw-py-1 tw-rounded dark:tw-text-white">
                {{code}}
              </span>
            </div>
          </div>
          <div *ngIf="companyJson.sic_codes">
            <h3 class="tw-font-medium tw-text-base tw-text-gray-700 dark:tw-text-gray-200 tw-mb-1">
              SIC Codes:
            </h3>
            <div class="tw-flex tw-flex-wrap tw-gap-2 tw-mt-1">
              <span
                *ngFor="let code of companyJson.sic_codes | slice:0:(showAllSicCodes ? companyJson.sic_codes.length : 50)"
                class="tw-bg-primary_purple-200 dark:tw-bg-primary_purple-600 tw-px-2 tw-py-1 tw-rounded dark:tw-text-white">
                {{code}}
              </span>
              <button
                *ngIf="companyJson.sic_codes.length > 50"
                mat-button
                color="primary"
                class="tw-text-sm tw-py-0"
                (click)="showAllSicCodes = !showAllSicCodes">
                {{ showAllSicCodes ? 'Show Less' : 'Show More (' + (companyJson.sic_codes.length - 50) + ')' }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Group Information -->
      <div class="tw-bg-gray-100 dark:tw-bg-gray-700 tw-p-2 lg:tw-p-4 tw-rounded">
        <h2 class="tw-text-lg tw-font-semibold tw-mt-0 dark:tw-text-white">Group Information</h2>
        <div class="dark:tw-text-gray-200">
          <!-- group id -->
          <div class="tw-flex tw-items-center">
            <mat-icon class="tw-text-gray-500 tw-mr-2">group_work</mat-icon>
            <span>Group ID:</span>
            <span class="tw-ml-2" *ngIf="companyJson.group_id">
              <a
                href="/datawarehouse/companies?id={{companyJson.group_id}}"
                class="hover:tw-underline">
                {{companyJson.group_id}}
              </a>
            </span>
            <button
              mat-icon-button
              class="tw-w-8 tw-h-8 tw-leading-6 tw-border tw-border-dotted tw-border-gray-400 dark:tw-border-gray-500 tw-rounded-full tw-ml-3"
              (click)="openEditGroupDialog()"
              [matTooltip]="companyJson.group_id ? 'Edit Group' : 'Add Group'"
              [matTooltipPosition]="'above'">
              <mat-icon class="tw-text-lg tw-leading-[21px]">
                {{companyJson.group_id ? 'edit' : 'add'}}
              </mat-icon>
            </button>
          </div>

          <!-- companies in group -->
          <div
            *ngIf="companyJson.grouped_company_count !== undefined"
            class="tw-flex tw-flex-wrap tw-gap-x-4 tw-gap-y-2 tw-items-center tw-mb-4">
            <div class="tw-flex tw-items-center">
              <mat-icon class="tw-text-gray-500 tw-mr-2">corporate_fare</mat-icon>
              <span>Companies in Group:</span>
              <span class="tw-ml-2">
                <span class="tw-font-semibold">{{companyJson.grouped_company_count}}</span>
              </span>
            </div>

            <!-- count mismatch -->
            <span
              *ngIf="companyJson.grouped_company_count !== (childCompanies?.length || 0)"
              class="tw-flex tw-items-center tw-gap-2">
              <mat-icon class="tw-text-orange-400 tw-h-5 tw-w-5 tw-text-[20px]">warning</mat-icon>
              Count mismatch
              <button mat-raised-button color="primary" class="tw-ml-2" (click)="resetCount()">
                Set count to {{childCompanies?.length || 0}}
              </button>
            </span>
          </div>

          <!-- move all to alias target -->
          <div
            *ngIf="childCompanies && childCompanies.length > 0"
            class="tw-w-full tw-h-72 tw-mb-6">
            <button
              *ngIf="aliasAway"
              mat-raised-button
              color="primary"
              class="tw-mb-4"
              (click)="moveGroupsToAlias()">
              Move All to Alias Target
            </button>

            <ag-grid-angular
              class="tw-w-full tw-h-full"
              [ngClass]="{ 'ag-theme-balham': !(darkModeService.darkMode$ | async), 'ag-theme-balham-dark': darkModeService.darkMode$ | async }"
              [rowData]="childCompanies"
              [columnDefs]="childCompanyColumnDefs"
              accentedSort="true"
              [defaultColDef]="defaultColDef"
              enableCellTextSelection="true"
              ensureDomOrder="true">
            </ag-grid-angular>
          </div>

          <!-- aliases to this company -->
          <div
            *ngIf="aliasSources && aliasSources.length > 0"
            class="tw-flex tw-items-center tw-mb-2">
            <mat-icon class="tw-mr-2">east</mat-icon>
            <span class="tw-font-medium">Aliases to this company:</span>
            <span class="tw-ml-2 tw-font-semibold">{{aliasSources.length}}</span>
          </div>
          <div *ngIf="aliasSources && aliasSources.length > 0" class="tw-w-full tw-h-72">
            <ag-grid-angular
              (gridReady)="onAliasGridReady($event)"
              class="tw-w-full tw-h-full"
              [ngClass]="{ 'ag-theme-balham': !(darkModeService.darkMode$ | async), 'ag-theme-balham-dark': darkModeService.darkMode$ | async }"
              [rowData]="aliasSources"
              [columnDefs]="aliasSourcesColumnDefs"
              accentedSort="true"
              [defaultColDef]="defaultColDef"
              enableCellTextSelection="true"
              ensureDomOrder="true">
            </ag-grid-angular>
          </div>
        </div>
      </div>

      <!-- Metadata Section -->
      <div class="tw-bg-gray-100 dark:tw-bg-gray-700 tw-p-2 lg:tw-p-4 tw-rounded">
        <h2 class="tw-text-lg tw-font-semibold tw-mt-0 dark:tw-text-white">Metadata</h2>
        <div class="tw-flex tw-flex-col tw-gap-3 dark:tw-text-gray-200">
          <div *ngIf="companyJson.metadata?.created_at">
            <span class="tw-font-medium tw-text-sm">Created:</span>
            <span class="tw-ml-2 dark:tw-text-white">
              {{companyJson.metadata.created_at | date:'medium'}}
            </span>
          </div>
          <div *ngIf="companyJson.metadata?.updated_at">
            <span class="tw-font-medium tw-text-sm">Updated:</span>
            <span class="tw-ml-2 dark:tw-text-white">
              {{companyJson.metadata.updated_at | date:'medium'}}
            </span>
          </div>
          <div *ngIf="companyJson.metadata?.is_internal !== undefined">
            <span class="tw-font-medium tw-text-sm">Internal:</span>
            <span class="tw-ml-2 dark:tw-text-white">
              {{companyJson.metadata.is_internal ? 'Yes' : 'No'}}
            </span>
          </div>
          <div *ngIf="companyJson.metadata?.lookup">
            <span class="tw-font-medium tw-text-sm">Lookup Key:</span>
            <span class="tw-ml-2 dark:tw-text-white">{{companyJson.metadata.lookup}}</span>
          </div>
          <div *ngIf="companyJson.metadata?.aliases">
            <span class="tw-font-medium tw-text-sm">Aliases:</span>
            <div class="tw-flex tw-flex-wrap tw-gap-2 tw-mt-1">
              <span
                *ngFor="let alias of companyJson.metadata.aliases"
                class="tw-bg-gray-200 dark:tw-bg-gray-600 tw-px-2 tw-py-1 tw-rounded">
                {{alias}}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Raw JSON Viewer (Collapsed by Default) -->
    <div class="tw-max-w-[1200px] tw-mx-auto">
      <div class="tw-mt-6">
        <mat-expansion-panel
          id="company-json-expansion-panel"
          class="dark:tw-bg-gray-700 dark:tw-text-gray-200">
          <mat-expansion-panel-header>
            <mat-panel-title>Company JSON</mat-panel-title>
          </mat-expansion-panel-header>
          <ngx-json-viewer [json]="companyJson" [depth]="3" [expanded]="false"></ngx-json-viewer>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
</div>
