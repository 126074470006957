import { Injectable } from '@angular/core';
import { Log } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class HostnameService {
  private readonly defaultHostname = 'gotlivedata.com';
  private readonly workforceHostnames = ['app.workforce.ai', 'workforce.ai', '127.0.0.1'];

  constructor() {}

  get hostname(): string {
    return window.location.hostname;
  }

  isWorkforceHostname(): boolean {
    Log.d('Checking if hostname is workforce', this.hostname);
    // TEMP FOR DEVE ONLY!
    if (this.hostname.includes('workforce')) {
      return true;
    }

    return this.workforceHostnames.includes(this.hostname);
  }

  isDefaultHostname(): boolean {
    return this.hostname === this.defaultHostname;
  }
}
