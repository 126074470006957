import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'ldt-chat-api';

@Component({
  selector: 'app-chat-history-drawer',
  templateUrl: './chat-history-drawer.component.html',
  styleUrls: ['./chat-history-drawer.component.scss'],
})
export class ChatHistoryDrawerComponent {
  @Input() orgId: string;
  @Input() isOpen: boolean = false;
  @Output() closeDrawer = new EventEmitter<void>();

  chatHistory: any[] = [];
  chatsRefreshing: boolean = false;

  constructor(
    private chatService: ChatService,
    private router: Router
  ) {}

  ngOnChanges() {
    // Fetch chats when the drawer is opened
    if (this.isOpen) {
      this.getChats();
    }
  }

  getChats() {
    this.chatsRefreshing = true;
    this.chatService.getChats(this.orgId).subscribe({
      next: (data: any) => {
        this.chatHistory = data.threads;
        this.chatsRefreshing = false;
      },
      error: (err: any) => {
        console.error('Error getting chats:', err);
        this.chatsRefreshing = false;
      },
    });
  }

  onRowClicked(event: any) {
    const id = event.data.id;
    this.router.navigate([this.orgId, 'people', 'chat'], { queryParams: { threadId: id } });
    this.closeDrawer.emit();
  }

  toggleDrawer() {
    this.closeDrawer.emit();
  }
}
