<h1 class="content-title tw-mt-0">Moneyball</h1>

<!-- search -->
<mat-card class="searchCard moneyballContainer tw-max-w-[1800px]">
  <mat-card-content>
    <div
      class="searchFields tw-flex tw-flex-col tw-items-start tw-gap-2 md:tw-gap-4 tw-flex-wrap lgx:tw-flex-row lgx:tw-items-center tw-m-0 tw-mb-4">
      <!-- company search -->
      <app-company-search
        [labelText]="'Find a company'"
        [floatLabel]="'always'"
        (companySelected)="companySelected($event)"
        class="moneyball-battle-search" />

      <div
        class="tw-flex tw-items-center tw-gap-x-3 tw-gap-y-0 lg:tw-gap-3 tw-flex-wrap"
        style="flex: 2 !important">
        <!-- date range -->
        <div
          class="rangePicker tw-flex tw-flex-col tw-min-w-52 tw-self-start tw-mb-[13px] xl:tw-ml-auto">
          <label class="tw-font-medium tw-mb-[6px]">Date range: </label>
          <input
            type="text"
            [ranges]="ranges"
            name="daterange"
            alwaysShowCalendars="true"
            opens="left"
            ngxDaterangepickerMd
            [showDropdowns]="true"
            [(ngModel)]="selectedDates"
            (datesUpdated)="onDatesChanged()"
            class="form-control tw-p-2 tw-mb-1 tw-border tw-border-solid tw-border-gray-300 tw-rounded dark:tw-bg-gray-700 dark:tw-text-white dark:tw-border-gray-500" />
        </div>

        <!-- buttons -->
        <button
          mat-raised-button
          color="primary"
          (click)="getResults()"
          [disabled]="!selectedCompany">
          Get Results
        </button>
      </div>
    </div>

    <!-- recent searches -->
    <div *ngIf="recentSearches.length > 0">
      <div
        class="recentSearches tw-flex tw-items-center tw-overflow-hidden tw-whitespace-nowrap tw-w-full">
        <div class="tw-font-medium tw-mr-3">Recent Searches:</div>

        <div class="tw-flex tw-flex-1 tw-items-center tw-overflow-hidden ">
          <button
            mat-stroked-button
            color="primary"
            class="compact-btn recent-search tw-flex-shrink-0 tw-mr-3 tw-mb-1"
            *ngFor="let search of recentSearches"
            (click)="companySelected(search)">
            <img
              *ngIf="search.type === 'group'"
              src="../../assets/img/office-building.png"
              alt="office building conglomerate"
              class="tw-w-5 tw-align-middle tw-ml-1 tw-mb-1" />
            {{search.company.name}}
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>

<!-- loading -->
<div *ngIf="loading" class="loading-container">
  <p class="loading-message">Loading data for {{companyHero}}...</p>
</div>

<!-- Overview section -->
<section #sectionOverview id="section-overview" class="section-container">
  <div [hidden]="!showCharts" class="results tw-max-w-[1840px]">
    <div class="cols-3">
      <mat-card>
        <mat-card-title class="tw-gap-0">
          <img
            *ngIf="selectedCompanyGroups"
            src="../../assets/img/office-building.png"
            alt="office building conglomerate"
            class="tw-w-9 tw-mb-1" />
          {{companyHero}}
        </mat-card-title>
        <mat-card-subtitle *ngIf="selectedCompanyGroups">
          {{groupedCompanies.length}} subsidiary companies
        </mat-card-subtitle>
        <mat-card-content class="tw-flex tw-items-center tw-flex-wrap tw-gap-[6px]">
          <a
            *ngFor="let company of displayedCompanies; let last = last"
            href="javascript:void(0)"
            class="tw-truncate tw-text-ellipsis tw-max-w-[360px] tw-text-link-color-dynamic tw-font-medium hover:tw-underline tw-transition-all"
            (click)="companySelected({'company': company, 'type': 'company'})">
            {{company.name}}<ng-container *ngIf="!last">, </ng-container>
          </a>
          <!-- conditional btn to show/hide too many filter chips -->
          <button
            mat-stroked-button
            color="primary"
            *ngIf="groupedCompanies && groupedCompanies.length > displayCompaniesLimit"
            (click)="toggleShowMore()"
            class="tw-h-[24px] tw-leading-[20px] tw-self-start">
            <ng-container *ngIf="showMoreCompanies; else showMoreLabel">
              <span class="tw-flex tw-items-start">
                <mat-icon class="tw-text-[20px] tw-w-[20px] tw-h-[20px]">
                  keyboard_arrow_left
                </mat-icon>
                <span>Show fewer</span>
              </span>
            </ng-container>
            <ng-template #showMoreLabel>
              <span class="tw-text-left">
                ...and
                <span id="num-badges" class="tw-font-semibold">
                  {{ groupedCompanies.length - displayCompaniesLimit }}
                </span>
                more
              </span>
            </ng-template>
          </button>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-title>Total Coverage</mat-card-title>
        <mat-card-subtitle>People we track that have ever worked here</mat-card-subtitle>
        <mat-card-content class="bannerNumber tw-text-3xl tw-font-semibold">
          {{(allTimeEmployeeCount?.toLocaleString() || 'Loading...')}}
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-title>Current Employees</mat-card-title>
        <mat-card-subtitle>People we track currently working here</mat-card-subtitle>
        <mat-card-content class="bannerNumber tw-text-3xl tw-font-semibold">
          {{(currentEmployeeCount?.toLocaleString() || 'Loading...')}}
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</section>

<!-- Sticky nav -->
<nav
  #sectionNav
  [hidden]="!showCharts"
  id="mb-section-nav"
  class="tw-sticky tw-z-50 tw-bg-white dark:tw-bg-gray-800 tw-shadow-md dark:tw-shadow-dark-md tw-py-1 md:tw-py-2 lgx:tw-py-3 tw-px-3 md:tw-px-5 tw-mt-4 -tw-top-4 xxl:-tw-top-6"
  [class.tw-max-w-[1800px]]="!isOverviewScrolledPast()"
  [class.tw-max-w-none]="isOverviewScrolledPast()"
  [class.scrolled-passed]="isOverviewScrolledPast()">
  <div
    class="tw-flex tw-flex-col md:tw-flex-col lgx:tw-flex-row tw-items-start lgx:tw-items-center tw-gap-1 md:tw-gap-0">
    <!-- Company name -->
    <div
      class="mb-nav-item tw-text-xl xl:tw-text-2xl tw-font-medium tw-flex tw-items-center tw-gap-1 tw-order-1 lgx:tw-order-none tw-w-full lgx:tw-w-auto"
      [class.tw-opacity-0]="!isOverviewScrolledPast()"
      [class.tw-h-0]="!isOverviewScrolledPast()">
      <img
        *ngIf="selectedCompanyGroups"
        src="../../assets/img/office-building.png"
        alt="office building conglomerate"
        class="tw-w-7 md:tw-w-9 tw-mb-1" />
      <span
        id="company-hero-name"
        class="tw-text-gray-700 dark:tw-text-gray-300 lgx:tw-max-w-52 xxl:tw-max-w-[360px]">
        {{companyHero}}
      </span>
    </div>

    <!-- Navigation links -->
    <div
      class="mb-nav-item tw-flex tw-justify-start xs:tw-justify-center lg:tw-pl-4 tw-gap-2 md:tw-gap-6 tw-overflow-x-auto tw-w-full lgx:tw-w-auto tw-order-3 lgx:tw-order-none tw-pb-1 lgx:tw-pb-0 lgx:tw-mx-auto">
      <a
        *ngFor="let section of sections"
        href="javascript:void(0)"
        (click)="scrollToFragment(section.id); activeSection.set(section.id)"
        class="mb-nav-links tw-text-base xl:tw-text-lg tw-font-medium tw-px-3 md:tw-px-4 tw-py-1 md:tw-py-2 tw-rounded-md tw-transition-colors tw-whitespace-normal hover:tw-bg-gray-100 dark:hover:tw-bg-gray-700"
        [class.active-nav-link]="activeSection() === section.id">
        <span class="tw-hidden md:tw-block">{{section.name}}</span>
        <span class="tw-block md:tw-hidden">{{section.shortName}}</span>
      </a>
    </div>

    <!-- Employee count -->
    <div
      id="mb-employee-count"
      class="mb-nav-item tw-text-xs lg:tw-text-sm xxl:tw-text-base tw-text-gray-700 dark:tw-text-gray-300 tw-order-2 lgx:tw-order-none lgx:tw-w-auto lgx:tw-text-right"
      [class.tw-opacity-0]="!isOverviewScrolledPast()"
      [class.tw-h-0]="!isOverviewScrolledPast()">
      Current Employees
      <span class="tw-font-semibold tw-text-black-white-dynamic">
        {{(currentEmployeeCount?.toLocaleString() || 'Loading...')}}
      </span>
    </div>
  </div>
</nav>

<!-- Landscape section -->
<section #trackedSections id="landscape" class="section-container">
  <div [hidden]="!showCharts" class="results">
    <h2 class="tw-font-semibold tw-to-dark-light-dynamic tw-text-2xl tw-mt-2 tw-mb-0">
      Competitive Intelligence
    </h2>
    <div class="tw-grid tw-gap-4 tw-grid-cols-3 tw-w-full tw-mt-3">
      <!-- Talent Flow sankey -->
      <mat-card class="tw-flex tw-flex-col tw-col-span-3 xl:tw-col-span-2">
        <mat-card-title>
          <h3 class="tw-my-0 tw-text-lg tw-font-semibold">Talent Flow</h3>
        </mat-card-title>
        <mat-card-subtitle>
          For arrivals and departures between {{startDateDate | date: 'MMM YYYY'}} and
          {{endDateDate | date: 'MMM YYYY'}}
        </mat-card-subtitle>
        <mat-card-content class="tw-h-full">
          <div class="tw-overflow-x-auto">
            <div class="tw-min-w-[680px]">
              <highcharts-chart
                [Highcharts]="Highcharts"
                [options]="sankeyChartNoSeries"
                oneToOne="true"
                (chartInstance)="logChartInstance($event, 'companySankey')"></highcharts-chart>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- Top Talent Competition -->
      <mat-card class="tw-flex tw-flex-col tw-max-h-[500px] tw-col-span-3 xl:tw-col-span-1">
        <mat-card-title>
          <h3 class="tw-my-0 tw-text-lg tw-font-semibold">Versus Top Talent Competitors</h3>
        </mat-card-title>

        <mat-card-content class="tw-content-center">
          <div id="chart-container-headcount" class="tw-flex-1">
            <div id="competitionChartContainer" style="width: 100%; height: 100%"></div>
          </div>
          <div class="tw-flex tw-justify-center tw-mt-4">
            <button
              mat-raised-button
              color="primary"
              [disabled]="battleCompanies.length === 0"
              [class.spinner]="battleCompanies.length === 0"
              (click)="goToBattle()">
              View Detailed Benchmarks
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</section>

<!-- Dynamics section -->
<section #trackedSections id="dynamics" class="section-container">
  <div [hidden]="!showCharts" class="results">
    <h2 class="tw-font-semibold tw-to-dark-light-dynamic tw-text-2xl tw-mt-2 tw-mb-0">
      Workforce Dynamics
    </h2>
    <div class="cols-1 tw-my-3">
      <mat-card class="tw-flex tw-flex-col tw-max-h-[500px] tw-col-span-3 xl:tw-col-span-2">
        <mat-card-title>
          <h3 class="tw-my-0 tw-text-lg tw-font-semibold">Arrivals/Departures by</h3>
          <mat-form-field appearance="outline" class="moneyball-select">
            <mat-select
              [formControl]="selectedArrivalsDeparturesChartOption"
              (selectionChange)="arrDepChartOptionChange($event)">
              <mat-option *ngFor="let opt of arrivalsDeparturesChartOptions" [value]="opt">
                {{opt}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button
            [class.spinner]="arrDepDownloading"
            [disabled]="arrDepDownloading"
            mat-stroked-button
            color="primary"
            (click)="downloadArrivalsDeparturesCSV()"
            class="tw-ml-auto tw-shrink-0">
            Download
          </button>
        </mat-card-title>

        <mat-card-content class="tw-w-full">
          <highcharts-chart
            id="arrivalsDeparturesChart"
            [Highcharts]="Highcharts"
            [options]="arrDepChartNoSeries"
            oneToOne="true"
            showLoading="true"
            (chartInstance)="logChartInstance($event, 'arrivalsDeparturesChart')"
            class="tw-mx-0"></highcharts-chart>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="cols-1 tw-my-3">
      <!-- Historical Tenure by -->
      <mat-card class="tw-flex tw-flex-col lg:tw-max-h-[500px] tw-col-span-3 xl:tw-col-span-1">
        <mat-card-title class="tw-flex tw-items-center tw-flex-wrap">
          <h3 class="tw-my-0 tw-text-lg tw-font-semibold tw-max-w-32 md:tw-max-w-none">
            Historical Tenure by
          </h3>
          <mat-form-field
            appearance="outline"
            class="moneyball-select tw-max-w-36 lg:tw-max-w-44 lgx:tw-max-w-none">
            <mat-select
              [formControl]="selectedHistoricalTenureChartOption"
              (selectionChange)="historicalTenureChartOptionChange()">
              <mat-option
                *ngFor="let opt of historicalTenureChartOptions"
                [value]="opt"
                >{{opt}}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <div class="sample-size-picker moneyball-slider lg:tw-ml-auto tw-mr-2">
            <div>Minimum Sample Size: {{minTenureSampleSize}}</div>
            <mat-slider min="1" max="10" step="1" value="{{minTenureSampleSize}}">
              <input matSliderThumb (valueChange)="onMinTenureSampleSizeChanged($event)" />
            </mat-slider>
          </div>
          <mat-form-field
            appearance="outline"
            class="moneyball-select tw-max-w-36 lg:tw-max-w-44 lgx:tw-max-w-none">
            <mat-label>Show Employees</mat-label>
            <mat-select
              [formControl]="selectedTenureChartStatusOption"
              (selectionChange)="historicalTenureChartOptionChange()">
              <mat-option *ngFor="let opt of tenureChartStatusOptions" [value]="opt">
                {{opt}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-title>
        <mat-card-content>
          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="tenureByMonthChartNoSeries"
            oneToOne="true"
            (chartInstance)="logChartInstance($event, 'tenureByMonthChart')"></highcharts-chart>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="cols-1 tw-my-3">
      <mat-card>
        <mat-card-title>
          <h3 class="tw-my-0 tw-text-lg tw-font-semibold">Employee Count by</h3>
          <mat-form-field appearance="outline" class="moneyball-select">
            <mat-select
              [formControl]="selectedEmployeeCountChartOption"
              (selectionChange)="empCountOptionChange($event)">
              <mat-option *ngFor="let opt of employeeCountChartOptions" [value]="opt">
                {{opt}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <button
            [class.spinner]="empCountDownloading"
            [disabled]="empCountDownloading"
            mat-stroked-button
            color="primary"
            (click)="downloadEmployeeCountCSV()"
            class="tw-ml-auto tw-shrink-0">
            Download
          </button>
        </mat-card-title>

        <mat-card-content>
          <highcharts-chart
            id="employeeCountChart"
            [Highcharts]="Highcharts"
            [options]="empCountChartNoSeries"
            oneToOne="true"
            (chartInstance)="logChartInstance($event, 'employeeCountByOptions')"></highcharts-chart>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</section>

<!-- Demographics section -->
<section #trackedSections id="demographics" class="section-container">
  <div [hidden]="!showCharts" class="results">
    <h2 class="tw-font-semibold tw-to-dark-light-dynamic tw-text-2xl tw-mt-2 tw-mb-0">
      Internal Analytics
    </h2>
    <div class="cols-1 tw-my-3">
      <mat-card>
        <mat-card-title>
          <h3 class="tw-my-0 tw-text-lg tw-font-semibold">
            Current Employee Functional Distribution
          </h3>
        </mat-card-title>
        <mat-card-content>
          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="treeChartOptions"
            oneToOne="true"
            (chartInstance)="logChartInstance($event, 'orgChart')"></highcharts-chart>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="tw-grid tw-gap-4 tw-grid-cols-1 lg:tw-grid-cols-2 tw-w-full tw-mt-5">
      <!-- Arrivals/Departures -->
      <mat-card class="tw-flex tw-flex-col tw-max-h-[500px] tw-col-span-1 xl:tw-col-span-1">
        <mat-card-title>
          <h3 class="tw-my-0 tw-text-lg tw-font-semibold">Current Employee Tenure by</h3>
          <mat-form-field appearance="outline" class="moneyball-select">
            <mat-select
              [formControl]="selectedCurrentTenureChartOption"
              (selectionChange)="currentTenureChartOptionChange()">
              <mat-option *ngFor="let opt of currentTenureChartOptions" [value]="opt">
                {{opt}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </mat-card-title>
        <mat-card-content>
          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="currentTenureChartNoSeries"
            oneToOne="true"
            (chartInstance)="logChartInstance($event, 'currentTenureChart')"></highcharts-chart>
        </mat-card-content>
      </mat-card>
      <mat-card class="tw-flex tw-flex-col tw-col-span-1 xl:tw-col-span-1">
        <mat-card-title>
          <h3 class="tw-my-0 tw-text-lg tw-font-semibold">Current Employee Previous Companies</h3>
        </mat-card-title>
        <mat-card-content class="tw-h-full">
          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="top10PreviousCompanyChartNoSeries"
            oneToOne="true"
            (chartInstance)="logChartInstance($event, 'top10PreviousCompany')"></highcharts-chart>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="tw-grid tw-gap-4 tw-grid-cols-1 lg:tw-grid-cols-2 tw-w-full tw-mt-5">
      <mat-card class="tw-flex tw-flex-col tw-col-span-1 xl:tw-col-span-1">
        <mat-card-title>
          <h3 class="tw-my-0 tw-text-lg tw-font-semibold">Current Employee Schools</h3>
        </mat-card-title>
        <mat-card-content class="tw-h-full">
          <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="top10PreviousCompanyChartNoSeries"
            oneToOne="true"
            (chartInstance)="logChartInstance($event, 'top10School')"></highcharts-chart>
        </mat-card-content>
      </mat-card>

      <mat-card class="tw-flex tw-flex-col tw-col-span-1 xl:tw-col-span-1">
        <mat-card-title>
          <h3 class="tw-my-0 tw-text-lg tw-font-semibold">Current US Employee Distribution</h3>
        </mat-card-title>
        <mat-card-content class="tw-h-full">
          <highcharts-chart
            class="tw-h-full"
            [Highcharts]="Highcharts"
            constructorType="mapChart"
            [options]="mapChartNoData"
            oneToOne="true"
            (chartInstance)="logChartInstance($event, 'mapChart')"></highcharts-chart>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</section>
