import { Component, Input, SimpleChanges } from '@angular/core';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import * as Highcharts from 'highcharts';
import { timer, Subscription } from 'rxjs';
import { reportChartLineOptions } from '../highcharts-options';
import { QuickBuild, UniqueIdGenerator } from 'src/app/people/quick-build.service';
import { PeopleReportService } from 'src/app/people/report-service.service';
import { departments, levels, ReportVizChartColors } from '../constants';
import * as moment from 'moment';
import { getPeopleColumnByName } from 'src/app/people/people-columns';
import { Log } from 'src/app/logger.service';
const CHART_ID = 'demographics';

const FIELDS_TO_GROUP_BY = ['jobs.function', 'jobs.level'];

@Component({
  selector: 'app-reports-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss'],
})
export class DemographicsComponent {
  @Input() quickBuild: QuickBuild;
  @Input() reportTarget: string;
  @Input() dateFrom: moment.Moment;
  @Input() dateTo: moment.Moment;
  @Input() field: string;
  @Input() reportId: string;
  @Input() maxSeries: number = 25;
  @Input() allowFieldSelection = true;

  chart: Highcharts.Chart;
  chartId: string;
  subscriptions: Subscription = new Subscription();
  breakdownByOptions = FIELDS_TO_GROUP_BY;
  selectedBreakdownBy: (typeof FIELDS_TO_GROUP_BY)[number] = FIELDS_TO_GROUP_BY[0];

  constructor(private reportService: PeopleReportService) {
    this.chartId = UniqueIdGenerator.generate();
    if (!this.dateFrom || !this.dateTo) {
      this.dateFrom = moment().subtract(2, 'year');
      this.dateTo = moment();
    }
    if (!this.reportId) {
      this.reportId = UniqueIdGenerator.generate();
    }
  }

  ngAfterViewInit(): void {
    timer(100).subscribe(() => {
      try {
        this.initializeChart();
      } catch (error) {
        timer(100).subscribe(() => {
          this.initializeChart();
        });
      }
    });
  }

  initializeChart() {
    this.chart = Highcharts.chart(`highchartsContainer-${this.chartId}`, reportChartLineOptions);
    this.chart.update(demographicsChartNoSeries, true, true);
    this.chart.showLoading();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('field' in changes) {
      Log.d('field changed', this.field);
      this.selectedBreakdownBy = this.field;
    }
    this.resetChartData();
    this.getChartData();
  }

  getChartData() {
    if (!this.quickBuild) return;

    const reportParams = {
      date_from: this.dateFrom.toISOString(),
      date_to: this.dateTo.toISOString(),
      group_by: [this.selectedBreakdownBy],
    };
    this.reportService
      .getReportDataWithReportDetails(
        this.reportId,
        CHART_ID,
        reportParams,
        this.reportTarget,
        this.quickBuild
      )
      .subscribe((data) => {
        Log.d('demographics data', data);
        const series = this.generateChartSeries(data.data.demographics, this.selectedBreakdownBy);
        this.updateChart(series);
      });
  }

  private updateChart(series: any[]) {
    Log.d('demographics updateChart', this.chart, series);
    if (!this.chart) {
      // If chart isn't ready yet, wait for next tick when it should be initialized
      timer(300).subscribe(() => {
        if (this.chart) {
          this.chart.hideLoading();
          this.chart.update(
            {
              title: {
                text: 'Person Count by ' + this.getFieldDisplayName(this.selectedBreakdownBy),
              },
            },
            true,
            true
          );
          series.forEach((s) => {
            this.chart.addSeries(s);
          });
        }
      });
      return;
    }
    this.chart.hideLoading();
    this.chart.update(
      {
        title: {
          text: 'Person Count by ' + this.getFieldDisplayName(this.selectedBreakdownBy),
        },
      },
      true,
      true
    );
    series.forEach((s) => {
      this.chart.addSeries(s);
    });
  }

  onBreakdownByChanged(value: MatButtonToggleChange) {
    if (!value) return;

    this.selectedBreakdownBy = value.value;
    this.resetChartData();
    this.getChartData();
  }

  resetChartData() {
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    this.removeAllSeriesFromChart();
    this.chart?.showLoading();
  }

  removeAllSeriesFromChart() {
    if (!this.chart) return;

    while (this.chart.series.length > 0) {
      this.chart.series[0].remove(false);
    }
  }

  generateChartSeries(inputData: any, byType: string): any[] {
    // The API returns a ton of data sometimes, we filter to just the maxSeries number
    // based on the top counts of data
    const totalKeyCounts: Record<string, number> = {};

    let processed = inputData.reduce((acc: any, d: any) => {
      const keyVal = d['group_values'][0].value;

      if (!acc[keyVal]) {
        acc[keyVal] = {};
      }

      if (!totalKeyCounts[keyVal]) {
        totalKeyCounts[keyVal] = 0;
      }

      totalKeyCounts[keyVal] += d.count_employees;
      acc[keyVal][d.date] = d.count_employees;

      return acc;
    }, {});

    // Find the top 25 keys by count
    const topKeys = Object.entries(totalKeyCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, this.maxSeries)
      .map(([key, _]) => key);

    const indexer =
      byType === 'jobs.function'
        ? (val: string) => departments.indexOf(val)
        : (val: string) => levels.indexOf(val);

    return Object.entries(processed)
      .filter(([key, _]) => topKeys.includes(key))
      .map(([key, value]) => {
        return {
          id: key,
          name: key,
          data: Object.entries(value as Record<string, number>).map(([date, count]) => [
            new Date(date!).valueOf(),
            count,
          ]),
          color: ReportVizChartColors[indexer(key)],
          type: 'line',
        };
      });
  }

  getFieldDisplayName(field: string): string {
    return getPeopleColumnByName(field).displayName;
  }
}

const demographicsChartNoSeries: Highcharts.Options = {
  title: {
    text: 'Person Count',
  },
  yAxis: [
    {
      allowDecimals: false,
      tickAmount: 10,
      title: {
        text: 'Count of People',
      },
      stackLabels: {
        enabled: false,
      },
      plotLines: [
        {
          value: 0,
          color: '#3F83F8',
          width: 3,
          zIndex: 2,
        },
      ],
    },
  ],
  plotOptions: {
    column: {
      stacking: 'normal',
      borderWidth: 0,
      borderColor: 'transparent',
    },
  },
  legend: {
    itemStyle: {
      fontSize: '10px',
    },
    verticalAlign: 'bottom',
  },
  lang: {
    loading: 'Loading chart data...',
  },
};
