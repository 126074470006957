import { Injectable } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';
import { BehaviorSubject, timer } from 'rxjs';

/**
 * Service to manage the state of the Streams Subscribe Drawer
 * This service is used to communicate between the drawer component and other components
 * that need to know about the drawer's state (like the main app component)
 */
@Injectable({
  providedIn: 'root',
})
export class StreamsSubscribeDrawerService {
  private isDrawerOpen = new BehaviorSubject<boolean>(false);

  // Observable that emits the current state of the drawer
  isDrawerOpen$ = this.isDrawerOpen.asObservable();
  filtersSelectedTab$ = new BehaviorSubject<string>('reports');

  constructor(private markdownService: MarkdownService) {}

  // opens droawer & emits state
  openDrawer(filtersSelectedTab: 'moneyball' | 'reports' | 'people' = 'reports') {
    this.filtersSelectedTab$.next(filtersSelectedTab);
    // timer(0).subscribe(() => {
    this.isDrawerOpen.next(true);
    // });
  }

  // closes drawer & emits state
  closeDrawer() {
    this.isDrawerOpen.next(false);
  }

  // returns current state of drawer for use in app component
  isOpen(): boolean {
    return this.isDrawerOpen.value;
  }
}
