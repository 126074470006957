<!-- action btns -->
<div
  *ngIf="canAdmin"
  class="tw-flex tw-items-center tw-gap-2 md:tw-gap-4 tw-flex-row tw-flex-wrap tw-my-4 tw-ml-[.1rem]">
  <button
    mat-raised-button
    [class.spinner]="refreshing"
    [disabled]="refreshing"
    (click)="refreshOrgServiceAccounts()">
    Refresh
  </button>
  <button mat-raised-button color="primary" (click)="showCreateDialog()">
    Create Service Account
  </button>
  <button
    mat-flat-button
    color="warn"
    [disabled]="!rowsSelected"
    (click)="removeSelected()"
    class="disabled:tw-border-gray-400 disabled:tw-text-gray-400 disabled:tw-cursor-not-allowed">
    Remove Selected Service Accounts
  </button>
</div>

<!-- ag-grid table -->
<div class="tw-w-full tw-overflow-x-auto">
  <div class="tw-min-w-[1000px] tw-w-full">
    <ag-grid-angular
      style="width: 100%;"
      [ngClass]="{ 'ag-theme-quartz': !(darkModeService.darkMode$ | async), 'ag-theme-quartz-dark': darkModeService.darkMode$ | async }"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [components]="components"
      [rowSelection]="rowSelection"
      [defaultColDef]="defaultColDef"
      [tooltipShowDelay]="tooltipShowDelay"
      enableCellTextSelection="true"
      ensureDomOrder="true"
      domLayout="autoHeight"
      (gridReady)="onGridReady($event)"
      (selectionChanged)="onSelectionChanged($event)">
    </ag-grid-angular>
  </div>
</div>
