import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

export enum LogLevel {
  Off = 0,
  Error,
  Warning,
  Info,
  Debug,
}

@Injectable({
  providedIn: 'root',
})
export class Log {
  private static level: LogLevel;

  constructor() {
    // Initialize the static log level when the service is first created
    Log.initLogLevel();
  }

  private static initLogLevel(): void {
    // Set default log level based on environment
    Log.level = environment.production ? LogLevel.Error : LogLevel.Debug;

    // Allow runtime override via localStorage
    const storedLevel = localStorage.getItem('logLevel');
    if (storedLevel !== null) {
      Log.level = parseInt(storedLevel, 10);
    }
  }

  static d(message: string, ...data: any[]): void {
    Log.log(LogLevel.Debug, message, data);
  }

  static i(message: string, ...data: any[]): void {
    Log.log(LogLevel.Info, message, data);
  }

  static w(message: string, ...data: any[]): void {
    Log.log(LogLevel.Warning, message, data);
  }

  static e(message: string, ...data: any[]): void {
    Log.log(LogLevel.Error, message, data);
  }

  static setLogLevel(level: LogLevel): void {
    Log.level = level;
    localStorage.setItem('logLevel', level.toString());
  }

  private static log(level: LogLevel, message: string, data: any[]): void {
    // Initialize if not already initialized (in case it's called before Angular initializes the service)
    if (Log.level === undefined) {
      Log.initLogLevel();
    }

    if (level <= Log.level) {
      const timestamp = new Date().toISOString();
      const logPrefix = `[${timestamp}] [${LogLevel[level]}]:`;

      switch (level) {
        case LogLevel.Debug:
          console.debug(logPrefix, message, ...data);
          break;
        case LogLevel.Info:
          console.info(logPrefix, message, ...data);
          break;
        case LogLevel.Warning:
          console.warn(logPrefix, message, ...data);
          break;
        case LogLevel.Error:
          console.error(logPrefix, message, ...data);
          break;
      }
    }
  }
}
