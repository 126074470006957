<div id="filters-panel" class="tw-mb-5" [ngClass]="{'tw-mt-6': !(isEmployee$ | async)}">
  <!-- quick builds -->
  <app-quick-builds
    *ngIf="(isEmployee$ | async) && !embedded"
    [showQuickBuilds]="!collapseQuickBuilds"
    [uiFilters]="uiFilters"
    [columns]="columns"
    (quickBuildSelected)="loadQuickBuild($event)"
    (quickBuildNameSelected)="quickBuildName = $event">
  </app-quick-builds>

  <!-- AI chat -->
  <app-people-chat-to-filters
    *ngIf="!embedded && ((isEmployee$ | async) || (orgSettings | async)?.settings?.people?.features?.chat_to_query)"
    (quickBuild)="quickBuildFromChat($event)"
    [orgId]="orgId"></app-people-chat-to-filters>

  <!-- filters tree expanded -->
  <div
    id="filters-expanded"
    *ngIf="showFilters"
    class="tw-overflow-x-auto tw-text-sm tw-text-gray-800 tw-bg-white dark:tw-bg-gray-700 tw-rounded tw-border tw-border-solid tw-border-gray-300 dark:tw-border-gray-600 tw-relative tw-p-4">
    <div>
      <div class="tw-flex tw-justify-end tw-flex-wrap tw-gap-2 lg:tw-gap-4 tw-items-center tw-mb-4">
        <div class="tw-mr-auto tw-ml-3">
          <!-- QB applied name -->
          <div
            *ngIf="quickBuildName && (isEmployee$ | async)"
            class="tw-flex tw-items-center tw-gap-1 tw-text-xs tw-text-primary_purple-600 tw-mt-[-9px] tw-mb-[3px] dark:tw-text-primary_purple-500 tw-rounded tw-py-1">
            <!-- <mat-icon class="tw-h-[16px] tw-w-[18px] tw-text-base tw-leading-[14px]">
              check
            </mat-icon> -->
            QB applied:
            <span
              id="quick-build-name"
              class="tw-font-semibold tw-truncate tw-max-w-[300px] lg:tw-max-w-none tw-overflow-hidden tw-whitespace-nowrap">
              {{ quickBuildName }}
            </span>
          </div>

          <!-- heading -->
          <h3 class="tw-font-semibold tw-text-gray-800 dark:tw-text-white tw-mt-0 tw-mb-0">
            All matching contacts where:
          </h3>
        </div>

        <!-- Root-only Delete Button -->
        <button
          *ngIf="rootFilterGroup.filters.length > 0"
          (click)="deleteAllFilters()"
          type="button"
          class="tw-inline-flex tw-items-center tw-rounded tw-bg-white tw-px-2 tw-py-1 tw-text-sm tw-font-medium tw-text-red-700 tw-ring-1 tw-ring-inset tw-ring-gray-300 dark:tw-ring-gray-600 hover:tw-bg-red-50 focus:tw-z-10 dark:tw-bg-gray-800 dark:hover:tw-bg-red-700/5 dark:tw-text-red-400"
          matTooltip="Delete All Filters"
          matTooltipPosition="above">
          <mat-icon class="tw-text-[18px] tw-leading-[23px]">delete_outline</mat-icon>
          <span class="tw-hidden lg:tw-inline-block">Delete All Filters</span>
        </button>

        <!-- admin buttons -->
        <button
          *ngIf="isEmployee$ | async"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="open more actions"
          class="tw-hidden lg:tw-inline-flex tw-w-auto tw-h-auto tw-text-gray-800 tw-rounded-md tw-border tw-border-solid tw-border-gray-300 tw-leading-8 focus:tw-bg-gray-100 hover:tw-bg-gray-100 dark:focus:tw-bg-gray-900 dark:hover:tw-bg-gray-900 tw-transition-all dark:tw-text-white dark:tw-border-gray-500 tw-px-4">
          <mat-icon>more_horiz</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="copyQuickBuildToClipboard()">
            <mat-icon>content_copy</mat-icon>
            <span>Copy QB to Clipboard</span>
          </button>
          <button mat-menu-item (click)="importQBFromClipboard()">
            <mat-icon>content_paste</mat-icon>
            <span>Import QB from Clipboard</span>
          </button>
          <button mat-menu-item (click)="showAddQuickBuildDialog(null)">
            <mat-icon>bookmark_outline</mat-icon>
            <span>Save as Quick Build</span>
          </button>
        </mat-menu>

        <button
          *ngIf="isEmployee$ | async"
          (click)="showAddQuickBuildDialog(null)"
          type="button"
          class="tw-inline-flex lg:tw-hidden tw-items-center tw-rounded tw-bg-white tw-px-2 tw-py-1 tw-text-sm tw-font-medium tw-text-primary_purple-600 tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50 focus:tw-z-10 dark:tw-bg-gray-800 dark:hover:tw-bg-gray-700 dark:tw-ring-gray-600 dark:tw-text-teal-400"
          matTooltip="Save as Quick Build"
          matTooltipPosition="above">
          <mat-icon class="tw-text-[18px] tw-leading-[23px]"> bookmark_outline </mat-icon>
        </button>
      </div>

      <div class="tw-min-w-[1500px] xl:tw-min-w-[1700px]">
        <app-filter-group
          [group]="rootFilterGroup"
          [isRoot]="true"
          [depth]="0"
          (filterAdded)="handleFilterAdded($event)"
          (chartAdded)="handleChartAdded($event)"
          class="root-filter-group">
        </app-filter-group>
      </div>
    </div>
    <button
      mat-raised-button
      color="primary"
      class="tw-shrink-0"
      [class.spinner]="refreshing"
      [disabled]="refreshing"
      (click)="runSearch()">
      Search
    </button>
  </div>

  <!-- filter chips -->
  <app-filter-chips
    [rootFilterGroup]="rootFilterGroup"
    [showFilters]="showFilters"
    [categories]="categories"
    (filterAdded)="handleFilterAdded($event)"
    (filterRemoved)="removeFilterChip($event)">
  </app-filter-chips>

  <div
    class="tw-w-100 tw-flex tw-justify-center"
    [ngClass]="{ 'tw-mt-[-16px]': showFilters, 'tw-mt-[-8px]': !showFilters }">
    <button
      *ngIf="showFilters"
      mat-stroked-button
      color="primary"
      class="tw-bg-white dark:tw-bg-gray-800 dark:tw-text-teal-400"
      matTooltip="Hide Filters Toolbar"
      matTooltipPosition="right"
      (click)="toggleFiltersContent()">
      <mat-icon class="tw-font-bold">keyboard_arrow_up</mat-icon>
    </button>
    <button
      *ngIf="!showFilters"
      color="primary"
      class="tw-text-sm tw-flex tw-items-center tw-text-primary_purple-600 tw-border tw-border-solid tw-border-gray-300 dark:tw-border-gray-700 tw-rounded tw-bg-white dark:tw-bg-gray-800 dark:tw-text-teal-400 focus:tw-bg-slate-100 focus:tw-border-2"
      matTooltip="Show Filters Toolbar"
      matTooltipPosition="right"
      (click)="toggleFiltersContent()">
      <mat-icon class="tw-font-bold tw-leading-6">keyboard_arrow_down</mat-icon>
      Show/Edit Filters
    </button>
  </div>
</div>

<div class="tw-flex tw-flex-wrap tw-gap-3 tw-items-center tw-mt-5">
  <button
    *ngIf="!embedded"
    mat-raised-button
    class="tw-shrink-0"
    [class.spinner]="downloading"
    [disabled]="downloading"
    (click)="download()">
    Download
  </button>
  <button
    *ngIf="!embedded"
    mat-raised-button
    class="tw-shrink-0"
    (click)="copyFiltersToClipboard()">
    Copy API Request
  </button>
  <button
    *ngIf="!embedded"
    [disabled]="!apiResponse"
    mat-raised-button
    class="tw-shrink-0"
    (click)="copyApiResponseToClipboard()">
    Copy API Response
  </button>
</div>

<div class="tw-relative">
  <mat-tab-group class="tw-mt-2 tw-flex-grow">
    <mat-tab label="Moneyball" *ngIf="selectedTab === 'moneyball'">
      <div class="tw-my-4">
        <app-reports-prev-next-flow
          class="tw-col-span-2 tw-w-full tw-mt-3"
          [quickBuild]="setQuickBuild"
          [reportTarget]="reportTarget"
          [dateFrom]="startDateDate"
          [dateTo]="endDateDate"></app-reports-prev-next-flow>
      </div>
      <div class="tw-my-6">
        <app-reports-job-starts-and-ends
          class="tw-col-span-3"
          [quickBuild]="setQuickBuild"
          [reportTarget]="reportTarget"
          [dateFrom]="startDateDate"
          [showAIAnalysis]="true"
          [dateTo]="endDateDate"></app-reports-job-starts-and-ends>
      </div>
      <div class="tw-my-6">
        <app-reports-demographics
          class="tw-col-span-3"
          [quickBuild]="setQuickBuild"
          [reportId]="'general-demographics'"
          [dateFrom]="startDateDate"
          [dateTo]="endDateDate"></app-reports-demographics>
      </div>
      <div class="tw-my-6">
        <app-reports-functional-distribution
          [quickBuild]="setQuickBuild"
          [reportId]="'functional-distribution'"></app-reports-functional-distribution>
      </div>
      <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
        <div class="tw-my-6">
          <app-reports-tenure-current-breakdown
            [quickBuild]="setQuickBuild"
            [reportTarget]="reportTarget"
            [field]="'education.school'"></app-reports-tenure-current-breakdown>
        </div>
        <div class="tw-my-6">
          <app-reports-demographics-current
            [quickBuild]="setQuickBuild"
            [reportTarget]="reportTarget"
            [field]="'jobs.metadata.previous_job.company.name'"
            [allowFieldSelection]="false"></app-reports-demographics-current>
        </div>
      </div>
      <div class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-4">
        <div class="tw-my-6">
          <app-reports-demographics-current
            [quickBuild]="setQuickBuild"
            [reportId]="'school-demographics'"
            [field]="'education.school'"
            [allowFieldSelection]="false"></app-reports-demographics-current>
        </div>
        <div class="tw-my-6">
          <app-reports-map-us-states
            [quickBuild]="setQuickBuild"
            [reportTarget]="reportTarget"></app-reports-map-us-states>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Overview/Insights">
      <ng-template matTabContent>
        <!-- charts -->
        <div *ngFor="let groupedReportIds of allReportIDsGrouped | keyvalue" class="tw-w-full">
          <h3
            class="tw-mb-0 xxl:tw-mb-2 tw-font-semibold tw-text-gray-800 dark:tw-text-gray-200 tw-mt-8">
            {{ groupedReportIds.key }}
          </h3>
          <div
            class="overview-insights-tab-viz tw-grid tw-grid-cols-1 sm:tw-grid-cols-[repeat(auto-fit,minmax(450px,1fr))] tw-gap-4">
            <div *ngFor="let reportId of groupedReportIds.value">
              <app-viz-from-quickbuild-factory
                [quickBuild]="setQuickBuild"
                [reportId]="reportId"></app-viz-from-quickbuild-factory>
            </div>
          </div>

          <div *ngFor="let chart of chartOptions | keyvalue" class="tw-flex tw-flex-col">
            <!-- charts buttons -->
            <div class="charts-actions tw-flex tw-justify-between tw-mt-4 xl:tw-mt-0 tw-mb-2 ">
              <div class="tw-flex tw-gap-1 tw-ml-auto">
                <button
                  matButton
                  type="button"
                  (click)="downloadChartData(chart.key)"
                  matTooltip="Download this chart data as CSV"
                  matTooltipPosition="above"
                  class="tw-text-gray-600 tw-bg-white tw-border tw-border-solid tw-border-gray-500 tw-rounded hover:tw-text-gray-700 hover:tw-border-gray-600 hover:tw-bg-gray-50 tw-transition-all dark:tw-bg-gray-700 dark:tw-text-gray-200 tw-px-2">
                  <mat-icon class="tw-inline-block tw-font-semibold tw-h-[20px] tw-leading-[24px]">
                    download_outline
                  </mat-icon>
                </button>
              </div>
            </div>

            <!-- charts container -->
            <div class="tw-h-[400px] mat-card-shadow tw-rounded">
              <div id="tenureChartContainer" style="width: 100%; height: 100%" class="tw-rounded">
                <highcharts-chart
                  #filterChart
                  [attr.id]="chart.key"
                  [Highcharts]="Highcharts"
                  [options]="chart.value.options"
                  style="width: 100%; height: 400px; display: block;">
                </highcharts-chart>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="People">
      <!-- table -->
      <div class="tw-flex tw-flex-row tw-gap-2 tw-w-full tw-mt-7 tw-flex-wrap">
        <h3
          *ngIf="searchCount > 50"
          class="tw-mb-2 tw-font-semibold tw-text-gray-800 dark:tw-text-gray-200">
          Sample of <span class="tw-font-bold">50</span> matching people
        </h3>
        <div class="tw-flex tw-flex-col tw-gap-2 tw-w-full">
          <div *ngIf="peopleGridType === 'sample'" class="tw-mb-4">
            <app-alert [alertType]="AlertType.Info" [style]="'compact'">
              <p class="tw-my-2">
                Preview data below includes limited columns and rows. Download the data for the full
                dataset.
              </p>
            </app-alert>
          </div>
          <ag-grid-angular
            style="height: 100%; width: 100%"
            [ngClass]="{ 'ag-theme-balham': !(darkModeService.darkMode$ | async), 'ag-theme-balham-dark': darkModeService.darkMode$ | async }"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            suppressContextMenu="true"
            [defaultColDef]="defaultColDef"
            (gridReady)="onGridReady($event)"
            [domLayout]="'autoHeight'">
          </ag-grid-angular>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div
    class="tw-absolute tw-top-0 md:tw-top-10 md:tw-right-4 tw-transform tw--translate-y-1/2 tw-text-sm tw-text-gray-800 dark:tw-text-gray-200 tw-pointer-events-none">
    <div class="count tw-flex tw-items-center tw-ml-0 xl:tw-ml-4">
      <mat-progress-spinner
        mode="indeterminate"
        class="tw-mr-3"
        diameter="20"
        *ngIf="countUpdating; else contactCount">
      </mat-progress-spinner>
      <ng-template #contactCount>
        <span class="tw-font-semibold tw-mr-2 tw-rounded">{{searchCount | number}}</span>
      </ng-template>
      Matching Contacts
    </div>
  </div>
</div>
