import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { SearchService, Company } from 'ldt-dw-reader-service-api';
import {
  COMPANY_FIELD_CONFIG,
  EditCompanyIdentifierBaseComponent,
} from '../edit-company-identifier-base/edit-company-identifier-base.component';
import { Observable } from 'rxjs';
import { CompanyInfoService } from 'src/app/shared/company-search/company-info-service.service';
@Component({
  selector: 'app-edit-ticker-dialog',
  templateUrl: '../edit-company-identifier-base/edit-company-identifier-base.component.html',
  providers: [
    {
      provide: COMPANY_FIELD_CONFIG,
      useValue: {
        fieldName: 'Ticker',
        fieldKey: 'ticker',
        title: 'Edit Company Ticker',
        forceUpperCase: true,
      },
    },
  ],
})
export class EditTickerDialogComponent
  extends EditCompanyIdentifierBaseComponent
  implements OnInit
{
  constructor(
    dialogRef: MatDialogRef<EditTickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { company: Company },
    dwService: SearchService,
    fb: FormBuilder,
    notify: NotificationService,
    companyInfoService: CompanyInfoService
  ) {
    super(dialogRef, dwService, fb, notify, companyInfoService, {
      fieldName: 'Ticker',
      fieldKey: 'ticker',
      title: 'Edit Company Ticker',
      forceUpperCase: true,
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.company = this.data.company;
    this.loadData();
  }

  protected updateCompanyField(company: Company, value: string | null): Observable<void> {
    return this.companyInfoService.updateCompanyField(company, 'ticker', value);
  }
}
