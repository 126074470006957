<div
  class="filter-group tw-relative tw-mb-4"
  [ngClass]="{
    'empty-group': isEmptyGroup(), 
    'tw-bg-white dark:tw-bg-gray-700': isEvenDepth(), 
    'tw-bg-gray-100 dark:tw-bg-gray-800': !isEvenDepth(),
    'tw-pt-6': group.isJobsGroup,
    'tw-p-4 tw-pb-3': isEmptyGroup() && group.isJobsGroup }">
  <!-- editable group name & btns -->
  <h4
    *ngIf="group.isJobsGroup"
    class="tw-absolute tw-top-[-14px] tw-left-[20px] tw-h-9 tw-bg-white tw-border tw-border-solid tw-border-filters-tree-grey tw-rounded tw-flex tw-items-center tw-text-[13px] tw-font-semibold dark:tw-bg-gray-800 tw-m-0 tw-pl-[10px]">
    <button
      *ngIf="!editingGroupName"
      (click)="toggleEdit()"
      class="tw-w-6 tw-h-6 tw-text-dark-light-dynamic tw-rounded tw-flex tw-items-center tw-justify-center tw-cursor-pointer hover:tw-bg-gray-200 dark:hover:tw-bg-gray-700 focus:tw-border focus:tw-border-solid focus:tw-bg-gray-200 dark:focus:tw-bg-gray-700">
      <mat-icon class="tw-text-[16px] tw-h-[17px] tw-leading-none tw-overflow-visible">
        edit
      </mat-icon>
    </button>

    <span
      *ngIf="!editingGroupName"
      (click)="toggleEdit()"
      class="tw-text-sm tw-text-dark-light-dynamic tw-cursor-pointer tw-mx-1">
      {{ group.name }}
    </span>

    <input
      *ngIf="editingGroupName"
      matInput
      [(ngModel)]="group.name"
      (blur)="saveGroupName()"
      (keydown.enter)="saveGroupName()"
      #groupNameInput
      class="tw-text-sm tw-border-1 tw-border-dashed tw-border-gray-300 tw-bg-gray-100 tw-mx-1 tw-my-1.5 tw-pl-1" />

    <button
      matTooltip="Only match people where a job meets all of these criteria. Multiple job groups can be added."
      matTooltipPosition="above"
      class="tw-rounded focus:tw-border focus:tw-border-solid focus:tw-bg-gray-200 dark:focus:tw-bg-gray-700 dark:focus:tw-border-gray-200">
      <mat-icon
        class="tw-text-[18px] tw-w-[18px] tw-h-[18px] tw-leading-5 tw-cursor-pointer dark:tw-text-white">
        info_outline
      </mat-icon>
    </button>

    <!-- delete button for non-root + jobs groups -->
    <button
      *ngIf="!isRoot"
      (click)="onDeleteGroup()"
      type="button"
      class="tw-bg-white tw-text-red-600 hover:tw-bg-red-50 focus:tw-bg-red-50 dark:tw-bg-gray-800 dark:hover:tw-bg-red-700/15 dark:focus:tw-bg-red-700/15 dark:tw-text-red-400">
      <mat-icon class="tw-text-[20px] tw-leading-[25px]">delete_outline</mat-icon>
    </button>
  </h4>

  <!-- job status & position status container -->
  <div
    *ngIf="group.isJobsGroup"
    class="tw-flex tw-flex-col lg:tw-flex-row tw-justify-start tw-gap-x-6 tw-gap-y-3 xl:tw-gap-x-8 tw-mb-5 tw-mt-2">
    <!-- job status -->
    <div>
      <mat-icon
        matTooltip="Only match jobs that are active, or ended, or everything."
        matTooltipPosition="above"
        class="tw-text-dark-light-dynamic tw-text-[16px] tw-w-[18px] tw-h-[18px] tw-leading-5 tw-cursor-pointer">
        info_outline
      </mat-icon>
      <mat-label class="tw-text-dark-light-dynamic tw-text-xs tw-font-semibold tw-mr-1">
        Job Status:
      </mat-label>
      <mat-button-toggle-group
        name="jobMatch"
        aria-label="Job Match"
        [(ngModel)]="group.jobsGroupType"
        class="filter-group-btn-toggle tw-text-xs">
        <mat-button-toggle *ngFor="let opt of matchJobsOptions" [value]="opt.value">
          <ng-container *ngIf="group.jobsGroupType === opt.value">
            <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
          </ng-container>
          {{opt.displayValue}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <!-- position status -->
    <div>
      <mat-icon
        matTooltip="Match jobs relative to a person's tenure at the company. Only their first job of the tenure, last job of the tenure, or 'promotion' which is any non-first job of the tenure. 'First' and 'Last' are synonymous with a person's arrival or departure job at the company."
        matTooltipPosition="above"
        class="tw-text-dark-light-dynamic tw-text-[16px] tw-w-[18px] tw-h-[18px] tw-leading-5 tw-cursor-pointer">
        info_outline
      </mat-icon>
      <mat-label class="tw-text-dark-light-dynamic tw-text-xs tw-font-semibold tw-mr-1">
        Position Status
      </mat-label>
      <mat-button-toggle-group
        name="tenureType"
        aria-label="Tenure Type"
        [(ngModel)]="group.positionStatus"
        class="filter-group-btn-toggle tw-text-xs">
        <mat-button-toggle *ngFor="let opt of tenureOptions" [value]="opt.value">
          <ng-container *ngIf="group.positionStatus === opt.value">
            <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
          </ng-container>
          {{opt.displayValue}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <!-- delete button for non-empty + non-root + non-jobs groups -->
  <button
    *ngIf="!isRoot && !isEmptyGroup() && !group.isJobsGroup"
    (click)="onDeleteGroup()"
    type="button"
    class="tw-absolute tw-left-4 tw-top-[-12px] tw-text-red-600 hover:tw-bg-red-50 focus:tw-bg-red-50 tw-bg-white dark:tw-bg-gray-800 dark:hover:tw-bg-gray-900 dark:focus:tw-bg-gray-900 dark:tw-text-red-400 tw-border tw-border-solid tw-border-filters-tree-grey tw-rounded tw-transition-all tw-duration-200">
    <mat-icon class="tw-text-[20px] tw-leading-[25px]">delete_outline</mat-icon>
  </button>

  <!-- group operator change  control -->
  <mat-form-field
    *ngIf="!group.isJobsGroup"
    appearance="outline"
    class="filter-operator-dropdown tw-absolute tw-left-[9px] tw-top-[51px] tw-z-10"
    [ngClass]="{'tw-hidden': isEmptyGroup()}">
    <mat-select [(value)]="group.operator" aria-label="Select filter group operator">
      <mat-option value="and">and</mat-option>
      <mat-option value="or">or</mat-option>
    </mat-select>
  </mat-form-field>

  <div class="group-body">
    <ng-container *ngFor="let item of group?.filters; let i = index">
      <!-- Render SimpleFilterComponent if item is a SearchFilter -->
      <app-simple-filter
        *ngIf="isSimpleFilter(item)"
        [filter]="item"
        (removeFilter)="onRemoveItem(i)"
        [operator]="group.operator"
        [depth]="depth">
      </app-simple-filter>

      <app-filter-company-search
        *ngIf="isCompanySearchFilter(item)"
        [filter]="item"
        (removeFilter)="onRemoveItem(i)"
        [operator]="group.operator"
        [depth]="depth">
      </app-filter-company-search>

      <!-- Render FilterGroupComponent if item is a FilterGroup -->
      <app-filter-group
        *ngIf="isFilterGroup(item)"
        [group]="item"
        [isRoot]="false"
        [depth]="depth + 1"
        [parentOperator]="group.operator"
        [parentGroup]="group"
        (filterAdded)="filterAdded.emit($event)"
        (chartAdded)="chartAdded.emit($event)"
        (removeGroup)="onRemoveItem(i)">
      </app-filter-group>
    </ng-container>
  </div>

  <!-- control btns group -->
  <div
    class="group-footer filter-row query-item tw-flex tw-relative"
    [ngClass]="{'tw-ml-8': !isEmptyGroup()}">
    <span class="field-wrapper tw-isolate tw-inline-flex tw-rounded tw-shadow-sm">
      <button
        (click)="addFilter()"
        type="button"
        class="tw-relative tw-inline-flex tw-items-center tw-rounded-l tw-bg-white tw-px-2 tw-py-1 tw-text-sm tw-font-medium tw-text-primary_purple-600 tw-ring-1 tw-ring-inset tw-ring-filters-tree-grey hover:tw-bg-gray-50 focus:tw-z-10 dark:tw-bg-gray-800 dark:hover:tw-bg-gray-700 dark:tw-text-accent-teal-A200">
        <mat-icon class="tw-text-[20px] tw-leading-6">add</mat-icon>
        <span *ngIf="isRoot">Add Filter</span>
      </button>

      <button
        *ngIf="!group.isJobsGroup"
        (click)="addGroup()"
        type="button"
        class="tw-relative -tw-ml-px tw-inline-flex tw-items-center tw-bg-white tw-px-2 tw-py-1 tw-text-sm tw-font-medium tw-text-primary_purple-600 tw-ring-1 tw-ring-inset tw-ring-filters-tree-grey hover:tw-bg-gray-50 focus:tw-z-10 dark:tw-bg-gray-800 dark:hover:tw-bg-gray-700 dark:tw-text-accent-teal-A200">
        <mat-icon class="tw-text-[20px] tw-leading-[25px]">playlist_add</mat-icon>
        <span *ngIf="isRoot">Add Filter Group</span>
      </button>

      <button
        *ngIf="group.isJobsGroup || isRoot"
        (click)="addChart()"
        type="button"
        class="tw-relative -tw-ml-px tw-inline-flex tw-items-center tw-bg-white tw-px-2 tw-py-1 tw-text-sm tw-font-medium tw-text-primary_purple-600 tw-ring-1 tw-ring-inset tw-ring-filters-tree-grey hover:tw-bg-gray-50 focus:tw-z-10 dark:tw-bg-gray-800 dark:hover:tw-bg-gray-700 dark:tw-text-accent-teal-A200 tw-rounded-r">
        <mat-icon class="tw-text-[20px] tw-leading-[25px]">insights</mat-icon>
        <span *ngIf="isRoot">Add Chart</span>
      </button>

      <!-- delete button for empty non-root groups -->
      <button
        *ngIf="!isRoot && isEmptyGroup()"
        (click)="onDeleteGroup()"
        type="button"
        class="tw-text-red-600 hover:tw-bg-red-50 focus:tw-bg-red-50 tw-bg-transparent dark:hover:tw-bg-red-700/15 dark:focus:tw-bg-red-700/15 dark:tw-text-red-400 tw-ml-1">
        <mat-icon class="tw-text-[20px] tw-leading-[25px]">delete_outline</mat-icon>
      </button>
    </span>
  </div>

  <!-- operator group indicator -->
  <div
    class="connector-and connector-and-group"
    [ngClass]="{'tw-bg-white': !isEvenDepth(), 'tw-bg-gray-100': isEvenDepth(), 'tw-text-gray-500': isEvenDepth(), 'tw-hidden': isRoot}">
    {{ parentOperator || 'and' }}
  </div>

  <!-- selected charts inside filter groups -->
  <div
    *ngIf="group.reports?.length > 0 && !isRoot"
    class="tw-flex tw-flex-wrap tw-gap-1 tw-items-center tw-mr-8 tw-mt-4">
    <div class="tw-font-semibold tw-mr-1 tw-text-dark-light-dynamic">Selected charts:</div>
    <ng-container *ngFor="let chart of group.reports">
      <div
        class="badge-selected-items tw-bg-gray-600 tw-flex tw-items-center tw-gap-2 tw-rounded-md tw-pl-3">
        <span class="tw-font-semibold">{{ chart.name }}</span>
        <button
          class="tw-text-white tw-leading-3 tw-flex tw-items-center hover:tw-bg-primary_purple-800 tw-transition-all">
          <mat-icon
            class="tw-text-[18px] tw-w-[18px] tw-h-[18px]"
            (click)="removeChart(chart.columnName); $event.stopPropagation()">
            close
          </mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</div>

<!-- selected charts for root group -->
<div
  *ngIf="isRoot && group.reports?.length > 0"
  class="tw-flex tw-flex-wrap tw-gap-1 tw-items-center tw-mr-8 tw-ml-8 tw-mb-1"
  [ngClass]="{ 'tw-mt-4': !group?.filters?.length}">
  <div class="tw-font-semibold tw-mr-1 tw-text-dark-light-dynamic">Selected charts:</div>
  <ng-container *ngFor="let chart of group.reports">
    <div
      class="badge-selected-items tw-bg-gray-600 tw-flex tw-items-center tw-gap-2 tw-rounded-md tw-pl-3">
      <span class="tw-font-semibold">{{ chart.name }}</span>
      <button
        class="tw-text-white tw-leading-3 tw-flex tw-items-center hover:tw-bg-primary_purple-800 tw-transition-all">
        <mat-icon
          class="tw-text-[18px] tw-w-[18px] tw-h-[18px]"
          (click)="removeChart(chart.columnName); $event.stopPropagation()">
          close
        </mat-icon>
      </button>
    </div>
  </ng-container>
</div>
