<div class="instructions-container tw-pt-6">
  Locate people in the Live Data Warehouse. Upload a file and get immediate results back.
</div>
<div class="jobs-upload-container tw-pt-4 tw-max-w-[1000px]">
  <div
    class="form well drop-zone icon-upload"
    [class.drag-over]="isDragOver"
    (dragover)="isDragOver = true; dragOver($event)"
    (click)="fileInput.click()"
    (dragexit)="isDragOver = false"
    (dragend)="isDragOver = false"
    (drop)="isDragOver = false; dropFiles($event)">
    <input
      #fileInput
      id="file-input"
      type="file"
      [disabled]="uploadPercent != undefined"
      (change)="uploadFiles($event.target.files)" />
  </div>
  <div class="tw-mt-4">
    <mat-radio-group [(ngModel)]="downloadFormat" class="tw-flex tw-flex-col tw-gap-2">
      <mat-radio-button value="csv">Basic CSV (matched IDs and summary)</mat-radio-button>
      <mat-radio-button value="jsonl">Full Data (JSONL with complete records)</mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="tw-mt-4">
    <div *ngIf="!uploadPercent">
      <button mat-flat-button color="primary" (click)="fileInput.click()">Upload match file</button>
    </div>
    <div *ngIf="uploadPercent">
      <div class="upload-progress">
        <div>Upload Progress: {{ uploadPercent | number: '2.0-0' }}%</div>
        <progress max="100" [value]="uploadPercent"></progress>
      </div>
    </div>
  </div>
  <div *ngIf="uploadError" class="upload-error">{{ uploadError }}</div>
  <div *ngIf="uploadMessage" class="upload-message">{{ uploadMessage }}</div>
</div>
