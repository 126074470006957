<div
  class="tw-flex tw-flex-col tw-col-span-2 xl:tw-col-span-1 tw-h-full tw-max-h-[500px] tw-justify-end">
  <div class="charts-actions tw-flex tw-flex-row tw-justify-end tw-mt-4 xl:tw-mt-0 tw-mb-2">
    <div *ngIf="allowFieldSelection" class="tw-flex tw-flex-wrap tw-items-center tw-space-x-1">
      <span class="tw-text-sm tw-font-medium">Breakdown by:</span>
      <mat-button-toggle-group
        #group="matButtonToggleGroup"
        name="values"
        aria-label="Values"
        (change)="onBreakdownByChanged($event)"
        class="moneyBattleToggle tw-h-6 tw-border tw-border-solid tw-border-gray-300 dark:tw-border-none">
        <mat-button-toggle
          *ngFor="let breakdownBy of breakdownByOptions"
          [value]="breakdownBy"
          class="tw-px-2 tw-h-6 tw-text-xs tw-leading-6 tw-min-w-[32px]"
          [checked]="selectedBreakdownBy === breakdownBy">
          <ng-container *ngIf="selectedBreakdownBy === breakdownBy">
            <mat-icon class="tw-text-dark-light-dynamic">check</mat-icon>
          </ng-container>
          {{ getFieldDisplayName(breakdownBy) }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <div class="tw-h-[400px] mat-card-shadow tw-rounded">
    <div
      id="highchartsContainer-{{ chartId }}"
      style="width: 100%; height: 100%"
      class="tw-rounded"></div>
  </div>
</div>
