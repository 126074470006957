import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sa-create-dialog',
  templateUrl: './sa-create-dialog.component.html',
  styleUrls: ['./sa-create-dialog.component.scss'],
})
export class SaCreateDialogComponent implements OnInit {
  form: FormGroup;
  name: string;
  role: string;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SaCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      name: [this.name, [Validators.required]],
      role: [this.role, [Validators.required]],
    });
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }
}
