<div class="tw-flex tw-justify-between tw-items-center tw-mb-4 tw-gap-4">
  <h2 class="tw-text-lg tw-font-medium dark:tw-text-gray-300 tw-m-0">Chat History</h2>

  <button
    mat-stroked-button
    color="primary"
    (click)="getChats()"
    [class.spinner]="chatsRefreshing"
    [disabled]="chatsRefreshing"
    class="tw-flex-shrink-0">
    Refresh
  </button>

  <button mat-icon-button (click)="toggleDrawer()" class="tw-ml-auto">
    <mat-icon class="dark:tw-text-gray-300">close</mat-icon>
  </button>
</div>

<!-- Chat history list view -->
<div class="chat-history-list tw-overflow-y-auto">
  <!-- loading state msg -->
  <div
    *ngIf="chatsRefreshing"
    class="tw-text-center tw-py-3 tw-text-gray-500 dark:tw-text-gray-400 tw-flex tw-items-center tw-justify-center tw-gap-2">
    <span class="tw-text-sm tw-italic">Just a moment...</span>
  </div>

  <!-- empty state msg -->
  <div
    *ngIf="!chatsRefreshing && chatHistory.length === 0"
    class="tw-text-center tw-py-3 tw-text-gray-500 dark:tw-text-gray-400">
    No chat history available
  </div>

  <!-- Chat history items - only show when not loading or when we have items -->
  <div *ngIf="!chatsRefreshing && chatHistory.length > 0" class="tw-space-y-3 tw-px-1">
    <div
      *ngFor="let chat of chatHistory"
      class="chat-history-item tw-p-4 tw-rounded-lg tw-border tw-border-gray-200 dark:tw-border-gray-700 tw-bg-white dark:tw-bg-gray-800 tw-cursor-pointer hover:tw-bg-gray-50 dark:hover:tw-bg-gray-700 tw-transition-colors tw-shadow-sm hover:tw-shadow"
      (click)="onRowClicked({data: chat})">
      <div class="tw-flex tw-justify-between tw-items-start tw-gap-4">
        <div class="tw-font-medium dark:tw-text-white tw-truncate tw-text-base">
          {{chat.first_prompt || 'Untitled Chat'}}
        </div>
        <div class="tw-text-xs tw-text-gray-500 dark:tw-text-gray-400 tw-min-w-20">
          {{chat.created_at | date:'MMM d, y'}}
        </div>
      </div>
      <div class="tw-flex tw-items-center tw-gap-4 tw-mt-2">
        <div
          class="tw-flex tw-items-center tw-gap-1 tw-text-xs tw-text-gray-500 dark:tw-text-gray-400">
          <mat-icon class="tw-text-[16px] tw-h-4 tw-w-4">question_answer</mat-icon>
          <span>{{chat.prompt_count || 0}} messages</span>
        </div>
        <div
          class="tw-flex tw-items-center tw-gap-1 tw-text-xs tw-text-gray-500 dark:tw-text-gray-400">
          <mat-icon class="tw-text-[16px] tw-h-4 tw-w-4">people</mat-icon>
          <span>{{chat.total_people_count || 0}} people</span>
        </div>
        <div
          class="tw-flex tw-items-center tw-gap-1 tw-text-xs tw-text-gray-500 dark:tw-text-gray-400">
          <mat-icon class="tw-text-[16px] tw-h-4 tw-w-4">query_stats</mat-icon>
          <span>{{chat.quickbuild_count || 0}} queries</span>
        </div>
      </div>
      <div
        *ngIf="chat.user_email"
        class="tw-hidden md:tw-block tw-text-xs tw-text-gray-500 dark:tw-text-gray-400 tw-mt-2">
        <span class="tw-italic">by {{chat.user_email}}</span>
      </div>
    </div>
  </div>
</div>
