<div class="dark:tw-border dark:tw-border-solid dark:tw-border-gray-700 tw-rounded tw-py-2">
  <h2
    mat-dialog-title
    class="tw-text-xl tw-font-bold tw-mb-4 tw-text-dark-light-dynamic tw-px-4 lg:tw-px-6">
    Edit Parent Company
  </h2>

  <mat-dialog-content class="tw-px-4 lg:tw-px-6">
    <div *ngIf="loading" class="tw-flex tw-justify-center tw-items-center tw-h-32">
      <mat-spinner diameter="32"></mat-spinner>
    </div>

    <div *ngIf="!loading" class="tw-mb-4">
      <!-- Current Company Info -->
      <div class="tw-bg-gray-50 dark:tw-bg-gray-700 tw-p-4 tw-rounded">
        <h3 class="tw-text-lg tw-font-semibold tw-mt-0 tw-mb-2 tw-text-gray-700 dark:tw-text-white">
          Current Company
        </h3>

        <!-- company info -->
        <div>
          <div>
            <span class="tw-font-semibold tw-text-sm dark:tw-text-gray-300">Name:</span>
            <span class="tw-text-dark-light-dynamic tw-text-base tw-ml-2">{{company.name}}</span>
          </div>
          <div>
            <span class="tw-font-semibold tw-text-sm dark:tw-text-gray-300">
              Current Parent Company:
            </span>
            <span class="tw-text-dark-light-dynamic tw-text-base tw-ml-2">
              {{company.group_id || 'None'}}
            </span>
          </div>
          <ng-container *ngIf="company.group_id">
            <div>
              <span class="tw-font-semibold tw-text-sm dark:tw-text-gray-300">
                Parent Company Name:
              </span>
              <span class="tw-text-dark-light-dynamic tw-text-base tw-ml-2">
                {{currentParentCompany?.name || 'N/A'}}
              </span>
            </div>
            <div>
              <span class="tw-font-semibold tw-text-sm dark:tw-text-gray-300">
                Parent Company Ticker:
              </span>
              <span class="tw-text-dark-light-dynamic tw-text-base tw-ml-2">
                {{currentParentCompany?.ticker || 'N/A'}}
              </span>
            </div>
            <div>
              <span class="tw-font-semibold tw-text-sm dark:tw-text-gray-300">
                Parent Company LinkedIn:
              </span>
              <span class="tw-text-dark-light-dynamic tw-text-base tw-ml-2">
                {{currentParentCompany?.linkedin_url || 'N/A'}}
              </span>
            </div>
            <div>
              <span class="tw-font-semibold tw-text-sm dark:tw-text-gray-300">
                Parent Company Domain:
              </span>
              <span class="tw-text-dark-light-dynamic tw-text-base tw-ml-2">
                {{currentParentCompany?.domain || 'N/A'}}
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <app-company-search
      [labelText]="'Find a new parent company'"
      [showConglomerates]="true"
      (companySelected)="parentCompanySelected($event)"
      class="people-filters-company-search">
    </app-company-search>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="tw-gap-y-2 tw-px-4 lg:tw-px-6">
    <button mat-button (click)="close()" [disabled]="saving">Cancel</button>

    <!-- Show these buttons when dealing with current value -->
    <ng-container *ngIf="company.group_id">
      <button
        mat-raised-button
        color="warn"
        (click)="removeValue()"
        [disabled]="saving"
        class="tw-mr-2">
        Remove Parent Company
      </button>
    </ng-container>

    <!-- Show these buttons when setting new value -->
    <ng-container *ngIf="newParentCompany && newParentCompany.id !== company.group_id">
      <button
        *ngIf="company.grouped_company_count > 0"
        mat-raised-button
        (click)="updateCompanyAndChildren()"
        [disabled]="saving"
        class="tw-mr-2">
        Update and Move Children
      </button>
      <button mat-raised-button color="primary" (click)="updateCompanyOnly()" [disabled]="saving">
        Update Parent Company
      </button>
    </ng-container>
  </mat-dialog-actions>
</div>
