<div class="app-ledger-container tw-text-gray-800 dark:tw-text-white" width="100%">
  <div class="content-title">
    <a [routerLink]="['/' + orgId + '/ledgers']">Ledgers</a>
    <mat-icon class="navArrow">arrow_forward_ios</mat-icon>
    <div
      class="ledgerName tw-text-gray-800 dark:tw-text-white"
      contenteditable="true"
      (blur)="onLedgerNameChange($event.target)"
      [textContent]="ledger.name"
      (keydown)="ignoreCR($event)"></div>
    <div *ngIf="($canAccessV2 | async) || (isAdmin | async)">
      v1
      <mat-slide-toggle
        [checked]="$ledgerApiV2 | async"
        (change)="setLedgerVersion($event)"></mat-slide-toggle>
      v2
    </div>

    <button
      class="tw-ml-auto"
      mat-raised-button
      color="primary"
      [disabled]="atContactLimit"
      (click)="showUploader()">
      <mat-icon>add</mat-icon> Add Contacts
    </button>
  </div>
  <div
    class="content-description tw-text-gray-800 dark:tw-text-white"
    contenteditable="true"
    attr.placeholder="{{placeholder}}"
    (blur)="onLedgerDescriptionChange($event.target)"
    (keydown)="ignoreCR($event)"
    [textContent]="ledger.description"></div>
  <nav class="ledger-tabs" mat-tab-nav-bar>
    <a
      *ngIf="!($ledgerApiV2 | async)"
      mat-tab-link
      routerLinkActive="active-tab-nav"
      routerLink="./overview"
      >Overview</a
    >
    <a
      *ngIf="$ledgerApiV2 | async"
      mat-tab-link
      routerLinkActive="active-tab-nav"
      routerLink="./overview-v2"
      >Overview</a
    >
    <a
      *ngIf="!($ledgerApiV2 | async)"
      mat-tab-link
      routerLinkActive="active-tab-nav"
      routerLink="./details"
      >Details</a
    >
    <a
      *ngIf="$ledgerApiV2 | async"
      mat-tab-link
      routerLinkActive="active-tab-nav"
      routerLink="./details-v2"
      >Details</a
    >
    <a mat-tab-link routerLinkActive="active-tab-nav" routerLink="./imports"
      >Imports
      <mat-progress-spinner
        *ngIf="(importsInProgress$ | async)?.processing || (importsInProgress$ | async)?.importing"
        matTooltip="Imports are currently in progress"
        mode="indeterminate"
        diameter="20"></mat-progress-spinner>
    </a>
  </nav>
  <div class="noContactsContainer" *ngIf="ledger.size == 0 && !hasImports">
    <div *ngIf="!atContactLimit" class="tw-flex tw-flex-col tw-gap-2 tw-items-center">
      <div class="content-description">
        It looks like you don't have any contacts in this ledger yet. Press the button below to get
        started.
      </div>
      <button
        *ngIf="!atContactLimit"
        mat-raised-button
        color="primary"
        (click)="showUploader()"
        class="tw-w-40">
        <mat-icon>add</mat-icon> Add Contacts
      </button>
    </div>
    <div *ngIf="atContactLimit" class="content-description">
      It looks like you don't have any contacts in this ledger yet, but you're also at your limit.
      Contact sales to increase your limit and add contacts to this ledger.
    </div>
  </div>
  <router-outlet *ngIf="ledger?.size > 0 || hasImports" width="100%"></router-outlet>
</div>
<app-flatfile-uploader
  *ngIf="showSpace"
  [orgId]="orgId"
  [ledgerId]="ledger.id"
  (closeSpaceEvent)="closeSpace()"
  (uploadCompleteEvent)="uploadComplete()">
</app-flatfile-uploader>
