import { DWColumn, ColumnType } from '../data-warehouse/dw-column';

export interface SearchFilterOperator {
  key: string;
  displayName: string;
  inputsRequired: number;
  types: ColumnType[];
}

export function getPeopleColumnByName(name: string): DWColumn {
  // We have a couple of "hidden" columns that can be used for reporting
  // but shouldn't be visible to the user. Well, one for now...
  switch (name) {
    case 'jobs.metadata.previous_job.company.name':
    case 'previous_company':
      return {
        name: 'jobs.metadata.previous_job.company.name',
        displayName: 'Previous Company Name',
        type: ColumnType.string,
        group: 'jobs',
        subcategory: 'company',
      };
    case 'jobs.metadata.next_job.company.name':
    case 'next_company':
      return {
        name: 'jobs.metadata.next_job.company.name',
        displayName: 'Next Company Name',
        type: ColumnType.string,
        group: 'jobs',
        subcategory: 'company',
      };
    default:
      break;
  }
  if (name.startsWith('position.')) {
    name = name.replace('position.', 'jobs.');
  }

  const col = PeopleColumns.find((col) => col.name === name);
  if (!col) {
    throw new Error(`Column ${name} not found`);
  }

  return col;
}

export const PeopleColumns: DWColumn[] = [
  // Company Identifiers subcategory
  {
    name: 'jobs_search',
    displayName: 'Company Search',
    type: ColumnType.companySearch,
    group: 'jobs',
    subcategory: 'company',
    isPopular: true,
  },
  {
    name: 'jobs.company.id',
    displayName: 'LDT Company ID',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'company',
  },
  {
    name: 'jobs.company.linkedin',
    displayName: 'Company LinkedIn ID',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'company',
  },
  {
    name: 'jobs.company.domain',
    displayName: 'Company Domain',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'company',
  },

  // Company Characteristics (Firmo) subcategory
  {
    name: 'jobs.company.name',
    displayName: 'Company Name',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'company',
    isPopular: true,
  },
  {
    name: 'jobs.company.employee_count',
    displayName: 'Company Employee Count',
    type: ColumnType.number,
    group: 'jobs',
    subcategory: 'firmo',
  },
  {
    name: 'jobs.company.industry',
    displayName: 'Company Industry',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'firmo',
  },
  {
    name: 'jobs.company.location',
    displayName: 'Company Location',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'firmo',
    isPopular: true,
  },
  {
    name: 'jobs.company.country',
    displayName: 'Company Country',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'firmo',
    isPopular: true,
  },
  {
    name: 'jobs.company.address',
    displayName: 'Company Address',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'firmo',
  },
  {
    name: 'jobs.company.naics_codes',
    displayName: 'Company NAICS Codes',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'firmo',
  },
  {
    name: 'jobs.company.sic_codes',
    displayName: 'Company SIC Codes',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'firmo',
  },
  {
    name: 'jobs.company.ticker',
    displayName: 'Company Ticker',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'company',
  },
  {
    name: 'jobs.company.type',
    displayName: 'Company Type',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'firmo',
  },
  {
    name: 'jobs.company.ultimate_parent.id',
    displayName: 'Parent Company ID',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'firmo',
  },
  {
    name: 'jobs.company.ultimate_parent.name',
    displayName: 'Parent Company Name',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'firmo',
  },
  {
    name: 'jobs.company.ultimate_parent.ticker',
    displayName: 'Parent Company Ticker',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'firmo',
  },

  // Role Information subcategory
  {
    name: 'jobs.title',
    displayName: 'Job Title',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'role',
    isPopular: true,
  },
  {
    name: 'jobs.function',
    displayName: 'Job Function',
    type: ColumnType.jobfunction,
    group: 'jobs',
    subcategory: 'role',
    isPopular: true,
  },
  {
    name: 'jobs.level',
    displayName: 'Job Level',
    type: ColumnType.joblevel,
    group: 'jobs',
    subcategory: 'role',
    isPopular: true,
  },
  {
    name: 'jobs.location',
    displayName: 'Location',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'location',
    isPopular: true,
  },
  {
    name: 'jobs.location_details.country_code',
    displayName: 'Country Code',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'location',
  },
  {
    name: 'jobs.location_details.region_abbreviation',
    displayName: 'Region/State Abbreviation',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'location',
  },
  {
    name: 'jobs.location_details.msa',
    displayName: 'MSA',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'location',
  },
  {
    name: 'jobs.direct_dial_number',
    displayName: 'Direct Dial Number',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'role',
  },
  {
    name: 'jobs.email',
    displayName: 'Email',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'role',
  },
  {
    name: 'jobs.email_status',
    displayName: 'Email Status',
    type: ColumnType.string,
    group: 'jobs',
    subcategory: 'role',
  },

  // Dates and Tenure subcategory
  {
    name: 'jobs.started_at',
    displayName: 'Job Started At',
    type: ColumnType.date,
    group: 'jobs',
    subcategory: 'dates',
    isPopular: true,
  },
  {
    name: 'jobs.ended_at',
    displayName: 'Job Ended At',
    type: ColumnType.date,
    group: 'jobs',
    subcategory: 'dates',
    isPopular: true,
  },
  {
    name: 'jobs.duration',
    displayName: 'Job Duration (mos)',
    type: ColumnType.number,
    group: 'jobs',
    subcategory: 'dates',
  },
  {
    name: 'jobs.company_tenure',
    displayName: 'Tenure at Company (mos)',
    type: ColumnType.number,
    group: 'jobs',
    subcategory: 'dates',
  },
  {
    name: 'jobs.started_at_year_only',
    displayName: 'Job Started At Year Only',
    type: ColumnType.boolean,
    group: 'jobs',
    subcategory: 'dates',
  },
  {
    name: 'jobs.ended_at_year_only',
    displayName: 'Job Ended At Year Only',
    type: ColumnType.boolean,
    group: 'jobs',
    subcategory: 'dates',
  },

  // Non-jobs columns
  {
    name: 'company_change_detected_at',
    displayName: 'Company Change Detected',
    type: ColumnType.date,
    group: 'metadata',
  },
  {
    name: 'country',
    displayName: 'Country',
    type: ColumnType.string,
    group: 'personal',
    subcategory: 'location',
    isPopular: true,
  },
  {
    name: 'location',
    displayName: 'Location',
    type: ColumnType.string,
    group: 'personal',
    subcategory: 'location',
    isPopular: true,
  },
  {
    name: 'location_details.country_code',
    displayName: 'Country Code',
    type: ColumnType.string,
    group: 'personal',
    subcategory: 'location',
  },
  {
    name: 'location_details.region_abbreviation',
    displayName: 'Region/State Abbreviation',
    type: ColumnType.string,
    group: 'personal',
    subcategory: 'location',
  },
  {
    name: 'location_details.msa',
    displayName: 'MSA',
    type: ColumnType.string,
    group: 'personal',
    subcategory: 'location',
    isPopular: true,
  },
  {
    name: 'id',
    displayName: 'LDT Person ID',
    type: ColumnType.string,
    group: 'personal',
    subcategory: 'identity',
  },
  {
    name: 'name',
    displayName: 'Full Name',
    type: ColumnType.string,
    group: 'personal',
    subcategory: 'identity',
  },
  {
    name: 'first_name',
    displayName: 'First Name',
    type: ColumnType.string,
    group: 'personal',
    subcategory: 'identity',
  },
  {
    name: 'last_name',
    displayName: 'Last Name',
    type: ColumnType.string,
    group: 'personal',
    subcategory: 'identity',
  },
  {
    name: 'cell_phone_number',
    displayName: 'Cell Phone',
    type: ColumnType.string,
    group: 'personal',
  },
  {
    name: 'employment_status',
    displayName: 'Employment Status',
    type: ColumnType.string,
    group: 'personal',
  },
  {
    name: 'education.degree',
    displayName: 'Education Degree Name',
    group: 'education',
    type: ColumnType.string,
  },
  {
    name: 'education.ended_at',
    displayName: 'Education End Date',
    type: ColumnType.date,
    group: 'education',
  },
  {
    name: 'education.field',
    displayName: 'Education Field',
    type: ColumnType.string,
    group: 'education',
  },
  {
    name: 'education.school',
    displayName: 'School Name',
    type: ColumnType.string,
    group: 'education',
  },
  {
    name: 'education.started_at',
    displayName: 'Education Start Date',
    type: ColumnType.date,
    group: 'education',
  },
  {
    name: 'linkedin',
    displayName: 'LinkedIn Profile',
    type: ColumnType.string,
    group: 'personal',
    subcategory: 'identity',
  },
  {
    name: 'connections',
    displayName: 'Number of Connections',
    type: ColumnType.number,
    group: 'personal',
  },
];

export const SupportedOperators: { [key: string]: SearchFilterOperator } = {
  fuzzyMatch: {
    key: 'fuzzyMatch',
    displayName: 'Matches (fuzzy)',
    inputsRequired: 1,
    types: [ColumnType.string],
  },
  fuzzyNotMatch: {
    key: 'fuzzyNotMatch',
    displayName: 'Does not match (fuzzy)',
    inputsRequired: 1,
    types: [ColumnType.string],
  },
  equals: {
    key: 'equals',
    displayName: 'Equals',
    inputsRequired: 1,
    types: [
      ColumnType.string,
      ColumnType.date,
      ColumnType.number,
      ColumnType.boolean,
      ColumnType.joblevel,
      ColumnType.jobfunction,
      ColumnType.companySearch,
    ],
  },
  notEqual: {
    key: 'notEqual',
    displayName: 'Does not equal',
    inputsRequired: 1,
    types: [
      ColumnType.string,
      ColumnType.date,
      ColumnType.number,
      ColumnType.boolean,
      ColumnType.joblevel,
      ColumnType.jobfunction,
      ColumnType.companySearch,
    ],
  },
  blank: {
    key: 'blank',
    displayName: 'Blank',
    inputsRequired: 0,
    types: [
      ColumnType.string,
      ColumnType.date,
      ColumnType.number,
      ColumnType.boolean,
      ColumnType.joblevel,
      ColumnType.jobfunction,
    ],
  },
  notBlank: {
    key: 'notBlank',
    displayName: 'Not blank',
    inputsRequired: 0,
    types: [
      ColumnType.string,
      ColumnType.date,
      ColumnType.number,
      ColumnType.boolean,
      ColumnType.joblevel,
      ColumnType.jobfunction,
    ],
  },
  greaterThanOrEqual: {
    key: 'greaterThanOrEqual',
    displayName: 'Greater than or equal to',
    inputsRequired: 1,
    types: [ColumnType.date, ColumnType.number],
  },
  lessThanOrEqual: {
    key: 'lessThanOrEqual',
    displayName: 'Less than or equal to',
    inputsRequired: 1,
    types: [ColumnType.date, ColumnType.number],
  },
  lessThan: {
    key: 'lessThan',
    displayName: 'Less than',
    inputsRequired: 1,
    types: [ColumnType.number],
  },
  greaterThan: {
    key: 'greaterThan',
    displayName: 'Greater than',
    inputsRequired: 1,
    types: [ColumnType.number],
  },
  inRange: {
    key: 'inRange',
    displayName: 'In range',
    inputsRequired: 2,
    types: [ColumnType.date, ColumnType.number],
  },
};
