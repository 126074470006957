import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CompaniesService,
  Company,
  CompanyAlias,
  FindCompanyAliasesRequest,
  QueryDwRequest,
} from 'ldt-dw-reader-service-api';
import { NotificationService } from 'src/app/shared/notification-service/notification.service';
import { DefaultService } from 'ldt-dw-reader-service-api';
import { SelectedCompany } from 'src/app/shared/company-search/company-search.component';
import { SearchService } from 'ldt-dw-reader-service-api';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { DarkModeService } from 'src/app/shared/dark-mode/dark-mode.service';
import { EditTickerDialogComponent } from './edit-ticker-dialog/edit-ticker-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EditDomainDialogComponent } from './edit-domain-dialog/edit-domain-dialog.component';
import { EditCompanyGroupDialogComponent } from './edit-company-group/edit-company-group.component';
import { forkJoin } from 'rxjs';
import { CompanyInfoService } from 'src/app/shared/company-search/company-info-service.service';
import { AlertType } from 'src/app/shared/alert/alert.component';

type CompanyAliasWithImpact = CompanyAlias & { impact: number };

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss'],
})
export class CompaniesComponent {
  companyJson: Company;
  // TODO: change to strongly typed form group
  inputFormGroup: UntypedFormGroup;
  refreshing: boolean = false;
  replaying: boolean = false;
  AlertType = AlertType;

  /// AG -GRID --------------
  defaultColDef = {
    sortable: true,
    floatingFilter: true,
    resizable: true,
    filter: true,
    menuTabs: ['generalMenuTab', 'filterMenuTab'],
  };
  childCompanyColumnDefs: ColDef[] = [
    {
      field: 'id',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: { value: string }) => {
        if (!params.value) {
          return '';
        } else {
          return (
            '<a href="/datawarehouse/companies?id=' +
            params.value +
            '" target=_blank>' +
            params.value +
            '</a>'
          );
        }
      },
    },
    { field: 'name', filter: 'agTextColumnFilter' },
    {
      field: 'linkedin',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: { value: string }) => {
        if (!params.value) {
          return '';
        } else {
          return (
            '<a href="https://www.linkedin.com/company/' +
            params.value +
            '" target=_blank>' +
            params.value +
            '</a>'
          );
        }
      },
    },
    { field: 'ticker', filter: 'agTextColumnFilter' },
    { field: 'domain', filter: 'agTextColumnFilter' },
  ];
  childCompanies: Company[];

  aliasSourcesColumnDefs: ColDef[] = [
    {
      field: 'key',
      filter: 'agTextColumnFilter',
    },
    { field: 'keyType', filter: 'agTextColumnFilter' },
    { field: 'updatedAt', filter: 'agTextColumnFilter' },
    { field: 'impact', filter: 'agTextColumnFilter' },
  ];
  aliasSources: CompanyAliasWithImpact[];
  aliasGrid: GridApi;
  aliasAway: CompanyAlias | null;
  count_all_time_employees: number;

  constructor(
    private route: ActivatedRoute,
    private companiesService: CompaniesService,
    private notify: NotificationService,
    private _formBuilder: UntypedFormBuilder,
    private router: Router,
    private companyReplayService: DefaultService,
    private dwService: SearchService,
    public darkModeService: DarkModeService,
    private dialog: MatDialog,
    private companyInfoService: CompanyInfoService
  ) {}

  ngOnInit() {
    this.inputFormGroup = this._formBuilder.group({
      id: ['', [Validators.required]],
    });

    if (this.route.snapshot.queryParams.id) {
      this.inputFormGroup.setValue({ id: this.route.snapshot.queryParams.id });
      this.getCompany(this.route.snapshot.queryParams.id);
    }
  }

  companySelected(company: SelectedCompany) {
    this.getCompany(company.company.id);
  }

  getCompany(id: string) {
    if (!id) {
      return;
    }

    let url = '/datawarehouse/companies?id=' + id.trim();
    this.router.navigateByUrl(url);

    this.refreshing = true;
    this.companiesService.getCompanyByLookup(id.trim()).subscribe({
      next: (company) => {
        this.companyJson = company as Company;
        this.refreshing = false;
        this.getChildCompanyData();
        this.getCompanyAliases();
        this.getCompanyStats();
      },
      error: (err) => {
        this.refreshing = false;
        console.error(err);
        this.notify.error('Failed to load company: ' + id);
      },
    });
  }

  refresh() {
    if (!this.companyJson) {
      return;
    }

    this.getCompany(this.companyJson.id!);
  }

  replayCompany() {
    if (!this.companyJson) {
      return;
    }

    const linkedin = this.companyJson.linkedin;
    if (!linkedin) {
      this.notify.error('Company does not have a linkedin');
      return;
    }

    this.replaying = true;
    this.companyReplayService
      .replayCompanies({
        lookups: [linkedin],
      })
      .subscribe({
        next: () => {
          this.notify.success('Company replay started');
          this.replaying = false;
        },
        error: () => {
          this.notify.error('Failed to replay company: ' + linkedin);
          this.replaying = false;
        },
      });
  }

  onAliasGridReady(params: GridReadyEvent) {
    this.aliasGrid = params.api;
  }

  openEditTickerDialog() {
    if (!this.companyJson?.id) return;

    const dialogRef = this.dialog.open(EditTickerDialogComponent, {
      width: '600px',
      data: { company: this.companyJson },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refresh();
      }
    });
  }

  openEditDomainDialog() {
    if (!this.companyJson?.id) return;

    const dialogRef = this.dialog.open(EditDomainDialogComponent, {
      width: '600px',
      data: { company: this.companyJson },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refresh();
      }
    });
  }

  openEditGroupDialog() {
    if (!this.companyJson?.id) return;

    const dialogRef = this.dialog.open(EditCompanyGroupDialogComponent, {
      width: '600px',
      data: {
        company: this.companyJson,
        childCompanies: this.childCompanies,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refresh();
      }
    });
  }

  moveGroupsToAlias() {
    if (this.aliasAway === null) {
      return;
    }

    forkJoin(
      this.childCompanies.map((company) => {
        return this.companyInfoService.updateCompanyField(
          company as Company,
          'group_id',
          this.aliasAway!.id
        );
      })
    ).subscribe({
      next: () => this.notify.success('Groups moved to alias'),
    });
  }

  resetCount() {
    this.companyInfoService
      .updateCompanyField(
        this.companyJson,
        'grouped_company_count',
        this.childCompanies?.length || 0
      )
      .subscribe({
        next: () => this.notify.success('Group count reset'),
      });
  }

  private getCompanyStats() {
    const osQuery = {
      query: {
        term: {
          'jobs.company.ultimate_parent.id.keyword': this.companyJson.id,
        },
      },
      size: 0,
      track_total_hits: true,
    };
    const req: QueryDwRequest = {
      query: osQuery,
      index: 'people',
    };
    this.dwService.queryDw(req).subscribe({
      next: (res: any) => {
        this.count_all_time_employees = res.hits.total.value;
      },
      error: (err) => {
        console.error('Error fetching people count', err);
      },
    });
  }

  private getChildCompanyData() {
    const osQuery = {
      query: {
        term: {
          'group_id.keyword': this.companyJson.id,
        },
      },
      size: 1000,
    };
    const req: QueryDwRequest = {
      query: osQuery,
      index: 'companies',
    };
    this.dwService.queryDw(req).subscribe({
      next: (res: any) => {
        this.childCompanies = res.hits.hits.map((hit: any) => hit._source);
      },
      error: (err) => {
        console.error('Error fetching child companies', err);
      },
    });
  }

  private getCompanyAliases() {
    let req: FindCompanyAliasesRequest = {
      keys: [
        {
          key: this.companyJson.linkedin,
          key_type: 'linkedin',
        },
      ],
    };
    this.companiesService.findCompanyAliases(req).subscribe({
      next: (res) => {
        if (res.length > 0 && res[0].id) {
          this.aliasAway = res[0];
        } else {
          this.aliasAway = null;
        }
      },
    });

    this.companiesService.listCompanyAliases(this.companyJson.id!).subscribe({
      next: (res) => {
        this.aliasSources = res.companyAliases as CompanyAliasWithImpact[];
        this.getAliasImpacts();
      },
    });
  }

  private getAliasImpacts() {
    if (!this.aliasSources) {
      return;
    }

    // for some reason some aliases don't have a keytype -- ignore those
    this.aliasSources
      .filter((alias) => alias.keyType)
      .forEach((alias) => {
        const query = this.getAliasImpactsQuery(alias);
        const req: QueryDwRequest = {
          query: query,
          index: 'people',
        };
        this.dwService.queryDw(req).subscribe({
          next: (res: any) => {
            alias.impact = res.hits.total.value;
            this.aliasGrid.refreshCells();
          },
        });
      });
  }

  private getAliasImpactsQuery(alias: CompanyAlias) {
    if (alias.keyType === CompanyAlias.KeyTypeEnum.Lipid) {
      return {
        query: {
          nested: {
            path: 'jobs',
            query: {
              bool: {
                must: [
                  {
                    term: {
                      'jobs.metadata.original_company_lookup': {
                        value: alias.key,
                      },
                    },
                  },
                  {
                    term: {
                      'jobs.metadata.company_source': {
                        value: 'linkedin_alias',
                      },
                    },
                  },
                ],
              },
            },
          },
        },
        track_total_hits: true,
        size: 0,
      };
    }
    return {
      query: {
        nested: {
          path: 'jobs',
          query: {
            bool: {
              must: [
                {
                  term: {
                    'jobs.metadata.original_company_name.keyword': {
                      value: alias.key,
                    },
                  },
                },
                {
                  term: {
                    'jobs.metadata.company_source': {
                      value: 'name_alias',
                    },
                  },
                },
              ],
            },
          },
        },
      },
      track_total_hits: true,
      size: 0,
    };
  }

  copyIconState: { [key: string]: string } = {};
  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      // change icon to thumbs up after copying
      this.copyIconState[text] = 'thumb_up';

      // reset icon after 4 seconds
      setTimeout(() => {
        this.copyIconState[text] = 'content_copy';
      }, 4000);
    });
  }
}
